// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.descFormato {
  color: #3a7be0;
}

.input-readonly {
  color: gray;
}`, "",{"version":3,"sources":["webpack://./src/app/_components/ordine-esolver-launch/ordine-esolver-launch.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".descFormato {\n  color: #3a7be0;\n}\n\n.input-readonly {\n  color:  gray;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
