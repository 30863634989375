import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ordine-stato-label',
  templateUrl: './ordine-stato-label.component.html',
  styleUrls: ['./ordine-stato-label.component.scss'],
})
export class OrdineStatoLabelComponent {
  @Input() ordineStato: number;
}
