import { Component, EventEmitter, Input, Output } from '@angular/core';
import {TRACCIATO_DIRECTIONS, TracciatoDirections} from '../../../_helpers/TracciatoDirections';

@Component({
  selector: 'app-select-tracciati-direction',
  templateUrl: './select-tracciati-direction.component.html',
  styleUrls: ['./select-tracciati-direction.component.scss'],
})
export class SelectTracciatiDirectionComponent {
  @Input() direction: TracciatoDirections;
  @Output() directionChange = new EventEmitter<TracciatoDirections>();
  tracciatoDirectionsItem = TRACCIATO_DIRECTIONS;
  selectDirection = (e: any) => this.directionChange.emit(e.target.value);
}
