import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { NotificheService } from '../_services/notifiche.service';

interface NotificationItem {
  message: string;
  color: string;
}

@Injectable({ providedIn: 'root' })
export class SaveMessage {
  notifications: Array<NotificationItem> = [];
  currentToast: any;
  constructor(private toastCtrl: ToastController, private notificheService: NotificheService) {}

  messageInfo(label: string) {
    this.notificheService.messageInfo(label);
  }

  messageError(label: string) {
    this.notificheService.messageError(label);
  }

    async messageWarning(label: string) {
        this.notificheService.messageWarning(label);
    }

  runPopupQueue() {
    if (this.notifications === undefined) {
      return;
    }

    this.notifications.some(async (notification) => {
      this.currentToast = await this.toastCtrl
        .create({message: notification.message, header: notification.color, duration: 3000,
          color: notification.color, position: 'bottom' });

      this.currentToast.present()
        .then(() => {
          this.notifications.shift();
          this.currentToast = null;
          this.runPopupQueue();
        });
      return true;
    });

  }
}
