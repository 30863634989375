import { Detail } from './_Index';

export class LavorazioneEsolver extends Detail{
    lavorazione: string;
    descrLavorazione: string;
}

export class LavorazioneEsolverResult {
    lavorazioni: LavorazioneEsolver[];
}
