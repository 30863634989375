import { Risorsa,Lavorazione, Detail } from './_Index';
import {TipoUmTempo} from '../_helpers/TipoUmTempo';
import {Materiale} from './Materiale';

export class OrdineKey extends Detail {
  idDocumento: number;
  idRiga: number;
  idRigaLavorazione: number;
}
export class OrdinePKey extends OrdineKey {
  organizzazioneId: number;
}
export class Ordine extends OrdinePKey {
    risorsaId: number;
    lavorazioneId: number;
    dataRegistrazione?: Date;
    numRegistrazione?: number;
    codiceArticolo?: string;
    descArticolo?: string;
    quantita: number;
    lotto: number;
    quantitaLavorata: number;
    quantitaScartata: number;
    tempoLav?: string;

    quantitaLavorataWait?: number;
    quantitaScartataWait?: number;
    tempoLavWait?: number;
    fileNameWait?: string;
    qtaRimanente?: number;

    tempoParzialeLav?: string;
    totalWorkingHours?: number;
    partialWorkingHours?: number;
    countWorkingSessions?: number;
    workStartTime?: number;
    lastActionTime?: number;
    lastEventoTime?: number;

    omacState?: string;
    stato: number;
    flgTerminato: boolean;
    statoElimina: number;
    dataTermine: Date;
    commessa?: string;
    umTempo?: TipoUmTempo;

    note: string;

    risorsa?: Risorsa;
    lavorazione?: Lavorazione;

    isLastOfRiga?: boolean;
    lastFase?: number;

    isLoading?: boolean;
    onQueue?: boolean;
    //qtaToEsolver?: number;
    erpFound?: boolean;

    rifLottoProdotto?: string;
    rifLottoMateriale?: string;
    udM: string;
    rifOdP?: string;
    numFase?: number;

    materiale?: Materiale[];

    rifOrdine?: string;
}

export class OrdineCreate extends Ordine {
    codiceRisorsa: string;
    codiceLavorazione: string;
    lottoCode?: string;
}
