import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OrdineGroup} from '../../_models/_Index';
import {ModalController} from '@ionic/angular';
import {OrdiniGroupUpdateSendComponent} from '../ordini-group-update-send/ordini-group-update-send.component';
import {LocalService} from '../../_services/local.service';
import {Subscription} from 'rxjs';
import {OrdiniShowColumns} from '../../_models/OrdiniShowColumns';
@Component({
  selector: 'app-ordine-local-group-item',
  templateUrl: './ordine-local-group-item.component.html',
  styleUrls: ['./ordine-local-group-item.component.scss'],
})
export class OrdineLocalGroupItemComponent {
  @Input() ordineGroup: OrdineGroup;
  @Input() updateEnable: boolean;
  @Output() ordineGroupSended = new EventEmitter<OrdineGroup>();
  today = new Date().toISOString();
  isVisibleOrdini = false;
  showColumns: OrdiniShowColumns;
  subscription: Subscription;

  constructor(
    private localService: LocalService,
    private modalCtrl: ModalController) {
    this.showColumns = this.localService.showColumns.value;
    this.subscription = this.localService.showColumns.subscribe((_showColumns: OrdiniShowColumns) => this.showColumns = _showColumns);
  }

  selectOrdineGroup = (ordine: OrdineGroup) => {
    if (this.updateEnable) {
      this.ordineGroup.isLoading = true;
      this.localService.setSumOrdineGroup(this.ordineGroup, true)
        .then(() => this.openModal(ordine))
        .finally(() => this.ordineGroup.isLoading = false);
    } else {
      this.ordineGroupSended.emit(ordine);
    }
  };

  async openModal(element: OrdineGroup) {
    this.ordineGroup.isLoading = true;
    const modal = await this.modalCtrl.create({
      component: OrdiniGroupUpdateSendComponent,
      backdropDismiss: false,
      componentProps: { ordineGroup: element }
    });
    await modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'save' || role === 'send') {
      //this.localService.handleRefresh.next();
    }
    element.isLoading = false;
  }

  showOrdini = () => this.isVisibleOrdini = !this.isVisibleOrdini;
}
