import {Component, Input} from '@angular/core';
import {Ordine} from '../../_models/Ordine';

@Component({
  selector: 'app-ordine-info',
  templateUrl: './ordine-info.component.html',
  styleUrls: ['./ordine-info.component.scss'],
})
export class OrdineInfoComponent {
  @Input() ordine: Ordine;
  @Input() lavorata: number;
  @Input() scartata: number;
}
