import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Movimento} from '../_models/Movimento';
import {HubConnection, HubConnectionBuilder} from '@microsoft/signalr';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {SaveMessage} from '../_helpers/save-message';
import {OrganizzazioniService} from './organizzazioni.service';
import {environment} from '../../environments/environment';
import {SearchResult} from "../_models/SearchResult";
import {Lavorazione} from "../_models/Lavorazione";

@Injectable({ providedIn: 'root' })
export class MovimentiService {
  public lastMovimentoCreated: BehaviorSubject<Movimento> = new BehaviorSubject<Movimento>(null);
  public lastMovimentoUpdated: BehaviorSubject<Movimento> = new BehaviorSubject<Movimento>(null);
  public lastMovimentoDeleted: BehaviorSubject<Movimento> = new BehaviorSubject<Movimento>(null);
  public hubState: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private hubConnection: HubConnection;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private organizzazioniService: OrganizzazioniService,
    private saveMessage: SaveMessage) {}

  public startConnection() {
    return new Promise((resolve, reject) => {
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(`${environment.localHubUrl}hubs/movimento`)
        .withAutomaticReconnect()
        .build();

      this.hubConnection.onclose(() => this.hubState.next(0));

      this.hubConnection.start()
        .then(() => {
          this.hubState.next(1);
          resolve(true);
        })
        .catch((err: any) => reject(err));

      this.hubConnection.on('SendCreated',(movimento: Movimento) => {
        if (movimento.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Nuovo movimento creato!');
          this.lastMovimentoCreated.next(movimento);
        }
      });

      this.hubConnection.on('SendUpdated',(movimento: Movimento) => {
        if (movimento.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Movimento aggiornato!');
          this.lastMovimentoUpdated.next(movimento);
        }
      });

      this.hubConnection.on('SendDeleted',(movimento: Movimento) => {
        if (movimento.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Movimento eliminato!');
          this.lastMovimentoDeleted.next(movimento);
        }
      });
    });
  }

  async getMovimentiFilteredAsync(orgId: number, search: string, page: number, count: number, sortField: string, sortAsc: boolean) {
    return await this.http.post<SearchResult<Movimento>>(`${environment.localApiUrl}Movimenti/MovimentiFiltered?orgId=${orgId}`,
      { search, page, count, sortField, sortAsc })
      .toPromise();
  }

  async getMovimentoAsync(orgId: number, id: number) {
    // eslint-disable-next-line max-len
    return await this.http.get<Movimento>(`${environment.localApiUrl}Movimenti/Movimento?orgId=${orgId}&code=${id}`)
      .toPromise();
  }

  async createMovimentoAsync(movimento: Movimento) {
    return await this.http.post<Movimento>(`${environment.localApiUrl}Movimenti/CreateMovimento`, movimento)
      .toPromise();
  }

  async updateMovimentoAsync(movimento: Movimento) {
    return await this.http.post<Movimento>(`${environment.localApiUrl}Movimenti/UpdateMovimento`, movimento)
      .toPromise();
  }

  async deleteMovimentoAsync(orgId: number, id: number) {
    return await this.http.get<Movimento>(`${environment.localApiUrl}Movimenti/DeleteMovimento/${orgId}/${id}`)
      .toPromise();
  }
}
