import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import { Chart } from 'chart.js/auto';
import { RisorseCounter } from '../../../_models/RisorseCounter';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit, OnDestroy {
  @Input() bars: RisorseCounter[] = [];
  public chart: any;
  constructor() { }

  ngOnInit() {
    this.createChart();
  }

  ngOnDestroy() {
    if (this.chart !== undefined) {
      this.chart.destroy();
    }
  }

  createChart(){
    const labels = [];
    const datas = [];
    if (this.bars && this.bars.length > 0) {
      this.bars.forEach((bar: RisorseCounter) => {
        labels.push(bar.risorsaId);
        datas.push(bar.workingTimeOfToday / 3600);
      });
      this.chart = Chart.getChart('PieChart');
      if (this.chart){
        this.chart.destroy();
      }
      this.chart = new Chart('PieChart', {
        type: 'doughnut',
        data: {
          labels,
          datasets: [{ label: 'Ore lavorate', data: datas }]
        },
        options: {
          responsive: true,
          aspectRatio:2
        }
      });
    }
  }

}
