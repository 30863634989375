import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms'; // <-- NgModel lives here
import { NgxBarcode6Module } from 'ngx-barcode6';
import { IonicModule } from '@ionic/angular';
import { SelectRisorseFormatComponent } from './_components/select/select-risorse-format/select-risorse-format.component';
import { SelectStatiOrdiniComponent } from './_components/select/select-stati-ordini/select-stati-ordini.component';
import { SelectTypeEventoComponent } from './_components/select/select-type-evento/select-type-evento.component';
import { SelectTracciatiComponent } from './_components/select/select-tracciati/select-tracciati.component';
import { SelectTracciatiFieldComponent } from './_components/select/select-tracciati-field/select-tracciati-field.component';
import { SelectTypeTracciatoComponent } from './_components/select/select-type-tracciato/select-type-tracciato.component';
import { SearchComponentComponent } from './_components/search-component/search-component.component';
import { OrdineStatoLabelComponent } from './_components/ordine-stato-label/ordine-stato-label.component';
import { InstallazioneTypeLabelComponent } from './_components/installazione-type-label/installazione-type-label.component';
import { TracciatiTypeFieldLabelComponent } from './_components/tracciati/tracciati-type-field-label/tracciati-type-field-label.component';
import { TipoEventoLabelComponent } from './_components/tipo-evento-label/tipo-evento-label.component';
import { OrdineEsolverItemComponent } from './_components/ordine-esolver-item/ordine-esolver-item.component';
import { OrdineLocalItemComponent } from './_components/ordine-local-item/ordine-local-item.component';
import { SelectOrganizzazioneComponent } from './_components/select/select-organizzazione/select-organizzazione.component';
import { OrdiniFilterRowComponent} from './_components/ordini-filter-row/ordini-filter-row.component';
import { SelectFilterRowOperationComponent } from './_components/select/select-filter-row-operation/select-filter-row-operation.component';
import { SelectRisorsaComponent } from './_components/select/select-risorsa/select-risorsa.component';
import { SelectLavorazioneComponent } from './_components/select/select-lavorazione/select-lavorazione.component';
import { SelectDateRangeComponent } from './_components/select/select-date-range/select-date-range.component';
import { SelectDateModalComponent } from './_components/select/select-date-modal/select-date-modal.component';
import { SelectTracciatiTypeFieldComponent } from './_components/select/select-tracciati-type-field/select-tracciati-type-field.component';
import { SelectTypeInstallazioneComponent } from './_components/select/select-type-installazione/select-type-installazione.component';
import { SelectTypeUmtempoComponent } from './_components/select/select-type-umtempo/select-type-umtempo.component';
import { SelectTracciatiFieldTypeKeyComponent } from './_components/select/select-tracciati-field-type-key/select-tracciati-field-type-key.component';
import { SelectTracciatiDirectionComponent } from './_components/select/select-tracciati-direction/select-tracciati-direction.component';
import { SelectTracciatiLinkModeComponent } from './_components/select/select-tracciati-link-mode/select-tracciati-link-mode.component';
import { SelectTracciatiTerminatoModeComponent } from './_components/select/select-tracciati-terminato-mode/select-tracciati-terminato-mode.component';
import { SelectModuloComponent } from './_components/select/select-modulo/select-modulo.component';
import { SelectTracciatoFieldTypeFormatComponent } from './_components/select/select-tracciato-field-type-format/select-tracciato-field-type-format.component';
import { SelectGroupTypeComponent } from './_components/select/select-group-type/select-group-type.component';
import {SelectTypeFiltroOrdineComponent} from './_components/select/select-type-filtro-ordine/select-type-filtro-ordine.component';
import { SelectTypeAvvioComponent } from './_components/select/select-type-avvio/select-type-avvio.component';
import { SelectRisorseConditionComponent } from './_components/select/select-risorse-condition/select-risorse-condition.component';
import { SelectNextFaseComponent } from './_components/select/select-next-fase/select-next-fase.component';
import { OrdineEsolverLaunchComponent} from './_components/ordine-esolver-launch/ordine-esolver-launch.component';
import { OrdineLocalCreateComponent } from './_components/ordine-local-create/ordine-local-create.component';
import { OrdineInfoComponent } from './_components/ordine-info/ordine-info.component';
import { OrdineSessioniComponent} from './_components/ordine-sessioni/ordine-sessioni.component';
import { OrdiniUpdateSendComponent } from './_components/ordini-update-send/ordini-update-send.component';
import { OrdiniEsolverComponent } from './_components/ordini-esolver/ordini-esolver.component';
import { OrdiniLocaliComponent } from './_components/ordini-locali/ordini-locali.component';
import { OrdiniLocaliGroupComponent } from './_components/ordini-locali-group/ordini-locali-group.component';
import { OrdiniLocaliGroupbymanyComponent } from './_components/ordini-locali-groupbymany/ordini-locali-groupbymany.component';
import { OrdiniViewmodeSwitchComponent } from './_components/ordini-viewmode-switch/ordini-viewmode-switch.component';
import { OrdineLocalGroupItemComponent } from './_components/ordine-local-group-item/ordine-local-group-item.component';
import { OrdineLocalGroupbymanyItemComponent } from './_components/ordine-local-groupbymany-item/ordine-local-groupbymany-item.component';
import { OrdiniEsolverGroupbymanyComponent} from './_components/ordini-esolver-groupbymany/ordini-esolver-groupbymany.component';
import { OrdineEsolverGroupbymanyItemComponent} from './_components/ordine-esolver-groupbymany-item/ordine-esolver-groupbymany-item.component';
import { OrdineLocalHeaderComponent } from './_components/ordine-local-header/ordine-local-header.component';
import { OrdiniLocaliListComponent } from './_components/ordini-locali-list/ordini-locali-list.component';
import { OrdiniGroupColumnsComponent } from './_components/ordini-group-columns/ordini-group-columns.component';
import { OrdiniShowColumnsComponent } from './_components/ordini-show-columns/ordini-show-columns.component';
import { OrdiniGroupUpdateSendComponent } from './_components/ordini-group-update-send/ordini-group-update-send.component';
import { TracciatiFieldCreateComponent } from './_components/tracciati/tracciati-field-create/tracciati-field-create.component';
import { TracciatiFieldListComponent } from './_components/tracciati/tracciati-field-list/tracciati-field-list.component';
import { TracciatoFieldStatoComponent } from './_components/tracciati/tracciato-field-stato/tracciato-field-stato.component';
import { HubStateLedComponent } from './_components/hub-state-led/hub-state-led.component';
import { CentroNotificheComponent } from './_components/centro-notifiche/centro-notifiche.component';
import { CentroNotifichePopupComponent } from './_components/centro-notifiche-popup/centro-notifiche-popup.component';
import { OrdiniStatisticsComponent} from './_components/ordini-statistics/ordini-statistics.component';
import { BarChartComponent } from './_components/charts/bar-chart/bar-chart.component';
import { PieChartComponent } from './_components/charts/pie-chart/pie-chart.component';
import { TruncatePipe } from './_helpers/TruncatePipe';
import {OrderByPipe} from './_helpers/order-by.pipe';
import { FloorPipe } from './_helpers/FloorPipe';
import { TimeStringPipe } from './_helpers/TimeStringPipe';
import { KeyboardComponent } from './_components/keyboard/keyboard.component';
import { LicenzeConfigComponent } from './_components/licenze/licenze-config/licenze-config.component';
import { LicenzeConfigItemComponent } from './_components/licenze/licenze-config-item/licenze-config-item.component';
import { OrdiniFilterRowCodiceUnivocoComponent } from './_components/ordini-filter-row-codice-univoco/ordini-filter-row-codice-univoco.component';
import {EventiChartComponent} from './_components/charts/eventi-chart/eventi-chart.component';
import {OrdineEsolverGroupItemComponent} from './_components/ordine-esolver-group-item/ordine-esolver-group-item.component';
import {OrdineEsolverGroupLaunchComponent} from './_components/ordine-esolver-group-launch/ordine-esolver-group-launch.component';
import { UserHeaderComponent} from './_components/user-header/user-header.component';
import {UserInfoComponent} from './_components/user-info/user-info.component';
import {FiltroOrdineComponent} from './_components/filtro-ordine/filtro-ordine.component';
import { MaterialeListComponent} from './_components/materiale-list/materiale-list.component';
import { MovimentoAddComponent } from './_components/movimenti/movimento-add/movimento-add.component';
import { LottoInputComponent } from './_components/lotto-input/lotto-input.component';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [
        SelectRisorseFormatComponent,
        SelectStatiOrdiniComponent,
        SelectTypeEventoComponent,
        SelectTracciatiComponent,
        SelectTracciatiFieldComponent,
        SelectTypeTracciatoComponent,
        SelectTypeInstallazioneComponent,
        SearchComponentComponent,
        OrdineStatoLabelComponent,
        InstallazioneTypeLabelComponent,
        TracciatiTypeFieldLabelComponent,
        TipoEventoLabelComponent,
        OrdineEsolverItemComponent,
        OrdineEsolverGroupItemComponent,
        OrdineEsolverGroupLaunchComponent,
        OrdineLocalItemComponent,
        SelectOrganizzazioneComponent,
        OrdiniFilterRowComponent,
        SelectFilterRowOperationComponent,
        SelectRisorsaComponent,
        SelectLavorazioneComponent,
        SelectDateRangeComponent,
        SelectDateModalComponent,
        SelectTracciatiTypeFieldComponent,
        SelectTypeUmtempoComponent,
        SelectTracciatiFieldTypeKeyComponent,
        SelectTracciatiDirectionComponent,
        SelectTracciatiLinkModeComponent,
        SelectTracciatiTerminatoModeComponent,
        SelectModuloComponent,
        SelectTracciatoFieldTypeFormatComponent,
        SelectGroupTypeComponent,
        SelectTypeFiltroOrdineComponent,
        SelectTypeAvvioComponent,
        SelectRisorseConditionComponent,
        SelectNextFaseComponent,
        OrdineEsolverLaunchComponent,
        OrdineLocalCreateComponent,
        OrdineInfoComponent,
        OrdineSessioniComponent,
        OrdiniUpdateSendComponent,
        OrdiniEsolverComponent,
        OrdiniLocaliComponent,
        OrdiniLocaliGroupComponent,
        OrdiniLocaliGroupbymanyComponent,
        OrdiniViewmodeSwitchComponent,
        OrdineLocalGroupItemComponent,
        OrdineLocalGroupbymanyItemComponent,
        OrdiniEsolverGroupbymanyComponent,
        OrdineEsolverGroupbymanyItemComponent,
        OrdineLocalHeaderComponent,
        OrdiniLocaliListComponent,
        OrdiniGroupColumnsComponent,
        OrdiniShowColumnsComponent,
        OrdiniGroupUpdateSendComponent,
        TracciatiFieldCreateComponent,
        TracciatiFieldListComponent,
        TracciatoFieldStatoComponent,
        HubStateLedComponent,
        CentroNotificheComponent,
        CentroNotifichePopupComponent,
        OrdiniStatisticsComponent,
        BarChartComponent,
        PieChartComponent,
        EventiChartComponent,
        KeyboardComponent,
        OrderByPipe,
        TruncatePipe,
        FloorPipe,
        TimeStringPipe,
        LicenzeConfigComponent,
        LicenzeConfigItemComponent,
        OrdiniFilterRowCodiceUnivocoComponent,
      UserHeaderComponent,
      UserInfoComponent,
      FiltroOrdineComponent,
      MaterialeListComponent,
      MovimentoAddComponent,
      LottoInputComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        IonicModule.forRoot()
    ],
    exports: [
        FormsModule,
        NgxBarcode6Module,
        SelectRisorseFormatComponent,
        SelectStatiOrdiniComponent,
        SelectTypeEventoComponent,
        SelectTracciatiComponent,
        SelectTracciatiFieldComponent,
        SelectTypeTracciatoComponent,
        SelectTypeInstallazioneComponent,
        SearchComponentComponent,
        OrdineStatoLabelComponent,
        InstallazioneTypeLabelComponent,
        TracciatiTypeFieldLabelComponent,
        TipoEventoLabelComponent,
        OrdineEsolverItemComponent,
        OrdineEsolverGroupItemComponent,
        OrdineEsolverGroupLaunchComponent,
        OrdineLocalItemComponent,
        SelectOrganizzazioneComponent,
        OrdiniFilterRowComponent,
        SelectFilterRowOperationComponent,
        SelectRisorsaComponent,
        SelectLavorazioneComponent,
        SelectDateRangeComponent,
        SelectDateModalComponent,
        SelectTracciatiTypeFieldComponent,
        SelectTypeUmtempoComponent,
        SelectTracciatiFieldTypeKeyComponent,
        SelectTracciatiDirectionComponent,
        SelectTracciatiLinkModeComponent,
        SelectTracciatiTerminatoModeComponent,
        SelectModuloComponent,
        SelectTracciatoFieldTypeFormatComponent,
        SelectGroupTypeComponent,
        SelectTypeFiltroOrdineComponent,
        SelectTypeAvvioComponent,
        SelectRisorseConditionComponent,
        SelectNextFaseComponent,
        OrdineEsolverLaunchComponent,
        OrdineLocalCreateComponent,
        OrdineInfoComponent,
        OrdineSessioniComponent,
        OrdiniUpdateSendComponent,
        OrdiniEsolverComponent,
        OrdiniLocaliComponent,
        OrdiniLocaliGroupComponent,
        OrdiniLocaliGroupbymanyComponent,
        OrdiniViewmodeSwitchComponent,
        OrdineLocalGroupItemComponent,
        OrdineLocalGroupbymanyItemComponent,
        OrdiniEsolverGroupbymanyComponent,
        OrdineEsolverGroupbymanyItemComponent,
        OrdineLocalHeaderComponent,
        OrdiniLocaliListComponent,
        OrdiniGroupColumnsComponent,
        OrdiniShowColumnsComponent,
        OrdiniGroupUpdateSendComponent,
        TracciatiFieldCreateComponent,
        TracciatiFieldListComponent,
        TracciatoFieldStatoComponent,
        HubStateLedComponent,
        CentroNotificheComponent,
        CentroNotifichePopupComponent,
        OrdiniStatisticsComponent,
        BarChartComponent,
        KeyboardComponent,
        EventiChartComponent,
        OrderByPipe,
        TruncatePipe,
        FloorPipe,
        TimeStringPipe,
        LicenzeConfigComponent,
        LicenzeConfigItemComponent,
        OrdiniFilterRowCodiceUnivocoComponent,
        UserHeaderComponent,
      UserInfoComponent,
      FiltroOrdineComponent,
      MaterialeListComponent,
      MovimentoAddComponent,
      LottoInputComponent,
        IonicModule
    ],
    providers: []
})
export class SharedModule {}
