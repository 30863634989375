import { Component, EventEmitter, Input, Output } from '@angular/core';
import {TRACCIATO_FIELD_TYPES, TracciatoFieldTypesEnum} from '../../../_helpers/TracciatoFieldTypes';

@Component({
  selector: 'app-select-tracciati-type-field',
  templateUrl: './select-tracciati-type-field.component.html',
  styleUrls: ['./select-tracciati-type-field.component.scss'],
})
export class SelectTracciatiTypeFieldComponent {
  @Input() fieldType: TracciatoFieldTypesEnum;
  @Output() fieldTypeChange = new EventEmitter<TracciatoFieldTypesEnum>();
  tracciatoFieldTypesItem = TRACCIATO_FIELD_TYPES;
  selectFieldType = (e: any) => this.fieldTypeChange.emit(e.target.value);
}
