import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {Operatore, Organizzazione} from '../_models/_Index';
import { AuthenticationService } from '../_services/authentication.service';
import { constants } from '../_helpers/constants';
import {OrganizzazioniService} from '../_services/organizzazioni.service';
import {first} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.scss'],
})
export class DashboardHomeComponent implements OnInit, OnDestroy {

  userName = '';
  organizzazioneCodice = '';
  isStandalone = false;
  constants = constants;
  subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private organizzazioniService: OrganizzazioniService,
    public authService: AuthenticationService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.id_token == null) {
        return false;
      }
      this.authService.externalLogin(params.id_token, params.refresh_token)
        .pipe(first())
        .subscribe(
          data => {
            if (data && data.organizzazioneId){
              this.organizzazioniService.setCurrentOrganizzazioneValueById(data.organizzazioneId);
            }
          }
        );
    });
    if (this.authService.currentUserValue != null) {
      this.userName = this.authService.currentUserValue.userName;
    }
    this.subscription = this.authService.currentUser.subscribe((_user: Operatore) => {
      if (_user != null) {
        this.userName = _user.firstName + ' ' + _user.lastName;
      } else {
          this.userName = '';
      }
    });
    this.subscription.add(this.organizzazioniService.currentOrganizzazione.subscribe((_org: Organizzazione) => {
      if (_org) {
        this.organizzazioneCodice = _org.descrizione;
      }
      this.isStandalone = this.organizzazioniService.isStandalone();
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
