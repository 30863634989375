import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { Ordine } from '../../_models/Ordine';
import { LocalService } from '../../_services/local.service';
import {Subscription} from 'rxjs';
import {MessageConfirm} from '../../_helpers/message-confirm.service';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';
import {SaveMessage} from '../../_helpers/save-message';
import {OrdiniUpdateSendComponent} from '../ordini-update-send/ordini-update-send.component';
import {ModalController} from '@ionic/angular';
import {OrdiniShowColumns} from '../../_models/OrdiniShowColumns';

@Component({
  selector: 'app-ordine-local-item',
  templateUrl: './ordine-local-item.component.html',
  styleUrls: ['./ordine-local-item.component.scss']
})
export class OrdineLocalItemComponent implements OnInit, OnDestroy {
  @Input() ordine: Ordine;
  @Input() updateEnable: boolean;
  @Output()  ordineSended = new EventEmitter<Ordine>();
  showColumns: OrdiniShowColumns;
  dataOggi = new Date().toISOString();
  subscription: Subscription;
  today = new Date().toISOString();

  constructor(
      private organizzazioniService: OrganizzazioniService,
      private messageConfirm: MessageConfirm,
      private saveMessage: SaveMessage,
      private modalCtrl: ModalController,
      private localService: LocalService) {}

  ngOnInit() {
    this.subscription = this.localService.lastOrdineUpdated.subscribe((_ordine: Ordine) => {
      if (_ordine == null) {
        return false;
      }
      if (!this.ordine.isLoading &&
        _ordine.numRegistrazione > 0 &&
        this.ordine.idDocumento === _ordine.idDocumento &&
        this.ordine.idRiga === _ordine.idRiga &&
        this.ordine.idRigaLavorazione === _ordine.idRigaLavorazione) {
        this.ordine = _ordine;
      }
    });
    this.showColumns = this.localService.showColumns.value;
    this.subscription.add(this.localService.showColumns.subscribe((_showColumns: OrdiniShowColumns) => this.showColumns = _showColumns));
  }

  deleteOrdine = (ordine: Ordine) => {
    this.messageConfirm
        .presentAlert(`Confermi di eliminare ordine ${ordine.numRegistrazione} (${ordine.idDocumento}.${ordine.idRiga}.${ordine.idRigaLavorazione}) e tutti gli eventi collegati?`, () => {
          this.localService.deleteOrdineAsync(this.organizzazioniService.organizzazioneId, ordine.idDocumento,
              ordine.idRiga,ordine.idRigaLavorazione)
              .then(() => {
                this.ordine.onQueue = true;
                this.saveMessage.messageInfo('Richiesta di eliminare ordine inviata con successo');
              })
              .catch(() => this.saveMessage.messageError('Ci sono problemi con eliminazione di ordine.'));
        });
  };

  selectOrdine = (ordine: Ordine) => {
    if (this.updateEnable && !ordine.isLoading) {
      this.openOrdineWithTempo(ordine);
    } else {
      this.ordineSended.emit(ordine);
    }
  };

  openOrdineWithTempo = (ordine: Ordine) => {
    ordine.isLoading = true;
    this.localService.getOrdiniTempoAsync(ordine.organizzazioneId,ordine.idDocumento,ordine.idRiga,ordine.idRigaLavorazione)
        .then(async (_ordine: Ordine) => await this.openModal(_ordine))
        .catch(() => this.saveMessage.messageError('Ci sono problemi con il caricamento di ordine.'))
        .finally(() => ordine.isLoading = false);
  };

  async openModal(element) {
    const modal = await this.modalCtrl.create({
      component: OrdiniUpdateSendComponent,
      backdropDismiss: false,
      componentProps: { ordine: element, data: this.dataOggi }
    });
    await modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'save' || role === 'send') {
      //this.localService.handleRefresh.next(1);
      //this.ordine.onQueue = true;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
