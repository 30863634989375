import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ForgotPasswordRequest,Operatore,RegisterModel,ResetPasswordRequest } from '../_models/_Index';

const cachePrefix = 'archiconnect-v2-';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    public currentUser: Observable<Operatore>;
    public tokenKey = '';
    private currentUserSubject = new BehaviorSubject<Operatore>(null);
    private currentPermessiSubject = new BehaviorSubject<string[]>([]);

    constructor(private http: HttpClient) {
        this.currentUserSubject.next(JSON.parse(localStorage.getItem(cachePrefix+'currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): Operatore {
        return this.currentUserSubject.value;
    }

    public isInRole(roleName: string): boolean {
        return this.currentUserValue.roles.indexOf(roleName) > -1;
    }

    login(email: string, password: string) {
        return this.http.post<any>(`${environment.localApiUrl}Accounts/authenticate`,
            { email, password })
            .pipe(map(user => {
                if (user && user.jwtToken) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem(cachePrefix+'currentUser', JSON.stringify(user));
                    this.tokenKey = user.jwtToken;
                    this.currentUserSubject.next(user);
                    this.currentPermessiSubject.next(this.decodeJwtToken(user.jwtToken, 'license').split(','));
                }
                this.startRefreshTokenTimer();
                return user;
            }));
    }

    decodeJwtToken(jwtToken: string, claimName): string {
      const decodedJWT = JSON.parse(window.atob(jwtToken.split('.')[1]));
      return decodedJWT[claimName];
    }

    public checkPermesso(permessoCode: string): boolean {
      return this.currentPermessiSubject.value.indexOf(permessoCode) > -1;
    }

    externalLogin(idToken: string, refreshToken: string) {
      return this.http.get<any>(`${environment.localApiUrl}Accounts/external-login?id_token=${idToken}&refresh_token=${refreshToken}`)
        .pipe(map(user => {
          if (user && user.jwtToken) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(cachePrefix+'currentUser', JSON.stringify(user));
            this.tokenKey = user.jwtToken;
            this.currentUserSubject.next(user);
            this.currentPermessiSubject.next(this.decodeJwtToken(user.jwtToken, 'license').split(','));
          }
          this.startRefreshTokenTimer();
          return user;
        }));
    }

  updateRefreshToken() {
      const token = this.currentUserValue?.refreshToken ?? '';
      return this.http.post<any>(`${environment.localApiUrl}Accounts/update-refresh-token`,
        { token })
        .pipe(map(user => {
          if (user && user.jwtToken) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(cachePrefix+'currentUser', JSON.stringify(user));
            this.tokenKey = user.jwtToken;
            this.currentUserSubject.next(user);
            this.currentPermessiSubject.next(this.decodeJwtToken(user.jwtToken, 'license').split(','));
          }
          this.startRefreshTokenTimer();
          return user;
        })).toPromise();
  }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem(cachePrefix+'currentUser');
        this.currentUserSubject.next(null);
    }

    register(account: RegisterModel) {
        return this.http.post<any>(`${environment.localApiUrl}Accounts/register`, account)
            .toPromise();
    }

    forgotPassword(email: ForgotPasswordRequest) {
        return this.http.post<any>(`${environment.localApiUrl}Accounts/forgot-password`, email)
            .toPromise();
    }

  verifyEmail(token: string) {
    return this.http.post<any>(`${environment.localApiUrl}Accounts/verify-email`, {token})
      .toPromise();
  }

    resetPassword(reset: ResetPasswordRequest){
        return this.http.post<any>(`${environment.localApiUrl}Accounts/reset-password`, reset)
        .toPromise();
    }

    // helper functions
    refreshToken() {
        return this.http.post<any>(`${environment.localApiUrl}Accounts/refresh-token`, {})
            // .pipe(map(user => {
            //     if (user && user.jwtToken) {
            //         // store user details and jwt token in local storage to keep user logged in between page refreshes
            //         localStorage.setItem(cachePrefix+'currentUser', JSON.stringify(user));
            //         this.currentUserSubject.next(user);
            //         this.organizzazioneId = user.organizzazioneId;
            //         this.organizzazioneCodice = user.organizzazioneCodice;
            //         this.gruppoCodice = user.gruppoCodice;
            //     }
            //     this.startRefreshTokenTimer();
            //     return user;
            // }));
            .toPromise()
            .then(user => {
                // publish user to subscribers and start timer to refresh token
                this.currentUserSubject.next(user);
                this.tokenKey = user.jwtToken;
                this.startRefreshTokenTimer();
                return user;
            });
    }

    startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.tokenKey.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
    }

    stopRefreshTokenTimer() {
        clearTimeout(refreshTokenTimeout);
    }
}

let refreshTokenTimeout;
