import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LicenzeService} from '../../../_services/licenze.service';
import {SearchResult} from '../../../_models/SearchResult';
import {Modulo} from '../../../_models/Modulo';

@Component({
  selector: 'app-select-modulo',
  templateUrl: './select-modulo.component.html',
  styleUrls: ['./select-modulo.component.scss'],
})
export class SelectModuloComponent implements OnInit {
  @Input() byCode = false;
  @Input() isString = false;
  @Input() moduloValue;
  @Output() moduloValueChange = new EventEmitter<string>();
  modulis = [];
  loading = false;
  constructor(
    private licenzeService: LicenzeService) { }

  ngOnInit() {
    this.getModuliLocalAsync();
  }

  selectModulo = (e: any) => this.moduloValueChange.emit(this.isString ? e.target.value.toString() : e.target.value);

  getModuliLocalAsync() {
    this.loading = true;
    this.modulis = [];
    this.licenzeService
      .getAllModuliFilteredAsync( '', 0, 0, 'codice', true)
      .then((_result: SearchResult<Modulo>) => {
        if (_result.totalCount > 0) {
          _result.result.forEach((_modulo: Modulo) =>
            this.modulis.push({ value: this.byCode ? _modulo.codice : _modulo.id,
              label: `${_modulo.codice}: ${_modulo.descrizione}` })
          );
        }
      })
      .catch((_err) => console.log(_err))
      .finally(() => this.loading = false);
  }
}
