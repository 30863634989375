export interface GroupResult<T> {
  key: any;
  count: number;
  items?: T[];
  subGroups?: GroupResult<T>[];
  dataTermine: Date;
  quantita: number;
  quantitaLavorata: number;
  quantitaScartata: number;
}
