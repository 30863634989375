import { Component, EventEmitter, Input, Output } from '@angular/core';
import {TRACCIATO_TERMINATO_MODES, TracciatoTerminatoModes} from '../../../_helpers/TracciatoTerminatoModes';

@Component({
  selector: 'app-select-tracciati-terminato-mode',
  templateUrl: './select-tracciati-terminato-mode.component.html',
  styleUrls: ['./select-tracciati-terminato-mode.component.scss'],
})
export class SelectTracciatiTerminatoModeComponent {
  @Input() terminatoMode: TracciatoTerminatoModes;
  @Output() terminatoModeChange = new EventEmitter<TracciatoTerminatoModes>();
  tracciatoTerminatoModesItem = TRACCIATO_TERMINATO_MODES;
  selectTerminatoMode = (e: any) => this.terminatoModeChange.emit(e.target.value);
}
