import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { OrganizzazioniService} from '../../../_services/organizzazioni.service';
import { LavorazioniService } from '../../../_services/lavorazioni.service';
import {Lavorazione} from '../../../_models/Lavorazione';
import {SearchResult} from '../../../_models/SearchResult';
import {SaveMessage} from '../../../_helpers/save-message';

@Component({
  selector: 'app-select-lavorazione',
  templateUrl: './select-lavorazione.component.html',
  styleUrls: ['./select-lavorazione.component.scss'],
})
export class SelectLavorazioneComponent implements OnInit {
  @Input() byCode = false;
  @Input() isString = false;
  @Input() lavorazioneValue;
  @Input() all = false;
  @Output() lavorazioneValueChange = new EventEmitter<string>();
  loading = false;
  lavoraziones = [];

  constructor(
    private saveMessage: SaveMessage,
    private organizzazioniService: OrganizzazioniService,
    private lavorazioniService: LavorazioniService) {
  }

  ngOnInit() {
    this.getLavorazioni();
  }

  selectLavorazione = (e: any) => this.lavorazioneValueChange.emit(this.isString ? e.target.value.toString() : e.target.value);

  getLavorazioni = () => this.organizzazioniService
    .lavorazioniPersonal && !this.all ? this.getMyLavorazioni() : this.getLavorazioniLocalAsync();

  getMyLavorazioni = () => {
    //this.loading = true;
    this.setLavorazioni(this.lavorazioniService.myLavorazioni.value);
    /*this.lavorazioniService
      .getMyLavorazioni(this.organizzazioniService.organizzazioneId)
      .then(this.setLavorazioni)
      .catch((_err) => this.saveMessage.messageError('Ci sono problemi con il caricamento di lavorazioni'))
      .finally(() => this.loading = false);*/
  };

  getLavorazioniLocalAsync() {
    this.loading = true;
    this.lavoraziones = [];
    this.lavorazioniService
      .getLavorazioniFilteredAsync(this.organizzazioniService.organizzazioneId, '', 0, 0, 'codice', true)
      .then((_lavorazioni: SearchResult<Lavorazione>) => this.setLavorazioni(_lavorazioni.result))
      .catch((_err) => this.saveMessage.messageError('Ci sono problemi con il caricamento di lavorazioni'))
      .finally(() => this.loading = false);
  }

  setLavorazioni = (_result: Lavorazione[]) => {
    if (_result.length > 0) {
      _result.forEach((_lavorazione: Lavorazione) =>
        this.lavoraziones.push({ value: this.byCode ? _lavorazione.codice : _lavorazione.id,
          label: `${_lavorazione.codice}: ${_lavorazione.descrizione}` }));
    }
  };
}
