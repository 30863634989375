export enum StatiOrdine {
  fermo = -1,
  daPianificare = 0,
  pianificato = 1,
  lanciato = 2,
  inAvanzamento = 3,
  pausa = 6,
  terminato = 4,
  sospeso = 5,
  trasferimento = 21,
  esolverWaiting = 97,
  esolverFailed = 98,
  esolver = 99
}

export const STATO_ORDINE = [
  { value: StatiOrdine.fermo, text: 'Fermo', newValue: '' },
  { value: StatiOrdine.daPianificare, text: 'Da pianificare', newValue: '' },
  { value: StatiOrdine.pianificato, text: 'Pianificato', newValue: '' },
  { value: StatiOrdine.lanciato, text: 'Lanciato', newValue: '' },
  { value: StatiOrdine.inAvanzamento, text: 'In Avanzamento', newValue: '' },
  { value: StatiOrdine.pausa, text: 'Pausa', newValue: '' },
  { value: StatiOrdine.terminato, text: 'Terminato', newValue: '' },
  { value: StatiOrdine.sospeso, text: 'Sospeso', newValue: '' },
  { value: StatiOrdine.trasferimento, text: 'Trasferimento', newValue: '' },
  { value: StatiOrdine.esolverWaiting, text: 'ERP Waiting', newValue: '' },
  { value: StatiOrdine.esolverFailed, text: 'ERP Error', newValue: '' },
  { value: StatiOrdine.esolver, text: 'ERP', newValue: '' }
];

export const STATO_ORDINE_DICTIONARY: {[key: number]: any} = {
  [StatiOrdine.fermo]: { text: 'Fermo', newValue: '', color: 'bg-teal-300' },
  [StatiOrdine.daPianificare]: { text: 'Da pianificare', newValue: '', color: 'bg-gray-100' },
  [StatiOrdine.pianificato]: { text: 'Pianificato', newValue: '', color: 'bg-yellow-500' },
  [StatiOrdine.lanciato]: { text: 'Lanciato', newValue: '', color: 'bg-violet-500' },
  [StatiOrdine.inAvanzamento]: { text: 'In Avanzamento', newValue: '', color: 'bg-green-300' },
  [StatiOrdine.pausa]: { text: 'Pausa', newValue: '', color: 'bg-orange-500' },
  [StatiOrdine.terminato]: { text: 'Terminato', newValue: '', color: 'bg-blue-500' },
  [StatiOrdine.sospeso]: { text: 'Sospeso', newValue: '', color: 'bg-red-300' },
  [StatiOrdine.esolverWaiting]: { text: 'ERP Waiting', newValue: '', color: 'bg-orange-500'},
  [StatiOrdine.esolverFailed]: { text: 'ERP Error', newValue: '', color: 'bg-red-700'},
  [StatiOrdine.esolver]: { text: 'ERP', newValue: '', color: 'bg-pink-500'}
};
