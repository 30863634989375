import {Component, Input } from '@angular/core';

@Component({
  selector: 'app-installazione-type-label',
  templateUrl: './installazione-type-label.component.html',
  styleUrls: ['./installazione-type-label.component.scss'],
})
export class InstallazioneTypeLabelComponent {
  @Input() typeInstallazione: number;
}
