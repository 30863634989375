// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-odd-dark {
  --background:#015f01d5;
}

.item-odd {
  --background:#aaaaaaaa;
  background: #aaaaaa;
}

.item-even-dark {
  --background:#01010101;
  background: #010101;
}

.item-event {
  --background:#EEEEEEEE;
  background: #333333;
}

.item-background-color {
  background: #CCCCCC;
}

.item-background-dark {
  background: #333333;
}`, "",{"version":3,"sources":["webpack://./src/app/_components/ordini-locali/ordini-locali.component.scss"],"names":[],"mappings":"AAAA;EAAiB,sBAAA;AAEjB;;AADA;EAAW,sBAAA;EAAwB,mBAAA;AAMnC;;AALA;EAAkB,sBAAA;EAAwB,mBAAA;AAU1C;;AATA;EAAa,sBAAA;EAAwB,mBAAA;AAcrC;;AAbA;EAAwB,mBAAA;AAiBxB;;AAhBA;EAAuB,mBAAA;AAoBvB","sourcesContent":[".item-odd-dark { --background:#015f01d5; }\n.item-odd{ --background:#aaaaaaaa; background: #aaaaaa; }\n.item-even-dark { --background:#01010101; background: #010101; }\n.item-event{ --background:#EEEEEEEE; background: #333333; }\n.item-background-color{ background: #CCCCCC; }\n.item-background-dark{ background: #333333; }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
