export enum TracciatoFieldKeyTypes {
  none = 0,
  equals = 1,
  partial = 2,
  much = 3
}

export const TRACCIATO_FIELD_KEY_TYPES = [
  { value: TracciatoFieldKeyTypes.none, label: 'No-Key' },
  { value: TracciatoFieldKeyTypes.equals, label: 'Uguale' },
  { value: TracciatoFieldKeyTypes.partial, label: 'Parziale' },
  { value: TracciatoFieldKeyTypes.much, label: 'Contiene' }
];
