import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import {FilterItem} from '../../_models/FilterItem';
import {TipoFormControl} from '../../_helpers/TipoFormControl';
import {STATO_ORDINE} from '../../_helpers/StatoOrdine';
import {Subscription} from 'rxjs';
import {Ordine} from '../../_models/Ordine';
import {LocalService} from '../../_services/local.service';
import {OrdiniShowColumns} from '../../_models/OrdiniShowColumns';

@Component({
  selector: 'app-ordine-local-header',
  templateUrl: './ordine-local-header.component.html',
  styleUrls: ['./ordine-local-header.component.scss'],
})
export class OrdineLocalHeaderComponent implements OnInit, OnDestroy {
  @Input() flagTerminate: boolean;
  @Input() totalCount = 0;
  @Input() sortKey = 'dataRegistrazione';
  @Input() sortDirection = false;
  @Output() sortKeyChange = new EventEmitter<string>();
  @Output() sortDirectionChange = new EventEmitter<boolean>();
  @Output() handleRefresh = new EventEmitter<FilterItem<any>[]>();
  subscription: Subscription;
  newOrdinis: Ordine[] = [];
  showColumns: OrdiniShowColumns;

  localFilters: FilterItem<any>[] = [
    { fieldName:'NumRegistrazione', operazione: 1, value1:'',value2:'', tipoFormControl: TipoFormControl.inputText, showColumn: this.localService.showColumns.value.numRegistrazione },
    { fieldName:'Commessa', operazione: 5, value1:'',value2:'', tipoFormControl: TipoFormControl.inputText, showColumn: this.localService.showColumns.value.commessa },
    { fieldName:'RifOrdine', operazione: 1, value1:'',value2:'', tipoFormControl: TipoFormControl.inputText, showColumn: this.localService.showColumns.value.rifOrdine },
    { fieldName:'CodiceOrdine', operazione: 1, value1:'',value2:'', tipoFormControl: TipoFormControl.selectOrdine, showColumn: this.localService.showColumns.value.idDocumento },
    { fieldName:'RifOdP', operazione: 5, value1:'',value2:'', tipoFormControl: TipoFormControl.inputText, showColumn: this.localService.showColumns.value.rifOdP },
    { fieldName:'NumFase', operazione: 1, value1:'',value2:'', tipoFormControl: TipoFormControl.inputNumber, showColumn: this.localService.showColumns.value.numFase },
    { fieldName:'DataRegistrazione', operazione: 8,
      value1:new Date(2000, 0, 1, 12, 0, 0).toISOString(),
      value2: new Date(2099, 11, 31, 12, 0, 0).toISOString(), tipoFormControl: TipoFormControl.datetime, showColumn: this.localService.showColumns.value.dataTermine },
    { fieldName:'RisorsaId', operazione: 1, value1:'',value2:'', tipoFormControl: TipoFormControl.selectRisorsa, showColumn: this.localService.showColumns.value.risorsa },
    { fieldName:'LavorazioneId', operazione: 1, value1:'',value2:'', tipoFormControl: TipoFormControl.selectLavorazione, showColumn: this.localService.showColumns.value.lavorazione },
    { fieldName:'CodiceArticolo', operazione: 5, value1:'',value2:'', tipoFormControl: TipoFormControl.inputText, showColumn: this.localService.showColumns.value.codiceArticolo },
    { fieldName:'UdM', operazione: 5, value1:'',value2:'', tipoFormControl: TipoFormControl.inputText, showColumn: this.localService.showColumns.value.udM },
    { fieldName:'QuantitaLavorata', operazione: 3, value1:'-99999',value2:'', tipoFormControl: TipoFormControl.inputNumber, showColumn: this.localService.showColumns.value.quantita },
    { fieldName:'Quantita', operazione: 3, value1:'-99999',value2:'', tipoFormControl: TipoFormControl.inputNumber, showColumn: this.localService.showColumns.value.quantitaToDo },
    { fieldName:'Stato', operazione: 1, value1:'',value2:'', tipoFormControl: TipoFormControl.select, options: STATO_ORDINE, showColumn: this.localService.showColumns.value.stato }
  ];
  constructor(private localService: LocalService) { }

  ngOnInit() {
    this.subscription = this.localService.lastOrdineCreated.subscribe((_newOrdine: Ordine) => {
      if (_newOrdine != null && !this.flagTerminate) {
        this.newOrdinis.unshift(_newOrdine);
      }
    });
    this.subscription.add(this.localService.totalCount.subscribe((count: number) => this.totalCount = count));
    this.localService.localFilters.next(this.localFilters);
    this.showColumns = this.localService.showColumns.value;
    this.subscription.add(this.localService.showColumns.subscribe((_showColumns: OrdiniShowColumns) => this.showColumns = _showColumns));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  sort(key: string) {
    if (this.sortKey === key) {
      this.sortDirectionChange.emit(!this.sortDirection);
    }
    this.sortKey = key;
    this.sortKeyChange.emit(this.sortKey);
    this.localService.handleRefresh.next(1);
  }

  refresh = () => {
    this.newOrdinis = [];
    this.localService.localFilters.next(this.localFilters);
    this.localService.handleRefresh.next(1);
  };
}
