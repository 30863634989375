import {Detail, Risorsa} from './_Index';
import {TipoUmTempo} from '../_helpers/TipoUmTempo';

export class OrdineEsolver extends Detail {
  dbgruppo: string;
  idDocumento: number;
  dataRegistrazione: Date;
  numRegistrazione: number;
  idRiga: number;
  codiceArticolo: string;
  descArt: string;
  idRigaLavorazione: number;
  lavorazione: string;
  desLavorazione: string;
  stato: number;
  quantita: number;
  risorsa: string;
  risorsaLocale?: Risorsa;
  reparto: string;
  desRisorsa: string;
  dataTermine: Date;
  hasErrors: boolean;
  hasWarnings: boolean;

  saldato: boolean;
  //rifOrdine: string;
  quantitaLavorata: number;
  quantitaScartata: number;
  tempo: string;
  qtaOrdine: number;
  commessa?: string;
  umTempo?: TipoUmTempo;

  rifLottoAlfanum: string;
  udM: string;
  rifOdP?: string;
  numFase: number;

  rifOrdine?: string;

  lastFase?: number;
}
