import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColumnGroupTypes, COLUMN_GROUP_TYPES } from '../../../_helpers/ColumnGroupTypes';

@Component({
  selector: 'app-select-group-type',
  templateUrl: './select-group-type.component.html',
  styleUrls: ['./select-group-type.component.scss'],
})
export class SelectGroupTypeComponent {
  @Input() groupType: ColumnGroupTypes;
  @Output() groupTypeChange = new EventEmitter<ColumnGroupTypes>();
  columnGroupTypesItem = COLUMN_GROUP_TYPES;
  selectGroupType = (e: any) => this.groupTypeChange.emit(e.target.value);
}
