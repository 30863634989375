import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {SearchResult} from '../_models/SearchResult';
import {Tracciato, TracciatoField} from '../_models/_Index';

const cachePrefix = 'archiconnect-v2-';

@Injectable({ providedIn: 'root' })
export class TracciatiService {
  constructor(private http: HttpClient) {}

  async getTracciatiFilteredAsync(orgId: number, search: string, page: number, count: number, sortField: string, sortAsc: boolean) {
    return await this.http.post<SearchResult<Tracciato>>(`${environment.localApiUrl}Tracciati/TracciatiFiltered?orgId=${orgId}`,
      { search, page, count, sortField, sortAsc })
      .toPromise();
  }

  async getTracciatoAsync(orgId: number, code: string) {
    return await this.http.get<Tracciato>(`${environment.localApiUrl}Tracciati/Tracciato?orgId=${orgId}&code=${code}`)
      .toPromise();
  }

  async getTracciatoFromIdAsync(orgId: number, tracciatoId: number) {
    return await this.http.get<Tracciato>(`${environment.localApiUrl}Tracciati/TracciatoFromId?orgId=${orgId}&tracciatoId=${tracciatoId}`)
      .toPromise();
  }

  async createTracciatoAsync(tracciato: Tracciato) {
    return await this.http.post<Tracciato>(`${environment.localApiUrl}Tracciati/CreateTracciato`, tracciato)
      .toPromise();
  }

  async updateTracciatoAsync(tracciato: Tracciato) {
    return await this.http.post<Tracciato>(`${environment.localApiUrl}Tracciati/UpdateTracciato`, tracciato)
      .toPromise();
  }

  async deleteTracciatoAsync(orgId: number, code: string) {
    return await this.http.get<string>(`${environment.localApiUrl}Tracciati/DeleteTracciato?orgId=${orgId}&code=${code}`)
      .toPromise();
  }

  async getFieldsOfTracciatiAsync(tracciatoId: number) {
    return await this.http.get<SearchResult<TracciatoField>>(`${environment.localApiUrl}Tracciati/GetFieldsOfTracciato?tracciatoId=${tracciatoId}`)
      .toPromise();
  }

  async createTracciatoFieldAsync(field: TracciatoField) {
    return await this.http.post<TracciatoField>(`${environment.localApiUrl}Tracciati/CreateTracciatoField`, field)
      .toPromise();
  }

  async updateTracciatoFieldAsync(field: TracciatoField) {
    return await this.http.post<TracciatoField>(`${environment.localApiUrl}Tracciati/UpdateTracciatoField`, field)
      .toPromise();
  }

  async deleteTracciatoFieldAsync(tracciatoId: number, fieldId: number) {
    return await this.http.get<number>(`${environment.localApiUrl}Tracciati/DeleteTracciatoField?tracciatoId=${tracciatoId}&fieldId=${fieldId}`,{})
      .toPromise();
  }
}
