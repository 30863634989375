export class DatiOrdiniEsolver {
    idDocumento: number;
    idRiga: number;
    idRigaLavorazione: number;
    codiceArticolo: string;
    quantita: number;
    quantitaLavorata: number;
    quantitaScartata: number;
    tempo: string;
    risorsa: string;
    lavorazione: string;
    rifOrdine: string;
    saldato: boolean;
    saldatoRiga: boolean;
    dataRegistrazione: string;
    lottoScarico: string;
    magazzinoPrincipale?: string;
    magazzinoRichiamo?: string;
}
