// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.descFormato {
  color: #3a7be0;
}

.input-readonly {
  color: gray;
}`, "",{"version":3,"sources":["webpack://./src/app/_components/ordini-update-send/ordini-update-send.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEE;EACE,WAAA;AACJ","sourcesContent":[".descFormato {\n    color: #3a7be0;\n  }\n\n  .input-readonly {    \n    color:  gray;    \n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
