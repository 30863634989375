import {Component, EventEmitter, Input, Output} from '@angular/core';
import { TipoAvvio, TIPO_AVVIO } from '../../../_helpers/TipoAvvio';

@Component({
  selector: 'app-select-type-avvio',
  templateUrl: './select-type-avvio.component.html',
  styleUrls: ['./select-type-avvio.component.scss'],
})
export class SelectTypeAvvioComponent  {
  @Input() tipoAvvio: TipoAvvio;
  @Output() tipoAvvioChange = new EventEmitter<TipoAvvio>();
  tipoAvviosItem = TIPO_AVVIO;
  selectTipoAvvio = (e: any) => this.tipoAvvioChange.emit(e.target.value);
}
