import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-select-type-evento',
  templateUrl: './select-type-evento.component.html',
  styleUrls: ['./select-type-evento.component.scss'],
})

export class SelectTypeEventoComponent {
  @Input() initialValue: number;
  @Output() selectedValue = new EventEmitter<number>();
  public eventType =
    [
      { value: 1, text: 'INFO' },
      { value: 2, text: 'WARNING' },
      { value: 3, text: 'ERROR' }
    ];
  selectStato = (e: any) => this.selectedValue.emit(+e.target.value);
}
