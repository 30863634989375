import {TipoOperazione} from '../_helpers/TipoOperazione';
import {TipoFormControl} from '../_helpers/TipoFormControl';


export class FilterItem<T> {
  fieldName: string;
  operazione: TipoOperazione;
  value1: string;
  value2: string;
  tipoFormControl: TipoFormControl;
  options?: T[];
  showColumn: boolean;
}
