import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Modulo, ModuloConfig, ModuloPermesso, Licenza, SearchResult} from '../_models/_Index';
import { environment } from '../../environments/environment';
import {Subject} from 'rxjs';

const cachePrefix = 'archiconnect-v2-';

@Injectable({ providedIn: 'root' })
export class LicenzeService{
  public handleSave: Subject<number> = new Subject();

  constructor(private http: HttpClient) {}

  async getAllModuliFilteredAsync(search: string, page: number, count: number, sortField: string, sortAsc: boolean) {
    return await this.http.post<SearchResult<Modulo>>(`${environment.localApiUrl}Licenze/AllModuli`,
      { search, page, count, sortField, sortAsc })
      .toPromise();
  }

  async getModuliFilteredAsync(search: string, page: number, count: number, sortField: string, sortAsc: boolean) {
    return await this.http.post<SearchResult<Modulo>>(`${environment.localApiUrl}Licenze/ModuliFiltered`,
      { search, page, count, sortField, sortAsc })
      .toPromise();
  }

  async getModulosync(code: string) {
    return await this.http.get<Modulo>(`${environment.localApiUrl}Licenze/Modulo?code=${code}`)
      .toPromise();
  }

  async getModuloFromIdAsync(moduloId: number) {
    return await this.http.get<Modulo>(`${environment.localApiUrl}Licenze/ModuloFromId?moduloId=${moduloId}`)
      .toPromise();
  }

  async createModuloAsync(modulo: Modulo) {
    return await this.http.post<Modulo>(`${environment.localApiUrl}Licenze/CreateModulo`, modulo)
      .toPromise();
  }

  async updateModuloAsync(modulo: Modulo) {
    return await this.http.post<Modulo>(`${environment.localApiUrl}Licenze/UpdateModulo`, modulo)
      .toPromise();
  }

  async deleteModuloAsync(code: string) {
    return await this.http.get<Modulo>(`${environment.localApiUrl}Licenze/DeleteModulo?code=${code}`)
      .toPromise();
  }

  async getModuliConfigFilteredAsync(orgId: number, roleId: number, operatoreId: number) {
    return await this.http
      .get<SearchResult<ModuloConfig>>(`${environment.localApiUrl}Licenze/ModuliConfigFiltered/${orgId}/${roleId}/${operatoreId}`)
      .toPromise();
  }

  async createPermessoAsync(permesso: ModuloPermesso) {
    return await this.http.post<ModuloPermesso>(`${environment.localApiUrl}Licenze/CreatePermesso`, permesso)
      .toPromise();
  }

  async updatePermessoAsync(permesso: ModuloPermesso) {
    return await this.http.post<ModuloPermesso>(`${environment.localApiUrl}Licenze/UpdatePermesso`, permesso)
      .toPromise();
  }

  async updateDatePermessoAsync(organizzazioneId: number, activationDate: Date, expiryDate: Date) {
    return await this.http.post<any>(`${environment.localApiUrl}Licenze/UpdateDatePermessi`, {organizzazioneId, activationDate, expiryDate})
      .toPromise();
  }

  async deletePermessoAsync(moduloId: number, orgId: number,roleId: number, operatoreId: number) {
    return await this.http
      .get<any>(`${environment.localApiUrl}Licenze/DeletePermesso?moduloId=${moduloId}&orgId=${orgId}&roleId=${roleId}&operatoreId=${operatoreId}`)
      .toPromise();
  }

  async getLicenzaFromIdAsync(orgId: number) {
    return await this.http.get<Licenza>(`${environment.localApiUrl}Licenze/LicenzaFromId?organizzazioneId=${orgId}`)
      .toPromise();
  }

  async createLicenzaAsync(licenza: Licenza) {
    return await this.http.post<Licenza>(`${environment.localApiUrl}Licenze/CreateLicenza`, licenza)
      .toPromise();
  }

  async updateLicenzaAsync(licenza: Licenza) {
    return await this.http.post<Licenza>(`${environment.localApiUrl}Licenze/UpdateLicenza`, licenza)
      .toPromise();
  }

  async deleteLicenzaAsync(orgId: number) {
    return await this.http
      .get<any>(`${environment.localApiUrl}Licenze/DeleteLicenza?organizzazioneId=${orgId}`)
      .toPromise();
  }
}
