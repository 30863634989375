import {TracciatoFieldTypesEnum} from './TracciatoFieldTypes';

export enum TracciatoFieldsEnum {
  idDocumento = 'IdDocumento',
  idRiga = 'IdRiga',
  idRigaLavorazione = 'IdRigaLavorazione',
  codiceUnivoco = 'CodiceUnivoco',
  dataEvento = 'DataEvento',
  dataRegistrazione = 'DataRegistrazione',
  dataRegistrazioneDate = 'DataRegistrazioneDate',
  dataRegistrazioneTime = 'DataRegistrazioneTime',
  dataTermine = 'DataTermine',
  dataTermineDate = 'DataTermineDate',
  dataTermineTime = 'DataTermineTime',
  numRegistrazione = 'NumRegistrazione',
  commessa = 'Commessa',
  risorsaId = 'RisorsaId',
  codiceRisorsa = 'CodiceRisorsa',
  lavorazioneId = 'LavorazioneId',
  codiceLavorazione = 'CodiceLavorazione',
  codiceArticolo = 'CodiceArticolo',
  quantita = 'Quantita',
  quantitaLavorata = 'QuantitaLavorata',
  quantitaLavorataCumolo = 'QuantitaLavorataCumolo',
  quantitaScartata = 'QuantitaScartata',
  quantitaScartataCumolo = 'QuantitaScartataCumolo',
  stato = 'StatoSpecial',
  statoRisorsa = 'StatoRisorsa',
  omacState = 'OmacState'
}

export const TRACCIATO_FIELDS = [
  { value: TracciatoFieldsEnum.idDocumento, label: 'ID Documento', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.idRiga, label: 'ID Riga', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.idRigaLavorazione, label: 'ID Riga Lavorazione', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.codiceUnivoco, label: 'Codice Univoco', fieldType: TracciatoFieldTypesEnum.special },
  { value: TracciatoFieldsEnum.dataEvento, label: 'Data e Ora Evento', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.dataRegistrazione, label: 'Data e Ora Registrazione', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.dataRegistrazioneDate, label: 'Data Registrazione', fieldType: TracciatoFieldTypesEnum.special },
  { value: TracciatoFieldsEnum.dataRegistrazioneTime, label: 'Ora Registrazione', fieldType: TracciatoFieldTypesEnum.special },
  { value: TracciatoFieldsEnum.dataTermine, label: 'Data e Ora Termine', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.dataTermineDate, label: 'Data Termine', fieldType: TracciatoFieldTypesEnum.special },
  { value: TracciatoFieldsEnum.dataTermineTime, label: 'Ora Termine', fieldType: TracciatoFieldTypesEnum.special },
  { value: TracciatoFieldsEnum.numRegistrazione, label: 'Numero Registrazione', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.numRegistrazione, label: 'Numero Registrazione', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.commessa, label: 'Commessa', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.commessa, label: 'Commessa', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.risorsaId, label: 'ID Risorsa', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.risorsaId, label: 'ID Risorsa', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.codiceRisorsa, label: 'Codice Risorsa', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.codiceRisorsa, label: 'Codice Risorsa', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.lavorazioneId, label: 'ID Lavorazione', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.lavorazioneId, label: 'ID Lavorazione', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.codiceLavorazione, label: 'Codice Lavorazione', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.codiceLavorazione, label: 'Codice Lavorazione', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.codiceArticolo, label: 'Codice Articolo', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.codiceArticolo, label: 'Codice Articolo', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.quantita, label: 'Quantità Totale', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.quantita, label: 'Quantità Totale', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.quantitaLavorata, label: 'Quantità Lavorata', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.quantitaLavorata, label: 'Quantità Lavorata', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.quantitaLavorataCumolo, label: 'Quantità Lavorata Cumulo', fieldType: TracciatoFieldTypesEnum.special },
  { value: TracciatoFieldsEnum.quantitaScartata, label: 'Quantità Scartata', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.quantitaScartata, label: 'Quantità Scartata', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.quantitaScartataCumolo, label: 'Quantità Scartata Cumulo', fieldType: TracciatoFieldTypesEnum.special },
  { value: TracciatoFieldsEnum.stato, label: 'Stato', fieldType: TracciatoFieldTypesEnum.special },
  { value: TracciatoFieldsEnum.stato, label: 'Stato', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.statoRisorsa, label: 'Stato Risorsa', fieldType: TracciatoFieldTypesEnum.special },
  { value: TracciatoFieldsEnum.omacState, label: 'Ultimo Stato Risorsa', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.omacState, label: 'Ultimo Stato Risorsa', fieldType: TracciatoFieldTypesEnum.fixed }
];
