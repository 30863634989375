import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-select-date-modal',
  templateUrl: './select-date-modal.component.html',
  styleUrls: ['./select-date-modal.component.scss'],
})
export class SelectDateModalComponent implements OnInit {
  @Input() name = 'dateInput';
  @Input() dateValue: Date;
  @Input() presentation = 'date-time';
  @Output() dateValueChange = new EventEmitter<Date>();
  dataOriginale: Date;
  dataCalendar = '';
  constructor() { }

  ngOnInit() {
    if (this.dateValue == null) {
      this.dateValue = new Date();
    }
    this.dataOriginale = new Date(this.dateValue);
    this.dataCalendar = new Date(this.dateValue).toISOString();
  }

  changeDate = () => {
    this.dateValue = new Date(this.dataCalendar);
    this.dateValueChange.emit(new Date(this.dataCalendar));
  };
}
