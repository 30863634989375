import { Component, OnInit } from '@angular/core';
import {OrdiniShowColumns} from '../../_models/OrdiniShowColumns';
import {ModalController} from '@ionic/angular';
import {LocalService} from '../../_services/local.service';
import { constants } from './../../_helpers/constants';
import {AuthenticationService} from '../../_services/authentication.service';

@Component({
  selector: 'app-ordini-show-columns',
  templateUrl: './ordini-show-columns.component.html',
  styleUrls: ['./ordini-show-columns.component.scss'],
})
export class OrdiniShowColumnsComponent  implements OnInit {
  showColumns: OrdiniShowColumns;
  constants = constants;
  constructor(
    public authService: AuthenticationService,
    private modalCtrl: ModalController,
    private localService: LocalService) { }

  ngOnInit() {
    this.showColumns = this.localService.showColumns.value;
  }

  cancel = () => this.modalCtrl.dismiss(false, 'cancel');

  succesSave = () => {
    this.localService.setOrdiniShowColumns(this.showColumns);
    this.modalCtrl.dismiss(true, 'confirm');
  };
}
