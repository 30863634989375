import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timestring'
})

export class TimeStringPipe implements PipeTransform {

  transform(value: number): string {
    if (value == null || value === 0) {
      return '00:00:00';
    }
    return `${this.padLeft(Math.floor((value/60)/60), 2)}:` +
      `${this.padLeft(Math.floor((value / 60)%60),2)}:` +
      `${this.padLeft(Math.floor(value%60), 2)}`;
  }

  padLeft(num: number, size: number) {
    let s = num + '';
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }
}
