import { Detail } from './_Index';

export class RisorsaEsolver extends Detail {
    risorsa: string;
    descrRisorsa: string;
    reparto: string;
}

export class RisorsaEsolverResult {
    macchine: RisorsaEsolver[];
}
