import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import { Chart } from 'chart.js/auto';
import {EventiCounter} from '../../../_models/EventiCounter';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit, OnDestroy {
  @Input() bars: EventiCounter[] = [];
  @Input() chartName: string = '';
  chartId = parseInt((new Date().getTime() / 1000).toString(), 10);
  public chart: any;
  constructor() { }

  ngOnInit() {
    if (this.chart !== undefined) {
      this.chart.destroy();
    }
    this.createChart();
  }

  ngOnDestroy() {
    if (this.chart !== undefined) {
      this.chart.destroy();
    }
  }

  createChart(){
    const labels = [];
    const datas = [];
    const starts = [];
    const dones = [];
    const sends = [];
    if (this.bars && this.bars.length > 0) {
      this.bars.forEach((bar: EventiCounter) => {
        labels.push(new Date(bar.day).toLocaleDateString());
        datas.push(bar.count);
        starts.push(bar.countStart);
        dones.push(bar.countDone);
        sends.push(bar.sendDone);
      });
      this.chart = Chart.getChart('BarChart');
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Chart('BarChart', {
        type: 'bar',
        data: {
          labels,
          datasets: [
            { label: 'Eventi', data: datas, backgroundColor: 'blue',
              borderRadius: Number.MAX_VALUE, borderSkipped: false },
            { label: 'Lanciati', data: starts, backgroundColor: 'violet',
              borderRadius: Number.MAX_VALUE, borderSkipped: false },
            { label: 'Terminati', data: dones, backgroundColor: 'green',
              borderRadius: Number.MAX_VALUE, borderSkipped: false },
            { label: 'Inviati', data: sends, backgroundColor: 'gray',
              borderRadius: Number.MAX_VALUE, borderSkipped: false }
          ]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            }
          }
        }
      });
    }
  }
}
