import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrdineGroup} from '../../_models/OrdineGroup';
import {ModalController} from '@ionic/angular';
import {GroupColumn} from '../../_models/OrdiniGroupColumns';
import {Subscription} from 'rxjs';
import {LocalService} from '../../_services/local.service';
import {Router} from '@angular/router';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';
import {Organizzazione} from '../../_models/Organizzazione';

@Component({
  selector: 'app-ordini-group-update-send',
  templateUrl: './ordini-group-update-send.component.html',
  styleUrls: ['./ordini-group-update-send.component.scss'],
})
export class OrdiniGroupUpdateSendComponent implements OnInit, OnDestroy {
  public currentTab = 'info';
  public ordineGroup: OrdineGroup;
  subscriptions: Subscription;
  groupColumns: GroupColumn[];
  constructor(
    private router: Router,
    private organizzazioniService: OrganizzazioniService,
    private localService: LocalService,
    private modalCtrl: ModalController) { }

  ngOnInit() {
    this.groupColumns = this.organizzazioniService.groupColumns;
    this.subscriptions = this.organizzazioniService.currentOrganizzazione
      .subscribe((_org: Organizzazione) => {
        if (_org) {
          this.groupColumns = this.organizzazioniService.groupColumns;
        }
      });
    this.subscriptions.add(this.localService.searchOrdineGroup
      .subscribe((ordineGroup: OrdineGroup) => this.ordineGroup = ordineGroup));
  }

  checkGroupColumn = (columnName: string) => this.groupColumns
    .some(column => column.columnName === columnName && column.active);

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  goToInterfacciaManuale = () => {
    this.modalCtrl.dismiss()
      .then(() => this.router.navigate(['/interfacciaManualeBase']));
  };

  dismiss() {
    this.modalCtrl.dismiss(true, 'save');
  }
}
