export enum TipoOperazione {
  equals = 1,
  notEquals = 2,
  major = 3,
  minor = 4,
  like = 5,
  startWith = 6,
  endWith = 7,
  between = 8,
  majorOrEqual = 9,
  minorOrEqual = 10
}
