import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';
import {LottiService} from '../../_services/lotti.service';
import {Lotto} from '../../_models/Lotto';
import {SaveMessage} from '../../_helpers/save-message';
import {ArticoliService} from '../../_services/articoli.service';
import {SearchResult} from '../../_models/SearchResult';
import {Giacenza} from '../../_models/Giacenza';

@Component({
  selector: 'app-lotto-input',
  templateUrl: './lotto-input.component.html',
  styleUrls: ['./lotto-input.component.scss'],
})
export class LottoInputComponent  implements OnInit {
@Input() codArt: string;
@Input() rifLotto = '';
@Output() rifLottoChange = new EventEmitter<string>();
@Input() rifMagazzino = '';
@Output() rifMagazzinoChange = new EventEmitter<string>();
@Input() rifMagArea = '';
@Output() rifMagAreaChange = new EventEmitter<string>();

lotto: Lotto = {
  codArt: '',
  rifLottoAlfa: '',
  dataAperturaLotto: new Date()
};
giacenze: Giacenza[] = [];
showGiacenza = false;
isValid = -1;
loading = false;
  constructor(
    private saveMessage: SaveMessage,
    private organizzazioniService: OrganizzazioniService,
    private lottiService: LottiService,
    private articoliService: ArticoliService) { }

  ngOnInit() {
    this.lotto.codArt = this.codArt;
    this.lotto.rifLottoAlfa = this.rifLotto;
    if (this.rifLotto && this.rifLotto.length > 0) {
      this.getLotto();
    }
  }

  createLotto() {
    this.loading = true;
    this.lotto.des = null;
    this.lotto.dataAperturaLotto = new Date();
    this.lottiService.createLottoAsync(this.organizzazioniService.organizzazioneId, this.lotto)
      .then((_lotto: Lotto) => {
        if (!_lotto.codArt) {
          this.saveMessage.messageError('Problemi con creazione lotto');
          this.isValid = -1;
          return false;
        }
        this.saveMessage.messageInfo('Lotto creato con successo');
        this.lotto = _lotto;
        this.isValid = 1;
        this.rifLottoChange.emit(this.lotto.rifLottoAlfa);
      })
      .catch(() => this.saveMessage.messageInfo('Error creating lotto'))
      .finally(() => this.loading = false);
  }

  getLotto() {
    this.loading = true;
    this.isValid = -1;
    this.rifLottoChange.emit(this.lotto.rifLottoAlfa);
    this.lottiService.leggiLottoAsync(this.organizzazioniService.organizzazioneId, this.lotto)
      .then((_lotto: Lotto) => {
        if (_lotto.codArt === this.lotto.codArt) {
          this.saveMessage.messageInfo('Lotto trovato');
          this.lotto = _lotto;
          this.isValid = 1;
          this.rifLottoChange.emit(this.lotto.rifLottoAlfa);
          return;
        }
        if (_lotto.rifLottoAlfa === this.lotto.rifLottoAlfa) {
          this.isValid = 0;
          this.saveMessage.messageError('Lotto trovato ma articolo diverso');
        }
      })
      .catch(() => this.saveMessage.messageInfo('Errore durante ricerca lotto'))
      .finally(() => this.loading = false);
  }

  progressivoLotto() {
    this.loading = true;
    this.lottiService.leggiProgressivoAsync(this.organizzazioniService.organizzazioneId, this.lotto)
      .then((_lotto: Lotto) => {
        this.lotto.rifLottoAlfa = _lotto.rifLottoAlfa;
        this.isValid = -1;
        this.rifLottoChange.emit(this.lotto.rifLottoAlfa);
      })
      .catch(() => this.saveMessage.messageInfo('Error progressivo lotto'))
      .finally(() => this.loading = false);
  }

  getGiacenza() {
    this.showGiacenza = !this.showGiacenza;
    if (!this.showGiacenza) {
      this.giacenze = [];
      return false;
    }
    this.loading = true;
    this.articoliService.getGiacenza(this.organizzazioniService.organizzazioneId, this.lotto.codArt)
      .then((_giacenze: SearchResult<Giacenza>) => {
        if (_giacenze.totalCount > 0) {
          this.giacenze = _giacenze.result;
        }
      })
      .catch(() => this.saveMessage.messageInfo('Error giacenza articolo'))
      .finally(() => this.loading = false);
  }

  selectGiacenza(giacenza: Giacenza) {
    this.lotto.rifLottoAlfa = giacenza.codLottoAlfa;
    this.rifMagazzinoChange.emit(giacenza.codMag);
    this.rifLottoChange.emit(giacenza.codAreaMag);
    this.giacenze = [];
    this.showGiacenza = false;
    this.getLotto();
  }
}
