import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {StatiOrdine,STATO_ORDINE_DICTIONARY} from '../../../_helpers/StatoOrdine';

@Component({
  selector: 'app-tracciato-field-stato',
  templateUrl: './tracciato-field-stato.component.html',
  styleUrls: ['./tracciato-field-stato.component.scss'],
})
export class TracciatoFieldStatoComponent implements OnInit {
  @Input() statoCode: string;
  @Output() statoCodeChange = new EventEmitter<string>();
  stati = StatiOrdine;
  statiDict = STATO_ORDINE_DICTIONARY;

  ngOnInit() {
    if (this.statoCode == null){
      return false;
    }
    const originalValues = this.statoCode.split(';');
    originalValues.forEach((original: string) => {
      const value = original.split('=');
      if (value.length === 2) {
        this.statiDict[value[0]].newValue = value[1];
      }
    });
  }

  onChangeValue = () => {
    const keys = Object.keys(this.stati);
    this.statoCode = '';
    keys.forEach((key) => {
      if (this.statiDict[key] != null) {
        this.statoCode += key + '=' + this.statiDict[key].newValue + ';';
      }
    });
    this.statoCodeChange.emit(this.statoCode);
  };
}
