import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-select-filter-row-operation',
  templateUrl: './select-filter-row-operation.component.html',
  styleUrls: ['./select-filter-row-operation.component.scss'],
})
export class SelectFilterRowOperationComponent {
  @Input() operation;
  @Output() operationChange = new EventEmitter<number>();
  operations = [
    {value: 1, text: '='},
    {value: 2, text: '!'},
    {value: 3, text: '>'},
    {value: 4, text: '<'},
    {value: 5, text: '*'},
    {value: 6, text: 'S'},
    {value: 7, text: 'E'},
    {value: 8, text: 'B'}
  ];
  selectOperation = (e: any) => this.operationChange.emit(+e.target.value);
}
