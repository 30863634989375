import { BaseModel } from './_Index';
import {AccountOrganizzazione} from './AccountOrganizzazione';

export class Organizzazione extends BaseModel {
  codiceGruppo: string;
  tipoInstallazione: number;
  pathFile: string;
  indirizzoApiEsolver: string;
  hostFtp: string;
  utenteFtp: string;
  passwordFtp: string;
  hostApiEsolver: string;
  usernameApiEsolver: string;
  passwordApiEsolver: string;

  accountSettings?: AccountOrganizzazione;
}
