import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {OrdineEsolver} from '../../_models/OrdineEsolver';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';
import {LocalService} from '../../_services/local.service';
import {SaveMessage} from '../../_helpers/save-message';
import {SearchResult} from '../../_models/SearchResult';
import {Subscription} from 'rxjs';
import {OrdineEsolverGroup} from '../../_models/OrdineEsolverGroup';
import {FilterItem} from '../../_models/FilterItem';
import {GroupEsolverResult} from '../../_models/GroupEsolverResult';

@Component({
  selector: 'app-ordini-esolver-groupbymany',
  templateUrl: './ordini-esolver-groupbymany.component.html',
  styleUrls: ['./ordini-esolver-groupbymany.component.scss'],
})
export class OrdiniEsolverGroupbymanyComponent  implements OnInit, OnDestroy {
  @Input() filters: FilterItem<any>[] = [];
  @Output() ordineEsolverGroupSended = new EventEmitter<OrdineEsolverGroup>();
  sortDirection = true;
  sortKey = 'dataTermine';
  ordiniGroups: GroupEsolverResult<OrdineEsolver>[] = [];
  page = 0;
  count = 20;
  loadMore = false;
  loading = false;
  selectedOrdiniGroup: OrdineEsolverGroup;
  subscription: Subscription;
  constructor(
    private organizzazioniService: OrganizzazioniService,
    private localService: LocalService,
    private saveMessage: SaveMessage) {}

  ngOnInit() {
    this.selectedOrdiniGroup = this.localService.getEmptyOrderEsolver();
    this.selectedOrdiniGroup.ordini = [];
    //this.getOrdiniEsolverGroupByMany(false);
    this.subscription = this.localService.searchText.subscribe((_value: string) => {
      this.getOrdiniEsolverGroupByMany(false);
    });
    this.subscription.add(this.localService.handleRefresh.subscribe(() => this.getOrdiniEsolverGroupByMany(false)));
  }

  getOrdiniEsolverGroupByMany = (add: boolean) => {
    this.loadMore = true;
    this.loading = true;
    if (!add) {
      this.ordiniGroups = [];
      this.page = 0;
    } else {
      this.page += 1;
    }
    const search = this.localService.searchText.getValue().toString();
    const groupColumns = this.organizzazioniService.groupColumnsArray;
    this.localService.getOrdiniGroupByManyEsolver(this.organizzazioniService.organizzazioneId, this.organizzazioniService.gruppoCodice,
      this.organizzazioniService.organizzazioneCodice, search,this.page, this.count,
      this.sortKey, this.sortDirection, true, true, this.filters, groupColumns)
      .then((_result: SearchResult<GroupEsolverResult<OrdineEsolver>>) => {
        if (_result.totalCount === 0) {
          this.ordiniGroups = [];
          return false;
        }
        if (!add) {
          this.ordiniGroups = _result.result;
        } else {
          _result.result.forEach((ord: GroupEsolverResult<OrdineEsolver>) => this.ordiniGroups.push(ord));
        }
        this.loadMore = _result.totalCount > this.ordiniGroups.length;
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con raggruppamento gerarchico'))
      .finally(() => this.loading = false);
  };

  addOrdiniEsolver = (ordiniEsolver: OrdineEsolver[]) => ordiniEsolver.forEach((_ordineEsolver: OrdineEsolver) => {
    if (this.selectedOrdiniGroup.ordini.indexOf(_ordineEsolver) < 0) {
      this.selectedOrdiniGroup.ordini.push(_ordineEsolver);
    }
  });

  confirmOrdineEsolverGroup = () => this.ordineEsolverGroupSended.emit(this.selectedOrdiniGroup);

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
