import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { StatisticheService } from '../../_services/statistiche.service';
import { EventiService } from '../../_services/eventi.service';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';
import {SaveMessage} from '../../_helpers/save-message';
import {Subscription} from 'rxjs';
import {OrdiniStat} from '../../_models/OrdiniStat';

@Component({
  selector: 'app-ordini-statistics',
  templateUrl: './ordini-statistics.component.html',
  styleUrls: ['./ordini-statistics.component.scss'],
})
export class OrdiniStatisticsComponent implements OnInit, OnDestroy {
  @Input() risorsaId = -1;
  loading = false;
  startDate = new Date().toISOString();
  endDate = new Date().toISOString();
  ordiniStat: OrdiniStat;
  tempoAttualeSecondi = 0;
  subscription: Subscription;
  constructor(
    private eventiService: EventiService,
    private statisticheService: StatisticheService,
    private organizzazioniService: OrganizzazioniService,
    private saveMessage: SaveMessage) { }

  ngOnInit() {
    const today = new Date();
    this.startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0,0,0).toISOString();
    this.subscription = this.eventiService.lastEventoCreated.subscribe(() => this.getStatistics());
    this.subscription.add(this.organizzazioniService.currentOrganizzazione.subscribe(() => this.getStatistics()));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getStatistics = () => {
    this.loading = true;
    this.statisticheService
      .getStatistics(new Date(this.startDate), new Date(this.endDate), this.organizzazioniService.organizzazioneId, this.risorsaId)
      .then((_ordiniStat: OrdiniStat) => this.ordiniStat = _ordiniStat)
      .catch(() => this.saveMessage.messageError('Ci sono problemi con il caricamento delle statistiche.'))
      .finally(() => this.loading = false);
  };

}
