// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.descFormato {
  font-size: 22px !important;
  color: rgb(59, 130, 246);
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/app/_components/select/select-stati-ordini/select-stati-ordini.component.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,wBAAA;EACA,gBAAA;AACJ","sourcesContent":[".descFormato {\n    font-size: 22px !important;\n    color: rgb(59 130 246);\n    text-align: left;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
