import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Ordine} from '../../_models/Ordine';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';
import {LocalService} from '../../_services/local.service';
import {SaveMessage} from '../../_helpers/save-message';
import {Organizzazione} from '../../_models/Organizzazione';
import {Subscription} from 'rxjs';
import {SearchResult} from '../../_models/SearchResult';
import {InfiniteScrollCustomEvent} from '@ionic/angular';
import {RisorseService} from '../../_services/risorse.service';
import {LavorazioniService} from '../../_services/lavorazioni.service';

@Component({
  selector: 'app-ordini-locali-list',
  templateUrl: './ordini-locali-list.component.html',
  styleUrls: ['./ordini-locali-list.component.scss'],
})
export class OrdiniLocaliListComponent implements OnInit, OnDestroy {
  @Input() loading: boolean;
  @Input() updateEnable: boolean;
  @Input() flagTerminate: boolean;
  @Input() sortKey: string;
  @Input() sortDirection: boolean;
  @Input() isPersonal: boolean;
  @Output() loadingChange = new EventEmitter<boolean>();
  @Output() totalQtaChange = new EventEmitter<number>();
  @Output()  ordineSended = new EventEmitter<Ordine>();
  loadMore = false;
  ordinis: Ordine[] = [];
  subscription: Subscription;
  page = 0;
  count = 20;
  filtroOrdini = 0;
  organizzazione: Organizzazione;

  constructor(
    private risorseService: RisorseService,
    private lavorazioniService: LavorazioniService,
    private organizzazioniService: OrganizzazioniService,
    private localService: LocalService,
    private saveMessage: SaveMessage) { }

  ngOnInit() {
    this.subscription = this.localService.searchText.subscribe((_value: string) => this.readOrdiniInviati(false));
    this.subscription.add(this.organizzazioniService
        .currentOrganizzazioneSubject.subscribe((_org: Organizzazione) => {
          if (_org && _org.accountSettings) {
            this.filtroOrdini = _org.accountSettings.tipoFiltroOrdini;
          }
          this.organizzazione = _org;
          this.readOrdiniInviati(false);
        }));
    this.subscription.add(this.localService.lastOrdineUpdated.subscribe((_updOrdine: Ordine) => {
      if (_updOrdine != null && _updOrdine.flgTerminato !== this.flagTerminate) {
        this.excludeOrdine(_updOrdine);
      } else if (_updOrdine != null && this.ordinis
        .findIndex((_ord: Ordine) => _ord.idDocumento === _updOrdine.idDocumento && _ord.idRiga === _updOrdine.idRiga &&
        _ord.idRigaLavorazione === _updOrdine.idRigaLavorazione) < 0) {
        if (this.filtroOrdini === 1 || this.filtroOrdini === 3) {
          const risorsa = this.risorseService.myRisorse.value
            .filter((_risorsa) => _risorsa.id === _updOrdine.risorsaId);
          if (!risorsa) {
            return;
          }
        }
        if (this.filtroOrdini === 2 || this.filtroOrdini === 3) {
          const lavorazione = this.lavorazioniService.myLavorazioni.value
            .filter((_lavorazione) => _lavorazione.id === _updOrdine.lavorazioneId);
          if (!lavorazione) {
            return;
          }
        }
        this.ordinis.unshift(_updOrdine);
        this.totalQtaChange.emit(this.ordinis.length);
      }
    }));
    this.subscription.add(this.localService.lastOrdineDeleted.subscribe((_delOrdine: Ordine) => {
      if (_delOrdine != null) {
        this.excludeOrdine(_delOrdine);
      }
    }));
    this.subscription.add(this.localService.handleRefresh.subscribe(() => this.readOrdiniInviati(false)));
  }

  infiniteScrollEventi(event) {
    this.readOrdiniInviati(true)
      .then(() => (event as InfiniteScrollCustomEvent).target.complete());
  }

    readOrdiniInviati(add: boolean) {
        this.loadMore = true;
        if (!add) {
            this.ordinis = [];
            this.page = 0;
        } else {
            this.page += 1;
        }
        const search = this.localService.searchText.getValue().toString();
        this.totalQtaChange.emit(0);
        this.loadingChange.emit(true);
        return this.localService.getOrdiniInviatiFiltered(this.organizzazioniService.organizzazioneId,
            this.flagTerminate, search, this.page, this.count, this.sortKey, this.sortDirection,
            this.localService.localFilters.value, this.isPersonal)
            .then((_result: SearchResult<Ordine>) => {
                this.localService.totalCount.next(_result.totalCount);
                this.sumQta();
                if (_result.totalCount === 0) {
                    this.ordinis = [];
                    this.loadMore = false;
                    return false;
                }
                if (!add) {
                    this.ordinis = _result.result;
                } else {
                    _result.result.forEach((ord: Ordine) => this.ordinis.push(ord));
                }
              this.loadMore = _result.totalCount > this.ordinis.length;
            })
            .catch(() => this.saveMessage.messageError('Ci sono problemi con le API ArchiConnect'))
            .finally(() => this.loadingChange.emit(false));
    }

    sumQta = () => {
      let totalQta = 0;
      this.ordinis.forEach((ordine: Ordine) => totalQta += ordine.quantita);
      this.totalQtaChange.emit(totalQta);
    };

    excludeOrdine = (ordine: Ordine) => {
        this.ordinis = this.ordinis.filter((_ordine: Ordine) =>
            !(ordine.idDocumento === _ordine.idDocumento &&
                ordine.idRiga === _ordine.idRiga &&
                ordine.idRigaLavorazione === _ordine.idRigaLavorazione));
      this.totalQtaChange.emit(this.ordinis.length);
    };

  selectOrdine = (ordine: Ordine) => {
    this.ordineSended.emit(ordine);
  };

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
