import { Detail } from './Detail';

export class RisorsaModBus extends Detail {
    risorsaId: number;
    //CAMPI WRITE
    idDocumentoAddress: number;
    idRigaAddress: number;
    idRigaLavorazioneAddress: number;
    recipeAddress: number;
    qtyAddress: number;
    articleCodeAddress: number;
    //CAMPI READ
    actualIdDocumentoAddress: number;
    actualIdRigaAddress: number;
    actualIdRigaLavorazioneAddress: number;
    actualRecipeAddress: number;
    actualQtyAddress: number;
    actualQtyHour: number;
    actualState: number;
    actualAlarmState: number;
    actualRejectAddress: number;
}
