export enum TipoAvvio {
  single = 0,
  byRisorsa = 1,
  byLavorazione = 2,
  byAll = 99
}

export const TIPO_AVVIO = [
  { value: TipoAvvio.single, label: 'Singolo' },
  { value: TipoAvvio.byRisorsa, label: 'Risorse simultanee' },
  { value: TipoAvvio.byLavorazione, label: 'Lavorazioni simultanee' },
  { value: TipoAvvio.byAll, label: 'Tutti simultanei' }
];
