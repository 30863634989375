import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AvanzamentoService } from '../../_services/avanzamento.service';
import { LocalService } from '../../_services/local.service';
import { EventiService } from '../../_services/eventi.service';
import { OrganizzazioniService } from '../../_services/organizzazioni.service';
import {Ordine} from '../../_models/_Index';
import { StatoRisorsa } from '../../_helpers/stato-risorsa';
import {MessageConfirm} from '../../_helpers/message-confirm.service';
import {SaveMessage} from '../../_helpers/save-message';
import {constants} from '../../_helpers/constants';
import {AuthenticationService} from '../../_services/authentication.service';

@Component({
  selector: 'app-ordini-update-send',
  templateUrl: './ordini-update-send.component.html',
  styleUrls: ['./ordini-update-send.component.scss'],
})
export class OrdiniUpdateSendComponent implements OnInit {
  currentTab = 'avanza';
  ordine: Ordine;
  dataOrdine = new Date();
  aggStato: number;
  aggTerminato: boolean;
  aggTerminatoRiga = false;
  viewElimina = true;
  aggElimina = false;
  aggQtaLav: number;
  aggQtaScar: number;
  aggLottoScarico = '';
  loading = false;
  changeStato = false;
  changeFlagTerminato = false;
  toSend = false;
  protected readonly constants = constants;

  constructor(
    public authService: AuthenticationService,
    private messageConfirm: MessageConfirm,
    private saveMessage: SaveMessage,
    private modalCtrl: ModalController,
    private avanzamentoService: AvanzamentoService,
    private localService: LocalService,
    private eventiService: EventiService,
    private organizzazioniService: OrganizzazioniService) { }

  ngOnInit() {
    this.setOrdineField();
  }

  setOrdineField() {
    if (this.ordine.flgTerminato) {
      this.aggTerminato = this.ordine.flgTerminato;
    } else {
      if (this.ordine.quantitaLavorata - this.ordine.quantitaScartata >= this.ordine.quantita) {
        this.aggTerminato = true;
      } else {
        this.aggTerminato = false;
      }
    }
    this.viewElimina = this.ordine.statoElimina == 0;
    this.aggElimina = this.aggTerminato;
    this.aggStato = this.ordine.stato;
    if (this.aggTerminato){
      this.aggStato = 4;
    }
    this.aggQtaLav = this.ordine.quantitaLavorata;
    this.aggQtaScar = this.ordine.quantitaScartata;
    this.localService
      .isLastOrdineOfRiga(this.organizzazioniService.organizzazioneId, this.ordine.idDocumento,
        this.ordine.idRiga, this.ordine.idRigaLavorazione)
      .then((_result: any) => this.ordine.isLastOfRiga = _result.isLast)
      .catch(() => this.saveMessage.messageError('Ci sono problemi con verifica ultima fase'));
  }

  cancel = () => this.modalCtrl.dismiss(false, 'cancel');
  updateTerminato = () => this.aggStato = (!this.aggTerminato ? 4 : this.aggStato);

  updateLocalOrdine() {
    this.ordine.stato = this.aggStato;
    this.ordine.flgTerminato = this.aggTerminato;
    this.ordine.statoElimina = (this.viewElimina && this.aggElimina) ? 1 : this.ordine.statoElimina;
    this.ordine.quantitaLavorata = +this.aggQtaLav;
    this.ordine.quantitaScartata = +this.aggQtaScar;
  }

  checkChanges() {
    if (this.ordine.stato !== this.aggStato) {
      this.changeStato = true;
    }
    if (this.ordine.flgTerminato !== this.aggTerminato && this.aggTerminato) {
      this.changeFlagTerminato = true;
    }
  }

  save() {
    this.loading = true;
    this.checkChanges();
    this.toSend = false;
    this.updateLocalOrdine();
    this.localService.updateOrdine(this.ordine)
      .then(() => this.creaEventoStato())
      .finally(() => this.loading = false);
  }

  creaEventoStato() {
    this.loading = true;
    if (this.changeStato) {
      this.eventiService.creaEvento(this.aggStato, this.ordine,new Date())
        .then(() => this.creaEventoFlagTerminato())
        .finally(() => this.loading = false);
    } else {
      this.creaEventoFlagTerminato();
    }
  }

  creaEventoFlagTerminato() {
    if (this.changeFlagTerminato) {
      this.eventiService.creaEvento(StatoRisorsa.END_FLAG, this.ordine, new Date())
        .then(() => this.dismiss())
        .finally(() => this.loading = false);
    } else {
      this.dismiss();
    }
  }

  dismiss() {
    if (this.toSend) {
      this.modalCtrl.dismiss(true, 'send');
    }
    else {
      this.modalCtrl.dismiss(true, 'save');
    }
  }

  async send() {
    this.loading = true;
    this.checkChanges();
    this.toSend = true;
    this.localService.getOrdiniTempoAsync(this.organizzazioniService.organizzazioneId, this.ordine.idDocumento,
      this.ordine.idRiga, this.ordine.idRigaLavorazione)
      .then((_ordineTempo: Ordine) => {
        this.ordine = _ordineTempo;
        this.updateLocalOrdine();
        this.localService.updateOrdine(this.ordine);
        this.avanzamentoService
          .invioFileEsolverFromOrdine(this.ordine,this.dataOrdine,this.organizzazioniService.currentOrganizzazioneValue,
            this.aggLottoScarico, this.aggTerminatoRiga)
          .then(() => this.creaEventoStato())
          .catch(() => {
            this.saveMessage.messageError('Ci sono problemi con invio file');
            this.modalCtrl.dismiss(true, 'error');
          })
          .finally(() => this.loading = false);
      });
  }

  deleteOrdine = (ordine: Ordine) => {
    this.loading = true;
    this.messageConfirm
      .presentAlert(`Confermi di eliminare ordine ${ordine.numRegistrazione} `+
        `(${ordine.idDocumento}.${ordine.idRiga}.${ordine.idRigaLavorazione}) e tutti gli eventi collegati?`, () => {
        this.localService.deleteOrdineAsync(this.organizzazioniService.organizzazioneId, ordine.idDocumento,
          ordine.idRiga,ordine.idRigaLavorazione)
          .then(() => {
            this.ordine.onQueue = true;
            this.saveMessage.messageInfo('Richiesta di eliminare ordine inviata con successo');
            this.modalCtrl.dismiss(true, 'delete');
          })
          .catch(() => this.saveMessage.messageError('Ci sono problemi con eliminazione di ordine.'))
          .finally(() => this.loading = false);
      });
  };
}
