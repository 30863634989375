import {Component, OnInit, ViewChild} from '@angular/core';
import {Operatore} from '../../_models/Operatore';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../../_services/authentication.service';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent  implements OnInit {
  @ViewChild('popover') popover;
  isOpen = false;
  firstName = '';
  organizzazioneCode = '';
  organizzazioneName = '';
  subscription: Subscription;
  constructor(
    private organizzazioniService: OrganizzazioniService,
    public authService: AuthenticationService) { }

  ngOnInit() {
    this.subscription = this.authService.currentUser.subscribe((_user: Operatore) => {
      if (_user != null) {
        this.firstName = _user.email;
      } else {
        this.firstName = '';
      }
    });
    this.organizzazioneCode = this.organizzazioniService.organizzazioneCodice;
    this.subscription.add(this.organizzazioniService.currentOrganizzazione
      .subscribe(()=> {
        if (this.organizzazioniService.currentOrganizzazioneValue !== null) {
          this.organizzazioneName = this.organizzazioniService.currentOrganizzazioneValue.descrizione;
        }
        this.organizzazioneCode = this.organizzazioniService.organizzazioneCodice;
      }));
  }

  onClose = () => {
    this.isOpen = false;
  };

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  logout = () => this.authService.logout();
}
