import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-select-type-umtempo',
  templateUrl: './select-type-umtempo.component.html',
  styleUrls: ['./select-type-umtempo.component.scss'],
})
export class SelectTypeUmtempoComponent {
  @Input() typeUmTempo: number;
  @Output() typeUmTempoChange = new EventEmitter<number>();
  public umTempoTypes =
    [
      { value: 0, text: 'Giorni' },
      { value: 1, text: 'Ore' },
      { value: 2, text: 'Minuti' },
      { value: 3, text: 'Secondi' },
      { value: 4, text: 'Quantità ore' },
      { value: 5, text: 'Quantità minuti' },
      { value: 6, text: 'Quantità secondi' }
    ];
  selectTypeUmTempo = (e: any) => this.typeUmTempoChange.emit(+e.target.value);
}
