import {Component, Input, OnInit} from '@angular/core';
import {ModuloConfig} from '../../../_models/ModuloConfig';
import {LicenzeService} from '../../../_services/licenze.service';
import {SaveMessage} from '../../../_helpers/save-message';

@Component({
  selector: 'app-licenze-config-item',
  templateUrl: './licenze-config-item.component.html',
  styleUrls: ['./licenze-config-item.component.scss'],
})
export class LicenzeConfigItemComponent implements OnInit {
  @Input() permesso: ModuloConfig;
  @Input() organizzazioneId: number;
  @Input() licenzaId: number;
  @Input() roleId = -1;
  @Input() operatoreId = -1;
  @Input() level = '';
  loading = false;
  constructor(
    private saveMessage: SaveMessage,
    private licenzeService: LicenzeService) { }

  ngOnInit() {}

  changePermesso = () => this.permesso.isAvailable ? this.removePermesso() : this.addPermesso();

  addPermesso = () => {
    this.loading = true;
    this.licenzeService.createPermessoAsync({
      moduloId: this.permesso.id,
      organizzazioneId: +this.organizzazioneId,
      roleId: this.roleId === -1 ? 0 : +this.roleId,
      operatoreId: this.operatoreId === -1 ? 0 : +this.operatoreId,
      activationDate: new Date(),
      expiryDate: new Date(),
      licenzaId: +this.licenzaId
    })
      .then(() => {
        this.permesso.isAvailable = true;
        this.saveMessage.messageInfo('Licenza aggiornata con successo');
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con licenza'))
      .finally(() => this.loading = false);
  };

  removePermesso = () => {
    this.loading = true;
    this.licenzeService
      .deletePermessoAsync(this.permesso.id, +this.organizzazioneId, this.roleId === -1 ? 0 : +this.roleId,this.operatoreId === -1 ? 0 : +this.operatoreId)
      .then(() => {
        this.permesso.isAvailable = false;
        this.saveMessage.messageInfo('Licenza rimossa con successo');
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con licenza'))
      .finally(() => this.loading = false);
  };

}
