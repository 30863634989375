import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { NotificheService } from '../../_services/notifiche.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-centro-notifiche',
  templateUrl: './centro-notifiche.component.html',
  styleUrls: ['./centro-notifiche.component.scss'],
})
export class CentroNotificheComponent implements OnInit, OnDestroy {
  @ViewChild('popover') popover;
  isOpen = false;
  subscription: Subscription;
  notifiche = [];
  notificheAlert = [];
  tempoAttualeSecondi = 0;
  constructor(private notificheService: NotificheService) { }

  ngOnInit() {
    this.subscription = this.notificheService.notifiche.subscribe((_notifiche) => this.notifiche =_notifiche);
    this.subscription.add(this.notificheService.notificheAlert.subscribe((_alert) => this.notificheAlert = _alert));
    setInterval(() => this.tempoAttualeSecondi = new Date().valueOf() / 1000, 10);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onClose = () => {
    this.notifiche = [];
    this.isOpen = false;
  };

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }
}
