import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss'],
})
export class KeyboardComponent {
  @Input() keyboardValue: number;
  @Output() keyboardValueChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() confirmClick: EventEmitter<number> = new EventEmitter<number>();
  @Output() undoClick: EventEmitter<number> = new EventEmitter<number>();
  onConfirmButtonClick = () => this.confirmClick.emit(this.keyboardValue);
  onUndoButtonClick = () => this.undoClick.emit(-1);

  onResetButtonClick = () => this.keyboardValue = 0;

  onKeyboardButtonClick(value: number) {
    if (value < 0) {
      this.keyboardValue = Math.floor(this.keyboardValue / 10);
    } else {
      this.keyboardValue = this.keyboardValue * 10 + value;
    }
  }
}
