import {Component, Input, OnInit} from '@angular/core';
import {OrdineGroup} from '../../_models/OrdineGroup';
import {StatoRisorsa} from '../../_helpers/stato-risorsa';

@Component({
  selector: 'app-ordine-sessioni',
  templateUrl: './ordine-sessioni.component.html',
  styleUrls: ['./ordine-sessioni.component.scss'],
})
export class OrdineSessioniComponent implements OnInit {
  @Input() ordine: OrdineGroup;
  tempoAttualeSecondi = 0;
  constructor() { }

  ngOnInit() {
    setInterval(() => {
      const dataOggi = new Date();
      if (this.ordine == null) {
        return;
      }
      this.ordine.tempoParzialeLav = '0';
      this.tempoAttualeSecondi = 0;
      //this.ordine.tempoParzialeLav = this.ordine.partialWorkingHours.toString();
      if (this.ordine.stato === StatoRisorsa.START) {
        this.ordine.tempoParzialeLav = ((((dataOggi.valueOf() / 1000) - this.ordine.lastActionTime) / 60) / 60).toFixed(4);
        this.tempoAttualeSecondi = (dataOggi.valueOf() / 1000) - this.ordine.lastActionTime;
      }
    }, 10);
  }

}
