import { Component, OnInit, Input } from '@angular/core';
import {Materiale} from '../../_models/Materiale';
import { ModalController } from '@ionic/angular';
import { MovimentoAddComponent } from '../movimenti/movimento-add/movimento-add.component';

@Component({
  selector: 'app-materiale-list',
  templateUrl: './materiale-list.component.html',
  styleUrls: ['./materiale-list.component.scss'],
})
export class MaterialeListComponent  implements OnInit {
  @Input() materiali: Materiale[];
  viewType = 'grid';
  constructor(
    private modalCtrl: ModalController) { }

  ngOnInit() {}

  async openAddMovimento(codArt: string, rifOdP: string, idRiga: number, udM: string, lotto: string, qtaX1: number) {
    const modal = await this.modalCtrl.create({
      component: MovimentoAddComponent,
      backdropDismiss: false,
      componentProps: { codArt, rifOdP, idRiga, udM, lotto, qtaX1 }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {}
  };

  newMovimento() {
    this.openAddMovimento('', '', 0, 'N.', '', 1);
  }

  setViewType = () =>
    this.viewType = this.viewType === 'list' ? 'grid' : 'list';
}
