import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-select-date-range',
  templateUrl: './select-date-range.component.html',
  styleUrls: ['./select-date-range.component.scss'],
})
export class SelectDateRangeComponent implements OnInit {
  singleDate = false;
  startDate = '';
  //@Output() startDateChange = new EventEmitter<string>();
  endDate = '';
  //@Output() endDateChange = new EventEmitter<string>();
  //@Output() refresh = new EventEmitter();
  selectedDate: string;
  step = 0;
  constructor(private modalController: ModalController) { }

  ngOnInit() {
    this.selectedDate = this.startDate;
    this.step = 1;
  }
  nextStep = (add: boolean) => {
    if (!add && this.step === 2) {
      this.selectedDate = this.startDate;
    }
    if (add && this.step === 1) {
      this.startDate = this.selectedDate;
      //this.startDateChange.emit(this.selectedDate);
      this.selectedDate = this.endDate;
    }
    if (add && this.step === 2) {
      this.endDate = this.selectedDate;
      //this.endDateChange.emit(this.selectedDate);
    }
    this.changeStep(add);
  };

  changeStep = (add: boolean) => this.step += add ? 1 : -1;

  isOverStart = (dateString: string) => {
    const date = new Date(dateString);
    return date > new Date(this.startDate) || this.singleDate || this.step < 2;
  };
  confirm = () => {
    this.modalController.dismiss({startDate: this.startDate, endDate: this.endDate }, 'confirm');
    //this.refresh.emit();
  };
  cancel = () => this.modalController.dismiss(null, 'cancel');
}
