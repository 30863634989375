import {Detail} from './Detail';
import {Tracciato} from './Tracciato';
import {Risorsa} from './Risorsa';
import {TracciatoDirections} from '../_helpers/TracciatoDirections';
import {TracciatoLinkModes} from '../_helpers/TracciatoLinkModes';
import {TracciatoTerminatoModes} from '../_helpers/TracciatoTerminatoModes';

export class RisorsaTracciato extends Detail {
  risorsaId: number;
  tracciatoId: number;
  tracciatoType: number;
  risorsaTracciatoId: number;
  pathUrl: string;
  skipRows: number;
  fileName: string;
  fileNameFormat?: string;
  fileExtension: string;
  separator: string;
  autoNewOrder: boolean;
  direction: TracciatoDirections;
  ordineLinkMode: TracciatoLinkModes;
  terminatoMode: TracciatoTerminatoModes;
  terminatoPlus: number;
  risorsa?: Risorsa;
  tracciato?: Tracciato;
}
