export enum TipoFormControl {
  inputText = 1,
  inputNumber= 2,
  datetime = 3,
  checkbox = 4,
  select = 5,
  selectRisorsa ,
  selectLavorazione,
  selectOrdine = 8
}
