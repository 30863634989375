import { Component, OnInit } from '@angular/core';
import {OrdineEsolver} from '../../_models/OrdineEsolver';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';
import {AuthenticationService} from '../../_services/authentication.service';
import {RisorseService} from '../../_services/risorse.service';
import {LocalService} from '../../_services/local.service';
import {EventiService} from '../../_services/eventi.service';
import {ModalController} from '@ionic/angular';
import {Ordine, OrdineCreate} from '../../_models/Ordine';
import {StatoRisorsa} from '../../_helpers/stato-risorsa';
import {FileZundComponent} from '../../ordini/file-zund/file-zund.component';
import {SaveMessage} from '../../_helpers/save-message';
import { constants } from '../../_helpers/constants';

@Component({
  selector: 'app-ordine-esolver-launch',
  templateUrl: './ordine-esolver-launch.component.html',
  styleUrls: ['./ordine-esolver-launch.component.scss'],
})
export class OrdineEsolverLaunchComponent implements OnInit {
  ordine: OrdineEsolver;
  newOrdine: OrdineCreate;
  loadFile = false;
  loading = false;
  constants = constants;
  constructor(
    public authService: AuthenticationService,
    private organizzazioniService: OrganizzazioniService,
    private risorseService: RisorseService,
    private localService: LocalService,
    private eventiService: EventiService,
    private modalCtrl: ModalController,
    private saveMessage: SaveMessage) { }

  ngOnInit() {
    this.newOrdine = {
      rifOrdine: this.ordine.rifOrdine,
      idDocumento: this.ordine.idDocumento,
      idRiga: this.ordine.idRiga,
      idRigaLavorazione: this.ordine.idRigaLavorazione,
      organizzazioneId: this.organizzazioniService.organizzazioneId,
      risorsaId: 0,
      lavorazioneId: 0,
      dataRegistrazione: this.ordine.dataRegistrazione,
      numRegistrazione: this.ordine.numRegistrazione,
      codiceArticolo: this.ordine.codiceArticolo,
      descArticolo: this.ordine.descArt,
      quantita: this.ordine.quantita,
      lotto: 1,
      quantitaLavorata: this.ordine.quantitaLavorata,
      quantitaScartata: this.ordine.quantitaScartata,
      tempoLav: this.ordine.tempo,
      omacState: '',
      stato: StatoRisorsa.PLANNED,
      flgTerminato: false,
      statoElimina: 0,
      dataTermine: this.ordine.dataTermine,
      codiceRisorsa: this.ordine.risorsa,
      codiceLavorazione: this.ordine.lavorazione,
      commessa: this.ordine.commessa,
      umTempo: this.ordine.umTempo,
      note: '',
      udM: this.ordine.udM,
      numFase: this.ordine.numFase,
      rifLottoProdotto: this.ordine.rifLottoAlfanum,
      rifOdP: this.ordine.rifOdP,
      lottoCode: this.organizzazioniService.autoLottoCode
    };
  }

  sendOrdine() {
    // da modificare
    if (this.risorseService.searchRisorsa.getValue().formato === 'ZUND') {
      this.openModal(this.newOrdine, this.risorseService.searchRisorsa.getValue())
        .then(() => {
          if (this.loadFile) {
            this.createOrdine(this.newOrdine);
          }
        });
    } else {
      this.createOrdine(this.newOrdine);
    }
  }

  createOrdine(ordine: OrdineCreate) {
    this.loading = true;
    this.localService.createOrdine(ordine)
      .then((ordineLocal: Ordine) => {
        this.saveMessage.messageInfo('Ordine lanciato con successo');
        this.eventiService.creaEvento(StatoRisorsa.PLANNED,ordineLocal,new Date())
          .then(() => this.modalCtrl.dismiss(ordineLocal, 'confirm'));
      })
      .catch(() => this.saveMessage.messageError('Problemi durante il lancio di ordine'))
      .finally(() => this.loading = false);
  }

  cancel = () => this.modalCtrl.dismiss(false, 'cancel');

  async openModal(element, _risorsa) {
    const modal = await this.modalCtrl.create({
      component: FileZundComponent,
      backdropDismiss: false,
      componentProps: { ordine: element, risorsa: _risorsa, }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.loadFile = true;
    } else if (!data) {
      this.loadFile = false;
    }
  }
}
