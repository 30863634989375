import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select-risorse-format',
  templateUrl: './select-risorse-format.component.html',
  styleUrls: ['./select-risorse-format.component.scss'],
})
export class SelectRisorseFormatComponent {
  @Input() formato = '';
  @Output() formatoChange = new EventEmitter<string>();
  formats: string[] = ['MANUALE', 'SYEK', 'MAZAK', 'STYLE', 'OPCUA', 'MODBUS', 'ZUND', 'GMM'];
  selectFormat = (e: any) => this.formatoChange.emit(e.target.value);
}
