import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {Movimento} from '../../../_models/Movimento';
import {OrganizzazioniService} from '../../../_services/organizzazioni.service';
import {SaveMessage} from '../../../_helpers/save-message';
import {MovimentiService} from '../../../_services/movimenti.service';

@Component({
  selector: 'app-movimento-add',
  templateUrl: './movimento-add.component.html',
  styleUrls: ['./movimento-add.component.scss'],
})
export class MovimentoAddComponent  implements OnInit {
  codArt = '';
  rifOdP: string;
  udM: string;
  lotto: string;
  qtaX1: number;
  idRiga: number;
  movimento: Movimento = {
    organizzazioneId: 1,
    id: 0,
    dataUnixRegistrazione: Math.floor(new Date().getTime() / 1000),
    codArt: '',
    unitaMisura: '',
    quantita: 0,
    coeffX1: 1,
    codLottoAlfa: '',
    idRigaMateriale: 0,
    tipoMovimento: -1
  };
  loading = false;
  constructor(
    private organizzazioniService: OrganizzazioniService,
    private movimentiService: MovimentiService,
    private modalCtrl: ModalController,
    private saveMessage: SaveMessage) {}

  ngOnInit() {
    this.movimento.organizzazioneId = this.organizzazioniService.organizzazioneId;
    this.movimento.codArt = this.codArt;
    this.movimento.rifOdP = this.rifOdP;
    this.movimento.unitaMisura = this.udM;
    this.movimento.codLottoAlfa = this.lotto;
    this.movimento.coeffX1 = this.qtaX1;
    this.movimento.idRigaMateriale = this.idRiga;
  }

  addMovimento = () => {
    this.loading = true;
    this.movimentiService.createMovimentoAsync(this.movimento)
      .then((_movimento: Movimento) => {
        this.saveMessage.messageInfo('Movimento creato con successo');
        this.modalCtrl.dismiss(_movimento, 'save');
      })
      .catch(() => this.saveMessage.messageInfo('Errore durante creazione movimento'))
      .finally(() => this.loading = false);
  };

  cancel = () => this.modalCtrl.dismiss(false, 'cancel');
}
