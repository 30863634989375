import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {OrdineEsolver, FilterItem, Ordine, SearchResult, Organizzazione} from '../../_models/_Index';
import { LocalService } from '../../_services/local.service';
import { OrganizzazioniService } from '../../_services/organizzazioni.service';
import { Subscription } from 'rxjs';
import {SaveMessage} from '../../_helpers/save-message';
import { TipoFormControl } from '../../_helpers/TipoFormControl';
import { STATO_ORDINE } from '../../_helpers/StatoOrdine';
import {OrdineEsolverGroup} from '../../_models/OrdineEsolverGroup';
import {OrdiniShowColumns} from '../../_models/OrdiniShowColumns';
import {InfiniteScrollCustomEvent} from '@ionic/angular';

@Component({
  selector: 'app-ordini-esolver',
  templateUrl: './ordini-esolver.component.html',
  styleUrls: ['./ordini-esolver.component.scss'],
})
export class OrdiniEsolverComponent implements OnInit, OnDestroy {
  @Input() isPersonal: boolean;
  viewGroup: boolean;
  ordiniEsolverApp: OrdineEsolver[] = [];
  ordiniEsolverGroup: OrdineEsolverGroup[] = [];
  totalCount = 0;
  subscription: Subscription;
  sortDirection = false;
  sortKey = 'dataTermine';
  loading = false;
  search = '';
  loadMore = false;
  page = 0;
  count = 20;
  showColumns: OrdiniShowColumns = {};
  filters: FilterItem<any>[] = [
    { fieldName:'NumRegistrazione', operazione: 1, value1:'',value2:'', tipoFormControl: TipoFormControl.inputText, showColumn: this.localService.showColumns.value.numRegistrazione },
    { fieldName:'Commessa', operazione: 5, value1:'',value2:'', tipoFormControl: TipoFormControl.inputText, showColumn: this.localService.showColumns.value.commessa },
    { fieldName:'RifOrdine', operazione: 1, value1:'',value2:'', tipoFormControl: TipoFormControl.inputText, showColumn: this.localService.showColumns.value.rifOrdine },
    { fieldName:'CodiceOrdine', operazione: 1, value1:'',value2:'', tipoFormControl: TipoFormControl.selectOrdine, showColumn: this.localService.showColumns.value.idDocumento },
    { fieldName:'RifOdP', operazione: 5, value1:'',value2:'', tipoFormControl: TipoFormControl.inputText, showColumn: this.localService.showColumns.value.rifOdP },
    { fieldName:'NumFase', operazione: 1, value1:'',value2:'', tipoFormControl: TipoFormControl.inputNumber, showColumn: this.localService.showColumns.value.numFase },
    { fieldName:'DataRegistrazione', operazione: 8,
      value1:new Date(2000, 0, 1, 0, 0, 0).toISOString(),
      value2: new Date(2099, 11, 31, 1, 0, 0).toISOString(), tipoFormControl: TipoFormControl.datetime, showColumn: this.localService.showColumns.value.dataRegistrazione },
    { fieldName:'DataTermine', operazione: 8,
      value1:new Date(2000, 0, 1, 0, 0, 0).toISOString(),
      value2: new Date(2099, 11, 31, 1, 0, 0).toISOString(), tipoFormControl: TipoFormControl.datetime, showColumn: this.localService.showColumns.value.dataTermine },
    { fieldName:'Risorsa', operazione: 5, value1:'',value2:'', tipoFormControl: TipoFormControl.inputText, showColumn: this.localService.showColumns.value.risorsa },
    { fieldName:'Lavorazione', operazione: 5, value1:'',value2:'', tipoFormControl: TipoFormControl.inputText, showColumn: this.localService.showColumns.value.lavorazione },
    { fieldName:'CodiceArticolo', operazione: 5, value1:'',value2:'', tipoFormControl: TipoFormControl.inputText, showColumn: this.localService.showColumns.value.codiceArticolo },
    { fieldName:'UdM', operazione: 5, value1:'',value2:'', tipoFormControl: TipoFormControl.inputText, showColumn: this.localService.showColumns.value.udM },
    { fieldName:'QtaOrdine', operazione: 3, value1:'-99999',value2:'', tipoFormControl: TipoFormControl.inputNumber, showColumn: this.localService.showColumns.value.quantita },
    { fieldName:'Quantita', operazione: 3, value1:'-99999',value2:'', tipoFormControl: TipoFormControl.inputNumber, showColumn: this.localService.showColumns.value.quantitaToDo },
    { fieldName:'Stato', operazione: 1, value1:'1',value2:'', tipoFormControl: TipoFormControl.select, options: STATO_ORDINE, showColumn: this.localService.showColumns.value.stato }
  ];

  constructor(
    private organizzazioniService: OrganizzazioniService,
    private saveMessage: SaveMessage,
    private localService: LocalService) { }

  ngOnInit() {
    this.subscription = this.localService.searchText.subscribe((_value: string) => {
      this.search = _value;
      this.refresh(false);
    });
    this.subscription.add(this.localService.lastOrdineCreated.subscribe((_newOrdine: Ordine) => {
      if (_newOrdine != null) {
        this.excludeOrdine(_newOrdine);
      }
    }));
    this.viewGroup = this.organizzazioniService.viewGroup;
    this.subscription.add(this.organizzazioniService.currentOrganizzazioneSubject
      .subscribe((_organizzazione: Organizzazione) => {
        if (_organizzazione && _organizzazione.accountSettings) {
          this.viewGroup = _organizzazione.accountSettings.activeViewGroup;
        }
      }));
    this.showColumns = this.localService.showColumns.value;
    this.subscription.add(this.localService.showColumns.subscribe((_showColumns: OrdiniShowColumns) => this.showColumns = _showColumns));
  }

  infiniteScrollEventi(event) {
    this.refresh(true)
      .then(() => (event as InfiniteScrollCustomEvent).target.complete());
  }

  refresh = (add: boolean) => {
    this.loadMore = true;
    if (!add) {
      this.page = 0;
      this.ordiniEsolverApp = [];
      this.ordiniEsolverGroup = [];
    } else {
      this.page += 1;
    }
    this.loading = true;
    if (!this.viewGroup) {
      return this.getOrdiniFromEsolver(add);
    } else {
      return this.getOrdiniGroupFromEsolver(add);
    }
  };

  getOrdiniFromEsolver(add: boolean) {
    return this.localService.getOrdiniEsolver(this.organizzazioniService.organizzazioneId, this.organizzazioniService.gruppoCodice,
      this.organizzazioniService.organizzazioneCodice, this.search,this.page, this.count,
      this.sortKey, this.sortDirection, true, this.isPersonal, this.filters, false)
      .then((_result: SearchResult<OrdineEsolver>) => {
        this.totalCount = _result.totalCount;
        if (_result.totalCount === 0) {
          this.ordiniEsolverApp = [];
          this.loadMore = false;
          return false;
        }
        if (!add) {
          this.ordiniEsolverApp = _result.result;
        } else {
          _result.result.forEach((ord: OrdineEsolver) => this.ordiniEsolverApp.push(ord));
        }
        this.loadMore = _result.totalCount > this.ordiniEsolverApp.length;
      })
      .catch((_err) => this.saveMessage.messageError('Problemi con il caricamento degli ordini pianificati in Esolver'))
      .finally(() => this.loading = false);
  }

  getOrdiniGroupFromEsolver(add: boolean) {
    const groupColumns = this.organizzazioniService.groupColumnsArray;
    return  this.localService.getOrdiniGroupEsolver(this.organizzazioniService.organizzazioneId, this.organizzazioniService.gruppoCodice,
      this.organizzazioniService.organizzazioneCodice, this.search,this.page, this.count,
      this.sortKey, this.sortDirection, true, this.isPersonal, this.filters, groupColumns)
      .then((_result: SearchResult<OrdineEsolverGroup>) => {
        this.totalCount = _result.totalCount;
        if (_result.totalCount === 0) {
          this.ordiniEsolverGroup = [];
          this.loadMore = false;
          return false;
        }
        if (!add) {
          this.ordiniEsolverGroup = _result.result;
        } else {
          _result.result.forEach((ord: OrdineEsolverGroup) => this.ordiniEsolverGroup.push(ord));
        }
        this.loadMore = _result.totalCount > this.ordiniEsolverGroup.length;
      })
      .catch((_err) => this.saveMessage.messageError('Problemi con Esolver API: controllare Url nella configurazione'))
      .finally(() => this.loading = false);
  }

  sort(key: string) {
    if (this.sortKey === key) {
      this.sortDirection = !this.sortDirection;
    }
    this.sortKey = key;
    this.refresh(false);
  }

  excludeOrdine = (ordine: Ordine) => {
    this.ordiniEsolverApp = this.ordiniEsolverApp.filter((_ordine: OrdineEsolver) =>
      !(ordine.idDocumento === _ordine.idDocumento &&
        ordine.idRiga === _ordine.idRiga &&
        ordine.idRigaLavorazione === _ordine.idRigaLavorazione));
  };

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
