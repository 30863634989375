import { BaseModel } from './_Index';

export class Operatore extends BaseModel{
    organizzazioneId?: number;
    title: string;
    firstName: string;
    lastName: string;
    userName: string;
    email: string;
    acceptTerms: boolean;
    jwtToken?: string;
    created: Date;
    update: Date;
    isVerified?: boolean;

    roles: string[];

    organizzazioneCodice: string;
    gruppoCodice: string;
    refreshToken?: string;
}
