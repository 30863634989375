import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { OrganizzazioniService} from '../../../_services/organizzazioni.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { SearchResult, Organizzazione} from '../../../_models/_Index';
import {SaveMessage} from '../../../_helpers/save-message';

@Component({
  selector: 'app-select-organizzazione',
  templateUrl: './select-organizzazione.component.html',
  styleUrls: ['./select-organizzazione.component.scss'],
})
export class SelectOrganizzazioneComponent implements OnInit {
  @Input() organizzazioneId: number;
  @Output() organizzazioneIdChange = new EventEmitter<number>();
  organizzazioni: Organizzazione[] = [];
  loading = false;
  constructor(
    private organizzazioniService: OrganizzazioniService,
    private saveMessage: SaveMessage,
    private authService: AuthenticationService) { }

  ngOnInit() {
    this.getOrganizzazioni();
  }

  getOrganizzazioni = () => {
    this.loading = true;
    this.organizzazioniService
      .getOrganizzazioniOfOperatoreAsync(this.authService.currentUserValue.id)
      .then((_organizzazioni: SearchResult<Organizzazione>) => this.organizzazioni = _organizzazioni.result
        .sort((a, b) => a.codice.localeCompare(b.codice)))
      .catch(() => this.saveMessage.messageError('Ci sono problemi con il caricamento delle organizzazioni.'))
      .finally(() => this.loading = false);
  };

  selectOrganizzazione = (e: any) => this.organizzazioneIdChange.emit(e.target.value);
}
