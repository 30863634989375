// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-success {
  background-color: #2fdf75;
}

.bg-error {
  background-color: #e04055;
}

.bg-warning {
  background-color: orange;
}

@media screen and (prefers-color-scheme: dark) {
  .bg-success {
    background-color: #2fdf75;
  }
  .bg-error {
    background-color: #e04055;
  }
  .bg-warning {
    background-color: orange;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/_components/centro-notifiche/centro-notifiche.component.scss"],"names":[],"mappings":"AAAA;EAAc,yBAAA;AAEd;;AADA;EAAW,yBAAA;AAKX;;AAJA;EAAa,wBAAA;AAQb;;AAPA;EACE;IAAc,yBAAA;EAWd;EAVA;IAAW,yBAAA;EAaX;EAZA;IAAa,wBAAA;EAeb;AACF","sourcesContent":[".bg-success { background-color:#2fdf75; }\n.bg-error {background-color: #e04055; }\n.bg-warning {background-color: orange; }\n@media screen and (prefers-color-scheme: dark) {\n  .bg-success { background-color:#2fdf75; }\n  .bg-error {background-color: #e04055; }\n  .bg-warning {background-color: orange;}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
