export const constants = {
  ROLE_ADMIN: 'ADMIN',
  ROLE_MANAGER: 'MANAGER',
  ROLE_OPER: 'OPER',
  ROLE_MES: 'MES',
  MOD_PRO_BASE: 'PRO-BASE',
  MOD_PRO_BASE_UI: 'PRO-BASE-UI',
  MOD_PRO_BASE_MAN: 'PRO-BASE-MAN',
  MOD_PRO_BASE_GROUP: 'PRO-BASE-GROUP',
  MOD_PRO_BASE_COLUMN: 'PRO-BASE-COLUMN',
  MOD_PRO_BASE_STA: 'PRO-BASE-STA',
  MOD_PRO_BASE_AVM_PLAN: 'PRO-BASE-AVM-PLAN',
  MOD_PRO_BASE_LOTTI: 'PRO-BASE-LOTTI',
  MOD_PRO_BASE_BARCODE: 'PRO-BASE-BARCODE',
  MOD_PRO_BASE_TRANSFER: 'PRO-BASE-TRANSFER'
};
