import { Component, OnInit, Input } from '@angular/core';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';
import {Organizzazione} from '../../_models/Organizzazione';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss'],
})
export class UserHeaderComponent  implements OnInit {
  @Input() iconName: string;
  @Input() pageTitle: string;
  @Input() count = 0;
  dataOggi = new Date();
  organizzazioneCode = '';
  subscriptions: Subscription;
  constructor(
    private organizzazioniService: OrganizzazioniService,
  ) { }

  ngOnInit() {
    if (this.organizzazioniService.currentOrganizzazioneValue != null) {
      this.organizzazioneCode = this.organizzazioniService.currentOrganizzazioneValue.codice;
    }
    this.subscriptions = this.organizzazioniService.currentOrganizzazione
      .subscribe((_org: Organizzazione)=> this.organizzazioneCode = _org ? _org.codice : '');
    setInterval(() => this.dataOggi = new Date(), 10);
  }

}
