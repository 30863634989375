import { Component, EventEmitter, Input, Output } from '@angular/core';
import {TRACCIATO_FIELD_KEY_TYPES, TracciatoFieldKeyTypes} from '../../../_helpers/TracciatoFieldKeyTypes';

@Component({
  selector: 'app-select-tracciati-field-type-key',
  templateUrl: './select-tracciati-field-type-key.component.html',
  styleUrls: ['./select-tracciati-field-type-key.component.scss'],
})
export class SelectTracciatiFieldTypeKeyComponent {
  @Input() fieldKeyType: TracciatoFieldKeyTypes;
  @Output() fieldKeyTypeChange = new EventEmitter<TracciatoFieldKeyTypes>();
  tracciatoFieldKeyTypesItem = TRACCIATO_FIELD_KEY_TYPES;
  selectFieldKeyType = (e: any) => this.fieldKeyTypeChange.emit(e.target.value);
}
