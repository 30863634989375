import { Component, OnInit } from '@angular/core';
import {GroupColumn} from '../../_models/OrdiniGroupColumns';
import {ModalController} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {Organizzazione} from '../../_models/Organizzazione';
import {AccountOrganizzazione} from '../../_models/AccountOrganizzazione';
import {SaveMessage} from '../../_helpers/save-message';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';

@Component({
  selector: 'app-ordini-group-columns',
  templateUrl: './ordini-group-columns.component.html',
  styleUrls: ['./ordini-group-columns.component.scss'],
})
export class OrdiniGroupColumnsComponent implements OnInit {
  subscription: Subscription;
  organizzazione: Organizzazione;
  accountOrganizzazione: AccountOrganizzazione;
  loading = false;
  groupColumns: GroupColumn[];
  groupType = 0;
  constructor(
    private saveMessage: SaveMessage,
    private organizzazioneService: OrganizzazioniService,
    private modalCtrl: ModalController) { }

  ngOnInit() {
    this.groupColumns = this.organizzazioneService.groupColumns;
    this.organizzazione = this.organizzazioneService.currentOrganizzazioneSubject.value;
    if (this.organizzazione && this.organizzazione.accountSettings) {
      this.accountOrganizzazione = this.organizzazione.accountSettings;
    }
    this.subscription = this.organizzazioneService.currentOrganizzazioneSubject
      .subscribe((_organizzazione: Organizzazione) => {
        if (_organizzazione && _organizzazione.accountSettings) {
          this.organizzazione = _organizzazione;
          this.accountOrganizzazione = _organizzazione.accountSettings;
        }
      });
  }

  cancel = () => this.modalCtrl.dismiss(false, 'cancel');

  succesSave = () => {
    //this.localService.setGroupColumns(this.groupColumns);
    //this.modalCtrl.dismiss(true, 'confirm');
    this.loading = true;
    this.accountOrganizzazione.groupColumns = this.getOrdiniGroupColumnsArray();
    this.organizzazioneService.saveAccountOrganizzazioneAsync(this.accountOrganizzazione)
      .then((_accountOrganizzazione: AccountOrganizzazione) => {
        this.organizzazione.accountSettings = _accountOrganizzazione;
        this.organizzazioneService.setCurrentOrganizzazioneValue(this.organizzazione);
        this.modalCtrl.dismiss(true, 'confirm');
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con salvataggio'))
      .finally(() => this.loading = false);
  };

  getOrdiniGroupColumnsArray = () => {
    const columnsFlag = this.groupColumns;
    const columns = [];
    columnsFlag
      .sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
      .forEach((column: GroupColumn) => {
        if (column.active) {
          columns.push(column.columnName);
        }
      });
    return columns.join(',');
  };
}
