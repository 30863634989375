import { Component, EventEmitter, Input, Output } from '@angular/core';
import {TRACCIATO_LINK_MODES, TracciatoLinkModes} from '../../../_helpers/TracciatoLinkModes';

@Component({
  selector: 'app-select-tracciati-link-mode',
  templateUrl: './select-tracciati-link-mode.component.html',
  styleUrls: ['./select-tracciati-link-mode.component.scss'],
})
export class SelectTracciatiLinkModeComponent {
  @Input() linkMode: TracciatoLinkModes;
  @Output() linkModeChange = new EventEmitter<TracciatoLinkModes>();
  tracciatoLinkModesItem = TRACCIATO_LINK_MODES;
  selectLinkMode = (e: any) => this.linkModeChange.emit(e.target.value);
}
