import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { LocalService } from '../../_services/local.service';
import {Subscription} from 'rxjs';
import {EventiService} from '../../_services/eventi.service';
import {MovimentiService} from '../../_services/movimenti.service';

@Component({
  selector: 'app-hub-state-led',
  templateUrl: './hub-state-led.component.html',
  styleUrls: ['./hub-state-led.component.scss'],
})
export class HubStateLedComponent implements OnInit, OnDestroy {
  @ViewChild('popover') popover;
  hubState = 1;
  hubEventiState = 1;
  hubMovimentiState = 1;
  isOpen = false;
  subscription: Subscription;
  constructor(
    private localService: LocalService,
    private eventiService: EventiService,
    private movimentiService: MovimentiService) { }

  ngOnInit() {
    this.hubState = this.localService.hubState.value;
    this.subscription = this.localService.hubState.subscribe((_hubState: number) => {
        this.hubState = _hubState;
        if (_hubState === 0) {
          this.isOpen = true;
        }
      });
    this.subscription.add(this.eventiService.hubState.subscribe((_hubState: number) => {
      this.hubEventiState = _hubState;
    }));
    this.subscription.add(this.movimentiService.hubState.subscribe((_hubState: number) => {
      this.hubMovimentiState = _hubState;
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }
}
