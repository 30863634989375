import {ColumnGroupTypes} from '../_helpers/ColumnGroupTypes';
import {TipoAvvio} from '../_helpers/TipoAvvio';

export class GroupColumns {
  groupType: ColumnGroupTypes;
  avvioType: TipoAvvio;
  columns: GroupColumn[];
}

export class GroupColumn {
  order: number;
  columnName: string;
  viewName: string;
  active: boolean;
}
