import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-select-type-installazione',
  templateUrl: './select-type-installazione.component.html',
  styleUrls: ['./select-type-installazione.component.scss'],
})
export class SelectTypeInstallazioneComponent {
  @Input() typeInstallazione: number;
  @Output() typeInstallazioneChange = new EventEmitter<number>();
  public installazioneTypes =
    [
      { value: 1, text: 'Locale' },
      { value: 2, text: 'Cloud' },
      { value: 3, text: 'Standalone' }
    ];
  selectTypeInstallazione = (e: any) => this.typeInstallazioneChange.emit(+e.target.value);
}
