import {Component, Input, OnInit} from '@angular/core';
import {OrdineEsolverGroup} from '../../_models/OrdineEsolverGroup';
import {ModalController} from '@ionic/angular';
import {OrdineEsolverGroupLaunchComponent} from '../ordine-esolver-group-launch/ordine-esolver-group-launch.component';
import {OrdiniShowColumns} from '../../_models/OrdiniShowColumns';
import { LocalService } from '../../_services/local.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-ordine-esolver-group-item',
  templateUrl: './ordine-esolver-group-item.component.html',
  styleUrls: ['./ordine-esolver-group-item.component.scss'],
})
export class OrdineEsolverGroupItemComponent  implements OnInit {
  @Input() ordineEsolverGroup: OrdineEsolverGroup;
  isVisibleOrdini = false;
  loading = false;
  today = new Date().toISOString();
  showColumns: OrdiniShowColumns;
  subscription: Subscription;
  constructor(
    private localService: LocalService,
    private modalCtrl: ModalController) { }

  ngOnInit() {
    this.subscription = this.localService.showColumns.subscribe((_showColumns: OrdiniShowColumns) => this.showColumns = _showColumns);
  }

  showOrdini = () => this.isVisibleOrdini = !this.isVisibleOrdini;

  async openModal(element: OrdineEsolverGroup) {
    const modal = await this.modalCtrl.create({
      component: OrdineEsolverGroupLaunchComponent,
      backdropDismiss: false,
      componentProps: { ordineEsolverGroup: element }
    });
    await modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'launch') {
      //this.localService.handleRefresh.next();
    }
  }
}
