import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Lavorazione, LavorazioneEsolver, SearchResult} from '../_models/_Index';
import { environment } from '../../environments/environment';
import {BehaviorSubject} from 'rxjs';

const cachePrefix = 'archiconnect-v2-';
const emptyLavorazione: Lavorazione = {
  organizzazioneId: 0,
  id: 0,
  codice: '',
  descrizione: '',
  isActive: false
};

@Injectable({ providedIn: 'root' })
export class LavorazioniService {

  public searchLavorazione: BehaviorSubject<Lavorazione> = new BehaviorSubject(emptyLavorazione);
  public myLavorazioni: BehaviorSubject<Lavorazione[]> = new BehaviorSubject([]);

  constructor(private http: HttpClient) {
    const lavorazioneJson = localStorage.getItem(cachePrefix+'lavorazione');
    if (lavorazioneJson !== undefined && lavorazioneJson !== 'undefined') {
      const lavorazione = JSON.parse(localStorage.getItem(cachePrefix+'lavorazione')) || emptyLavorazione;
      this.searchLavorazione.next(lavorazione);
    }
  }

  setLavorazione(lavorazione: Lavorazione) {
    if (lavorazione !== undefined) {
      this.searchLavorazione.next(lavorazione);
      localStorage.setItem(cachePrefix+'lavorazione', JSON.stringify(lavorazione));
    }
  }

  resetLavorazione() {
    this.searchLavorazione.next(emptyLavorazione);
    localStorage.setItem(cachePrefix+'lavorazione', JSON.stringify(emptyLavorazione));
  }

  async getLavorazioniFilteredAsync(orgId: number, search: string, page: number, count: number, sortField: string, sortAsc: boolean) {
    return await this.http.post<SearchResult<Lavorazione>>(`${environment.localApiUrl}Lavorazioni/LavorazioniFiltered?orgId=${orgId}`,
      { search, page, count, sortField, sortAsc })
      .toPromise();
  }

  async getLavorazioneAsync(orgId: number, code: string) {
    // eslint-disable-next-line max-len
    return await this.http.get<Lavorazione>(`${environment.localApiUrl}Lavorazioni/Lavorazione?orgId=${orgId}&code=${code}`)
      .toPromise();
  }

  async createLavorazioneAsync(lavorazione: Lavorazione) {
    return await this.http.post<Lavorazione>(`${environment.localApiUrl}Lavorazioni/CreateLavorazione`, lavorazione)
      .toPromise();
  }

  async updateLavorazioneAsync(lavorazione: Lavorazione) {
    return await this.http.post<Lavorazione>(`${environment.localApiUrl}Lavorazioni/UpdateLavorazione`, lavorazione)
      .toPromise();
  }

  async deleteLavorazioneAsync(orgId: number, code: string) {
    // eslint-disable-next-line max-len
    return await this.http.get<Lavorazione>(`${environment.localApiUrl}Lavorazioni/DeleteLavorazioni?orgId=${orgId}&code=${code}`)
      .toPromise();
  }

  async getLavorazioniOfAccountAsync(orgId: number, accountId: number) {
    return await this.http.get<SearchResult<Lavorazione>>(`${environment.localApiUrl}Lavorazioni/LavorazioniByAccountId?` +
      `orgId=${orgId}&accountId=${accountId}`)
      .toPromise();
  }

  setMyLavorazioni(orgId: number) {
    this.http.get<SearchResult<Lavorazione>>(`${environment.localApiUrl}Lavorazioni/MyLavorazioni?orgId=${orgId}`)
      .toPromise()
      .then((_lavorazioni: SearchResult<Lavorazione>) => this.myLavorazioni.next(_lavorazioni.result));
  }

  async addLavorazioneToAccountAsync(accountId: number, orgId: number, lavorazioneId: number) {
    return await this.http
      .get<Lavorazione>(`${environment.localApiUrl}Lavorazioni/AddLavorazioneToAccount?accountId=${accountId}&orgId=${orgId}&lavorazioneId=${lavorazioneId}`)
      .toPromise();
  }

  async removeLavorazioneFromAccountAsync(accountId: number, orgId: number, lavorazioneId: number) {
    return await this.http
      .get<Lavorazione>(`${environment.localApiUrl}Lavorazioni/RemoveLavorazioneFromAccount?` +
        `accountId=${accountId}&orgId=${orgId}&lavorazioneId=${lavorazioneId}`)
      .toPromise();
  }

  getLavorazioniEsolver(orgId: number) {
    return this.http.get<SearchResult<LavorazioneEsolver>>(`${environment.localApiUrl}Esolver/Lavorazioni/${orgId}`)
      .toPromise();
  }
}
