import { Detail } from "./_Index";

export class OperatoreEsolver extends Detail{
    codOperatore: string
    nomeOperatore: string
    password: string
    note:string
}

export class OperatoreEsolverResult {
    operatori: OperatoreEsolver[]
}