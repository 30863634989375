import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {GroupResult} from '../../_models/GroupResult';
import {Ordine} from '../../_models/Ordine';
import {Subscription} from 'rxjs';
import {Organizzazione} from '../../_models/Organizzazione';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';

@Component({
  selector: 'app-ordine-local-groupbymany-item',
  templateUrl: './ordine-local-groupbymany-item.component.html',
  styleUrls: ['./ordine-local-groupbymany-item.component.scss'],
})
export class OrdineLocalGroupbymanyItemComponent implements OnDestroy {
  @Input()ordineGroupByMany: GroupResult<Ordine>;
  @Input()level: number;
  @Output() ordiniAddSended = new EventEmitter<Ordine[]>();
  isVisibleOrdini = false;
  today = new Date().toString();
  subscription: Subscription;
  groupColumns = [];

  constructor(
    private organizzazioniService: OrganizzazioniService) {
    this.groupColumns = this.organizzazioniService.groupColumnsArray;
    this.subscription = this.organizzazioniService.currentOrganizzazione
      .subscribe((_org: Organizzazione)=> {
        this.groupColumns = this.organizzazioniService.groupColumnsArray;
      });
  }

  showOrdini = () => this.isVisibleOrdini = !this.isVisibleOrdini;
  addOrdini = (ordini: Ordine[]) => this.ordiniAddSended.emit(ordini);

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
