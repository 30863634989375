import {Detail} from './Detail';
import {EventiCounter} from './EventiCounter';
import {RisorseCounter} from './RisorseCounter';

export class OrdiniStat extends Detail {
  ordiniInAvanzamento: number;
  ordiniInAvanzamentoWeek: number;
  ordiniInPausa: number;
  ordiniInPausaWeek: number;
  ordiniPianificati: number;
  ordiniPianificatiWeek: number;
  ordiniTerminati: number;
  ordiniTerminatiRisorseCount: number;
  ordiniTerminatiWeek: number;
  workStartTime: number;
  lastActionTime: number;
  workingTime: number;
  workingTimeOfToday: number;
  partialWorkingHours: number;
  countWorkingSessions: number;
  eventi: EventiCounter[];
  risorseOfToday: RisorseCounter[];
  eventiLastMonth: EventiCounter[];
}
