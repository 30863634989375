import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {EventiCounter} from '../../../_models/EventiCounter';
import {Chart} from 'chart.js/auto';

@Component({
  selector: 'app-eventi-chart',
  templateUrl: './eventi-chart.component.html',
  styleUrls: ['./eventi-chart.component.scss'],
})
export class EventiChartComponent  implements OnInit,OnDestroy {
  @Input() eventi = [];
  @Input() code = 'code';
  chartEventi: any;
  chartSessioni: any;
  chartQta: any;
  constructor() { }

  ngOnInit() {
    const labelEvents = [];
    const eventTimes = [];
    const eventSessions = [];
    const eventQtas = [];
    if (this.eventi && this.eventi.length > 0) {
      this.eventi.forEach((_evento: EventiCounter) => {
        labelEvents.push(new Date(_evento.day).toLocaleDateString());
        eventTimes.push(_evento.workingTimeOfToday / 3600);
        eventSessions.push(_evento.countWorkingSessions);
        eventQtas.push(_evento.quantitaLavorata);
      });
      this.chartEventi = Chart.getChart('LineChartEventi');
      if (this.chartEventi !== undefined) {
        this.chartEventi.destroy();
      }
      this.chartSessioni = Chart.getChart('LineChartSessioni');
      if (this.chartSessioni !== undefined) {
        this.chartSessioni.destroy();
      }
      this.chartQta = Chart.getChart('LineChartQta');
      if (this.chartQta !== undefined) {
        this.chartQta.destroy();
      }
      this.chartEventi = new Chart('LineChartEventi', {
        type: 'line',
        data: { labels: labelEvents, datasets: [
            { label: 'Ore lavorate', data: eventTimes }
          ]},
        options: { aspectRatio:2 }
      });
      this.chartEventi.update();
      this.chartSessioni = new Chart('LineChartSessioni', {
        type: 'line',
        data: { labels: labelEvents, datasets: [
            {label: 'Sessioni', data: eventSessions}
          ]},
        options: {
          responsive: true,
          aspectRatio:2
        }
      });
      this.chartSessioni.update();
      this.chartQta = new Chart('LineChartQta', {
        type: 'line',
        data: { labels: labelEvents, datasets: [
            { label: 'Qta lavorate', data: eventQtas }
          ]},
        options: { aspectRatio:2 }
      });
      this.chartQta.update();
    }
  }

  ngOnDestroy() {
    if (this.chartEventi !== undefined) {
      this.chartEventi.destroy();
      this.chartEventi = null;
    }
    if (this.chartSessioni !== undefined) {
      this.chartSessioni.destroy();
      this.chartSessioni = null;
    }
  }

}
