import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Ordine } from '../../_models/Ordine';
import { Risorsa } from '../../_models/Risorsa';
import { OrganizzazioniService} from '../../_services/organizzazioni.service';
import { LocalService } from '../../_services/local.service';

@Component({
  selector: 'app-file-zund',
  templateUrl: './file-zund.component.html',
  styleUrls: ['./file-zund.component.scss'],
})
export class FileZundComponent implements OnInit {

  ordine: Ordine;
  risorsa: Risorsa;

  constructor(
    public localApi: LocalService,
    private organizzazioniService: OrganizzazioniService,
    private modalCtrl: ModalController) { }

  ngOnInit() { }

  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }
    this.localApi.updloadFileOrdineAsync(this.ordine.idDocumento, this.ordine.idRiga,
      this.ordine.idRigaLavorazione, this.organizzazioniService.organizzazioneId, files)
      .then(() => this.modalCtrl.dismiss(true))
      .catch(() => this.modalCtrl.dismiss(false));
  };
}
