import { Component, Input, Output, EventEmitter } from '@angular/core';

interface SelectItem {
  value: number;
  text: string;
}

@Component({
  selector: 'app-select-stati-ordini',
  templateUrl: './select-stati-ordini.component.html',
  styleUrls: ['./select-stati-ordini.component.scss'],
})
export class SelectStatiOrdiniComponent {

  @Input() stato = 0;

  @Output() statoChange = new EventEmitter<number>();

  public statis: SelectItem[] = [
    { value: 0, text: 'Da pianificare' },
    { value: 1, text: 'Pianificato' },
    { value: 2, text: 'Lanciato' },
    { value: 3, text: 'In Avanzamento' },
    { value: 6, text: 'Pausa' },
    { value: 4, text: 'Terminato' },
    { value: 5, text: 'Sospeso' },
    { value: 97, text: 'Invio Erp in corso' },
    { value: 98, text: 'Invio Erp Fallito' },
    { value: 99, text: 'Invio Erp Ok' }
  ];

  constructor() { }

  selectStato(e: any) {
    this.statoChange.emit(+e.target.value);
  }

  cancelStato() {
    //this.stato = -1;
    this.statoChange.emit(-1);
  }
}
