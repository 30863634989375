import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Operatore } from './_models/Operatore';
import { AuthenticationService } from './_services/authentication.service';
import { OperatoriService } from './_services/operatori.service';
import { OrganizzazioniService } from './_services/organizzazioni.service';
import { LocalService } from './_services/local.service';
import { EventiService } from './_services/eventi.service';
import { RisorseService } from './_services/risorse.service';
import { constants } from './_helpers/constants';
import {Organizzazione} from './_models/Organizzazione';
import {OrganizzazioniCreateComponent} from './organizzazioni/organizzazioni-create/organizzazioni-create.component';
import { OrdiniGroupColumnsComponent } from './_components/ordini-group-columns/ordini-group-columns.component';
import { OrdiniShowColumnsComponent} from './_components/ordini-show-columns/ordini-show-columns.component';
import {FiltroOrdineComponent} from './_components/filtro-ordine/filtro-ordine.component';
import {LavorazioniService} from './_services/lavorazioni.service';
import {TipoEntities} from './_helpers/TipoEntities';
import {MovimentiService} from './_services/movimenti.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  firstName = '';
  gruppoCodice = '';
  organizzazioneId = 1;
  organizzazioneCodice = '';
  subscription: Subscription;
  isStandalone = false;
  constants = constants;
  menuVisible = false;
  themeToggle = false;
  tipoEntities = TipoEntities;
  constructor(
    public authService: AuthenticationService,
    private operatoriService: OperatoriService,
    private modalCtrl: ModalController,
    private localService: LocalService,
    private eventiService: EventiService,
    private risorseService: RisorseService,
    private lavorazioniService: LavorazioniService,
    private organizzazioniService: OrganizzazioniService,
    private movimentiService: MovimentiService) {}
    ngOnInit() {
      this.authService.updateRefreshToken();
      this.localService.startConnection();
      this.eventiService.startConnection();
      this.movimentiService.startConnection();
      if (this.organizzazioniService.currentOrganizzazioneValue != null) {
        this.organizzazioneId = this.organizzazioniService.organizzazioneId;
        this.gruppoCodice = this.organizzazioniService.gruppoCodice;
        this.organizzazioneCodice = this.organizzazioniService.organizzazioneCodice;
        if (this.authService.currentUserValue && this.authService.currentUserValue.id) {
          this.risorseService.setMyRisorse(this.organizzazioniService.organizzazioneId);
          this.lavorazioniService.setMyLavorazioni(this.organizzazioniService.organizzazioneId);
        }
      }
      if (this.authService.currentUserValue != null) {
        this.firstName = this.authService.currentUserValue.email;
      }
      this.subscription = this.authService.currentUser.subscribe((_user: Operatore) => {
        if (_user != null) {
          this.firstName = _user.email;
        } else {
          this.firstName = '';
        }
      });
      this.subscription.add(this.organizzazioniService.showMenu.subscribe((value: boolean) => this.menuVisible = value));
      this.subscription.add(this.organizzazioniService.currentOrganizzazione
        .subscribe(()=> this.isStandalone = this.organizzazioniService.isStandalone()));
      // Use matchMedia to check the user preference
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

      // Initialize the dark theme based on the initial
      // value of the prefers-color-scheme media query
      this.initializeDarkTheme(prefersDark.matches);
      // Listen for changes to the prefers-color-scheme media query
      prefersDark.addEventListener('change', (mediaQuery) => this.initializeDarkTheme(mediaQuery.matches));
    }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }

  // Check/uncheck the toggle and update the theme based on isDark
  initializeDarkTheme(isDark) {
    this.themeToggle = isDark;
    this.toggleDarkTheme(isDark);
  }

  // Listen for the toggle check/uncheck to toggle the dark theme
  toggleChange(ev) {
    this.toggleDarkTheme(ev.detail.checked);
  }


  // Add or remove the "dark" class on the document body
  toggleDarkTheme(shouldAdd) {
    document.body.classList.toggle('dark', shouldAdd);
  }

  setOrganizzazione = (orgId: number) => {
    this.organizzazioneId = orgId;
    this.operatoriService.setOrganizzazioneAsync(orgId)
      .then((result: number) => this.setActiveOrganizzazione(orgId));
  };

  setActiveOrganizzazione = (orgId: number) => {
    this.organizzazioniService.getMyOrganizzazioneAsync(orgId)
      .then((_organizzazione: Organizzazione) => {
        this.organizzazioniService.setCurrentOrganizzazioneValue(_organizzazione);
        this.organizzazioneId = this.organizzazioniService.organizzazioneId;
        this.gruppoCodice = this.organizzazioniService.gruppoCodice;
        this.organizzazioneCodice = this.organizzazioniService.organizzazioneCodice;
        this.localService.resetOrdineGroup(false, false);
        this.risorseService.resetRisorsa();
        this.risorseService.setMyRisorse(this.organizzazioniService.organizzazioneId);
        this.lavorazioniService.resetLavorazione();
        this.lavorazioniService.setMyLavorazioni(this.organizzazioniService.organizzazioneId);
        this.localService.searchText.next('');

        this.authService.updateRefreshToken();
      });
  };

  async openModal() {
    const modal = await this.modalCtrl.create({
      component: OrganizzazioniCreateComponent,
      backdropDismiss: false
    });
    await modal.present();
  }

  async openOrdiniGroupColumnsModal() {
    const modal = await this.modalCtrl.create({
      component: OrdiniGroupColumnsComponent,
      backdropDismiss: false
    });
    await modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'confirm') {
      //this.fields.push(data);
      this.localService.handleRefresh.next(1);
    }
  }

  async openOrdiniShowColumnsModal() {
    const modal = await this.modalCtrl.create({
      component: OrdiniShowColumnsComponent,
      backdropDismiss: false
    });
    await modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'confirm') {
      //this.fields.push(data);
      this.localService.handleRefresh.next(1);
    }
  }

  async openTipoFiltroOrdineModal() {
    const modal = await this.modalCtrl.create({
      component: FiltroOrdineComponent,
      backdropDismiss: false
    });
    await modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'confirm') {
      //this.fields.push(data);
      this.localService.handleRefresh.next(1);
    }
  }

  logout = () => this.authService.logout();
}
