import { Component, OnInit, Input } from '@angular/core';
import {LicenzeService} from '../../../_services/licenze.service';
import { Licenza} from '../../../_models/Licenza';
import { SearchResult } from '../../../_models/SearchResult';
import { ModuloConfig } from '../../../_models/ModuloConfig';
import {SaveMessage} from '../../../_helpers/save-message';

@Component({
  selector: 'app-licenze-config',
  templateUrl: './licenze-config.component.html',
  styleUrls: ['./licenze-config.component.scss'],
})
export class LicenzeConfigComponent implements OnInit {
  @Input() organizzazioneId: number;
  @Input() roleId = -1;
  @Input() operatoreId = -1;
  licenza: Licenza;
  permessi: ModuloConfig[] = [];
  loading = false;
  today = new Date();
  activeTab = 'licenza-periodo';

  constructor(
    private saveMessage: SaveMessage,
    private licenzeService: LicenzeService) { }

  ngOnInit() {
    this.getLicenza();
    this.getPermessiTree();
  }

  getLicenza = () => {
    this.loading = true;
    this.licenzeService.getLicenzaFromIdAsync(this.organizzazioneId)
      .then((_licenza: Licenza) => this.licenza = _licenza)
      .catch(() => this.saveMessage.messageError('Ci sono problemi con caricamento di Licenza'))
      .finally(() => this.loading = false);
  };

  createNewLicenza = () => {
    this.loading = true;
    this.licenza = { id: 0, organizzazioneId: this.organizzazioneId, activationDate: new Date(), expiryDate: new Date(), maxDispositivi: 0, maxSedi: 0, maxRisorse: 0 };
    this.licenzeService.createLicenzaAsync(this.licenza)
      .then(() => this.getLicenza())
      .catch(() => this.saveMessage.messageError('Ci sono problemi con creazione di Licenza'))
      .finally(() => this.loading = false);
  };

  getPermessiTree = () => {
    this.loading = true;
    this.licenzeService.getModuliConfigFilteredAsync(this.organizzazioneId, this.roleId, this.operatoreId)
      .then((_permessi: SearchResult<ModuloConfig>) => this.permessi = _permessi.result)
      .catch(() => this.saveMessage.messageError('Ci sono problemi con il caricamento dei permessi'))
      .finally(() => this.loading = false);
  };

  updateLicenza = () => {
    this.loading = true;
    this.licenzeService.updateLicenzaAsync(this.licenza)
      .then(() => this.saveMessage.messageInfo('Aggiornamento riuscito'))
      .catch(() => this.saveMessage.messageError('Ci sono problemi con il salvataggio di licenza'))
      .finally(() => this.loading = false);
  };
}
