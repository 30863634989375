import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-select-type-tracciato',
  templateUrl: './select-type-tracciato.component.html',
  styleUrls: ['./select-type-tracciato.component.scss'],
})
export class SelectTypeTracciatoComponent {
  @Input() tracciatoType: number;
  @Output() tracciatoTypeChange = new EventEmitter<number>();
  public tracciatoTypes =
    [
      { value: 1, label: 'Scrittura' },
      { value: 2, label: 'Lettura' }
    ];
  selectTracciatoType = (e: any) => this.tracciatoTypeChange.emit(+e.target.value);
}
