import {Component, EventEmitter, OnInit, Input, Output} from '@angular/core';
import { TracciatiService } from '../../../_services/tracciato.service';
import { OrganizzazioniService } from '../../../_services/organizzazioni.service';
import {Tracciato, SearchResult} from '../../../_models/_Index';
import {SaveMessage} from '../../../_helpers/save-message';

@Component({
  selector: 'app-select-tracciati',
  templateUrl: './select-tracciati.component.html',
  styleUrls: ['./select-tracciati.component.scss'],
})
export class SelectTracciatiComponent implements OnInit {
  @Input() tracciatoId: number;
  @Output() tracciatoIdChange = new EventEmitter<number>();
  loading = false;
  tracciati: Tracciato[] = [];

  constructor(
    private saveMessage: SaveMessage,
    private tracciatiService: TracciatiService,
    private organizzazioniService: OrganizzazioniService) { }

  ngOnInit() {
    this.getTracciati();
  }

  getTracciati = () => {
    this.loading = true;
    this.tracciatiService
      .getTracciatiFilteredAsync(this.organizzazioniService.organizzazioneId, '', 0, 0, 'codice', true)
      .then((_tracciati: SearchResult<Tracciato>) => this.tracciati = _tracciati.result)
      .catch(() => this.saveMessage.messageError('Ci sono problemi con il caricamento di Tracciati'))
      .finally(() => this.loading = false);
  };

  selectTracciato = (e: any) => this.tracciatoIdChange.emit(e.target.value);
}
