export enum TracciatoTerminatoModes {
  never = 0,
  target = 1,
  plusPieces = 2,
  plusPercent = 3
}

export const TRACCIATO_TERMINATO_MODES = [
  { value: TracciatoTerminatoModes.never, label: 'Mai' },
  { value: TracciatoTerminatoModes.target, label: 'Quantità richiesta' },
  { value: TracciatoTerminatoModes.plusPieces, label: 'Quantità richiesta + N Pezzi' },
  { value: TracciatoTerminatoModes.plusPercent, label: 'Quantità richiesta + % Pezzi' }
];
