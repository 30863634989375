import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-ordini-filter-row-codice-univoco',
  templateUrl: './ordini-filter-row-codice-univoco.component.html',
  styleUrls: ['./ordini-filter-row-codice-univoco.component.scss'],
})
export class OrdiniFilterRowCodiceUnivocoComponent {
  @Input() id = '';
  @Output() idChange = new EventEmitter<string>();
  @Output() refresh = new EventEmitter();
  idDocumento = 0;
  idRiga = 0;
  idRigaLavorazione = 0;

  selectOrdine = () => {
    this.idChange.emit(`${this.idDocumento}.${this.idRiga}.${this.idRigaLavorazione}`);
    this.refresh.emit();
  };
}
