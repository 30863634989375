import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileEsolver } from '../_models/FileEsolver';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import {OrganizzazioniService } from './organizzazioni.service';
import {SaveMessage} from '../_helpers/save-message';
import {Ordine} from '../_models/Ordine';
import {Organizzazione} from '../_models/Organizzazione';
import { DatePipe } from '@angular/common';
import {OrdineGroup} from '../_models/OrdineGroup';
import {FilterItem} from '../_models/FilterItem';
import {OrdineByBarcode} from '../_models/OrdineByBarcode';
import {SearchResult} from '../_models/SearchResult';
import {OrdineEsolver} from '../_models/OrdineEsolver';

const datepipe: DatePipe = new DatePipe('en-US');

@Injectable({ providedIn: 'root' })
export class AvanzamentoService{
  hubState: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public lastAvanzamentoCreated: BehaviorSubject<FileEsolver> = new BehaviorSubject<FileEsolver>(null);
  private hubConnection: HubConnection;

  constructor(
    private saveMessage: SaveMessage,
    private organizzazioniService: OrganizzazioniService,
    private http: HttpClient) {}

  public startConnection() {
    return new Promise((resolve, reject) => {
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(`${environment.localHubUrl}hubs/avanzamento`)
        .withAutomaticReconnect()
        .build();

      this.hubConnection.onclose(() => this.hubState.next(0));

      this.hubConnection.start()
        .then(() => {
          this.hubState.next(1);
          resolve(true);
        })
        .catch((err: any) => reject(err));

      this.hubConnection.onreconnected(() => this.hubState.next(1));

      this.hubConnection.on('SendCreated',(file: FileEsolver) => {
        if (file.organizzazione.id === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Avanzamento inviato ad Esolver!');
          this.lastAvanzamentoCreated.next(file);
        }
      });
    });
  }

  async invioFileEsolver(avanzamento: FileEsolver) {
    return await this.http.post<FileEsolver>(`${environment.localApiUrl}Avanzamento/InvioAvanzamento`, avanzamento)
      .toPromise();
  }

  async invioInizioGroup(orgId: number, dataEvento: number, ordineGroup: OrdineGroup, username: string) {
    return await this.http.post<OrdineGroup>(`${environment.localApiUrl}Avanzamento/InvioInizioGroup`,
      { orgId, dataEvento, ordineGroup, username })
      .toPromise();
  }

  async invioPausaGroup(orgId: number, dataEvento: number, ordineGroup: OrdineGroup, lavorataManuale: number, scartataManuale: number, magazzino: string, tipoTracciato: number, username: string) {
    return await this.http.post<OrdineGroup>(`${environment.localApiUrl}Avanzamento/InvioPausaGroup`,
      { orgId, dataEvento, ordineGroup, lavorataManuale, scartataManuale, magazzino, tipoTracciato, username })
      .toPromise();
  }

  async invioFineGroup(orgId: number, dataEvento: number, ordineGroup: OrdineGroup, chiudiQtaFine: boolean, username: string) {
    return await this.http.post<OrdineGroup>(`${environment.localApiUrl}Avanzamento/InvioFineGroup`,
      { orgId, dataEvento, ordineGroup, lavorataManuale: 0, scartataManuale: 0, chiudiQtaFine, username })
      .toPromise();
  }

  async invioLottoGroup(orgId: number, dataEvento: number, ordineGroup: OrdineGroup, lavorataManuale: number, scartataManuale: number, magazzino: string, tipoTracciato: number, username: string) {
    return await this.http.post<OrdineGroup>(`${environment.localApiUrl}Avanzamento/InvioLottoGroup`,
      { orgId, dataEvento, ordineGroup, lavorataManuale, scartataManuale, magazzino, tipoTracciato, username })
      .toPromise();
  }

  async invioTrasferimentoGroup(orgId: number, dataEvento: number, ordineGroup: OrdineGroup, magazzino: string, username: string) {
    return await this.http.post<OrdineGroup>(`${environment.localApiUrl}Avanzamento/InvioTrasferimentoGroup`,
      { orgId, dataEvento, ordineGroup, magazzino, username })
      .toPromise();
  }

  async undoPlanGroup(orgId: number, dataEvento: number, ordineGroup: OrdineGroup, lavorataManuale: number, scartataManuale: number, username: string) {
    return await this.http.post<OrdineGroup>(`${environment.localApiUrl}Avanzamento/UndoPlanGroup`,
      { orgId, dataEvento, ordineGroup, lavorataManuale, scartataManuale, username })
      .toPromise();
  }

  async getOrdiniByBarcode(orgId: number, saldato: boolean, filters: FilterItem<any>[], filtroOrdini: number) {
    return await this.http.post<OrdineByBarcode>(`${environment.localApiUrl}Avanzamento/GetOrdiniByBarcode`,
      { orgId, saldato, filters, search: '', sortField: '', sortAsc: false, group: '', filtroOrdini })
      .toPromise();
  }
  
  invioFileEsolverFromOrdine(ordine: Ordine, dataEvento: Date, currentOrganizzazioneValue: Organizzazione, aggLottoScarico: string, aggSaldatoRiga: boolean) {
    const rifIdDocumento = this.padLeft(ordine.idDocumento, 9);
    const rifIdRiga = this.padLeft(ordine.idRiga, 4);
    const rifIdRigaLavorazione = this.padLeft(ordine.idRigaLavorazione, 4);

    if (ordine.quantitaLavorataWait == null) {
      ordine.quantitaLavorataWait = 0;
    }
    if (ordine.qtaRimanente == null) {
      ordine.qtaRimanente = ordine.quantita;
    }
    const qtaApp = ordine.quantita - (ordine.qtaRimanente - ordine.quantitaLavorataWait);
    let qtaLavorata = ordine.quantitaLavorata - qtaApp; // - ordine.quantitaScartata;
    if (!ordine?.risorsa?.scartoSend) {
      qtaLavorata = qtaLavorata - ordine.quantitaScartata;
    }
    if (!this.isNumber(qtaLavorata)){
      qtaLavorata = 0;
    }

    const prevTempoLav = ordine.partialWorkingHours > 0 ? ((ordine.partialWorkingHours / 60) / 60) : 0;
    const tempoParzialeLav = ((((dataEvento.valueOf() / 1000) - ordine.lastActionTime) / 60) / 60);

    ordine.quantitaLavorataWait = qtaLavorata;
    ordine.quantitaScartataWait = ordine?.risorsa?.scartoSend ? ordine.quantitaScartata : 0;
    // CON AVANZAMENTO DA LISTA NO TEMPO
    //ordine.tempoLavWait = prevTempoLav + tempoParzialeLav;

    const ordineFileEsolver: FileEsolver = {
      //dataEvento: Math.round(new Date(dataEvento.toUTCString()).valueOf() / 1000),
      organizzazione: currentOrganizzazioneValue,
      lavorazioni: {
        idDocumento: ordine.idDocumento,
        idRiga: ordine.idRiga,
        idRigaLavorazione: ordine.idRigaLavorazione,
        codiceArticolo: ordine.codiceArticolo,
        quantita: ordine.quantita,
        quantitaLavorata: qtaLavorata,
        quantitaScartata: ordine?.risorsa?.scartoSend ? ordine.quantitaScartata : 0,
        tempo: '0', // (prevTempoLav + tempoParzialeLav).toFixed(4),
        risorsa: ordine?.risorsa?.codice ?? '',
        lavorazione: ordine?.lavorazione?.codice ?? '',
        rifOrdine: rifIdDocumento + rifIdRiga + rifIdRigaLavorazione,
        saldato: ordine.flgTerminato,
        saldatoRiga: aggSaldatoRiga,
        dataRegistrazione: datepipe.transform(dataEvento.toISOString(), 'ddMMyyyy'),
        lottoScarico: aggLottoScarico
      },
      ordine
    };

    return this.invioFileEsolver(ordineFileEsolver);
  }

  isNumber = (value) => typeof value === 'number' && isFinite(value);

  padLeft(num: number, size: number) {
    let s = num + '';
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }
}
