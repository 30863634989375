import {Component, Input, Output, OnDestroy, OnInit, EventEmitter} from '@angular/core';
import { Ordine } from '../../_models/Ordine';
import {OrdineGroup, Organizzazione} from '../../_models/_Index';
import { Subscription } from 'rxjs';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';

@Component({
  selector: 'app-ordini-locali',
  templateUrl: './ordini-locali.component.html',
  styleUrls: ['./ordini-locali.component.scss'],
})
export class OrdiniLocaliComponent implements OnInit, OnDestroy {
  @Input() flagTerminate: boolean;
  @Input() updateEnable: boolean;
  @Input() isPersonal: boolean;
  @Output() ordineSended = new EventEmitter<Ordine>();
  @Output() ordineGroupSended = new EventEmitter<OrdineGroup>();
  viewGroup: boolean;
  sortDirection = false;
  sortKey = 'dataTermine';
  loading = false;
  subscription: Subscription;

  constructor(
    private organizzazioniService: OrganizzazioniService) { }

  ngOnInit() {
    this.viewGroup = this.organizzazioniService.viewGroup;
    this.subscription = this.organizzazioniService.currentOrganizzazioneSubject
      .subscribe((_organizzazione: Organizzazione) => {
        if (_organizzazione && _organizzazione.accountSettings) {
          this.viewGroup = _organizzazione.accountSettings.activeViewGroup;
        }
      });
  }

  selectOrdineGroup = (ordineGroup: OrdineGroup) => {
    this.ordineGroupSended.emit(ordineGroup);
  };

  selectOrdine = (ordine: Ordine) => {
      this.ordineSended.emit(ordine);
  };

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
