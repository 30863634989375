import {Component, EventEmitter, Input, Output} from '@angular/core';
import {GroupEsolverResult} from '../../_models/GroupEsolverResult';
import {OrdineEsolver} from '../../_models/OrdineEsolver';
import {Subscription} from 'rxjs';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';
import {Organizzazione} from '../../_models/Organizzazione';

@Component({
  selector: 'app-ordine-esolver-groupbymany-item',
  templateUrl: './ordine-esolver-groupbymany-item.component.html',
  styleUrls: ['./ordine-esolver-groupbymany-item.component.scss'],
})
export class OrdineEsolverGroupbymanyItemComponent {
  @Input()ordineEsolverGroupByMany: GroupEsolverResult<OrdineEsolver>;
  @Input()level: number;
  @Output() ordiniEsolverAddSended = new EventEmitter<OrdineEsolver[]>();
  isVisibleOrdiniEsolver = false;
  today = new Date().toString();
  subscription: Subscription;
  groupColumns = [];
  constructor(
    private organizzazioniService: OrganizzazioniService) {
    this.groupColumns = this.organizzazioniService.groupColumnsArray;
    this.subscription = this.organizzazioniService.currentOrganizzazione
      .subscribe((_org: Organizzazione)=> {
        this.groupColumns = this.organizzazioniService.groupColumnsArray;
      });
  }
  showOrdiniEsolver = () => this.isVisibleOrdiniEsolver = !this.isVisibleOrdiniEsolver;
  addOrdiniEsolver = (ordiniEsolver: OrdineEsolver[]) => this.ordiniEsolverAddSended.emit(ordiniEsolver);
}
