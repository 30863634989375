import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { NotificheService } from '../../_services/notifiche.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-centro-notifiche-popup',
  templateUrl: './centro-notifiche-popup.component.html',
  styleUrls: ['./centro-notifiche-popup.component.scss'],
})
export class CentroNotifichePopupComponent  implements OnInit {
  @ViewChild('popover') popover;
  subscription: Subscription;
  isOpen = false;
  notifiche = [];
  constructor(private notificheService: NotificheService) { }

  ngOnInit() {
    this.subscription = this.notificheService.notificheAlert.subscribe((_notifiche) => this.notifiche =_notifiche);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
