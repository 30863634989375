import {Component, EventEmitter, Input, Output} from '@angular/core';
import { FilterItem } from '../../_models/FilterItem';
import { TipoFormControl } from '../../_helpers/TipoFormControl';
import { TipoOperazione } from '../../_helpers/TipoOperazione';
import { SelectDateRangeComponent } from '../select/select-date-range/select-date-range.component';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-ordini-filter-row',
  templateUrl: './ordini-filter-row.component.html',
  styleUrls: ['./ordini-filter-row.component.scss'],
})
export class OrdiniFilterRowComponent {
  @Input() filters: FilterItem<any>[];
  @Output() filtersChange = new EventEmitter<FilterItem<any>[]>();
  @Output() handleRefresh = new EventEmitter<number>();
  tipoFormControl = TipoFormControl;
  tipoOperazione = TipoOperazione;

  constructor(private modalController: ModalController) {}

  refreshOrdine = (codiceUnivoco: string) => {
    this.refresh();
  };

  findVisible(): any[] {
    return this.filters.filter(p => p.showColumn);
  }

  refresh = () => this.handleRefresh.emit(1);

  async openDateModal(column: FilterItem<any>) {
    const modal = await this.modalController.create({
      component: SelectDateRangeComponent,
      componentProps: {
        singleDate: column.operazione !== this.tipoOperazione.between,
        startDate: column.value1, endDate: column.value2
      }
    });
    await modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'confirm') {
      column.value1 = data.startDate;
      column.value2 = data.endDate;
      this.refresh();
    }
  }
}
