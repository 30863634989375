import {Component, Input, Output, OnInit, EventEmitter, OnDestroy} from '@angular/core';
import {Ordine} from '../../_models/Ordine';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';
import {LocalService} from '../../_services/local.service';
import {SaveMessage} from '../../_helpers/save-message';
import {GroupResult} from '../../_models/GroupResult';
import {SearchResult} from '../../_models/SearchResult';
import {OrdineGroup} from '../../_models/OrdineGroup';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-ordini-locali-groupbymany',
  templateUrl: './ordini-locali-groupbymany.component.html',
  styleUrls: ['./ordini-locali-groupbymany.component.scss'],
})
export class OrdiniLocaliGroupbymanyComponent  implements OnInit, OnDestroy {
  @Input() flagTerminate: boolean;
  @Output() ordineGroupSended = new EventEmitter<OrdineGroup>();
  sortDirection = true;
  sortKey = 'dataTermine';
  ordiniGroups: GroupResult<Ordine>[] = [];
  page = 0;
  count = 20;
  loadMore = false;
  loading = false;
  selectedOrdiniGroup: OrdineGroup;
  subscription: Subscription;

  constructor(
    private organizzazioniService: OrganizzazioniService,
    private localService: LocalService,
    private saveMessage: SaveMessage) { }

  ngOnInit() {
    this.selectedOrdiniGroup = this.localService.getEmptyOrder();
    this.selectedOrdiniGroup.ordini = [];
    //this.getOrdiniGroupByMany(false);
    this.subscription = this.localService.searchText.subscribe((_value: string) => {
      this.getOrdiniGroupByMany(false);
    });
    this.subscription.add(this.localService.handleRefresh.subscribe(() => this.getOrdiniGroupByMany(false)));
  }

  getOrdiniGroupByMany = (add: boolean) => {
    this.loadMore = true;
    this.loading = true;
    if (!add) {
      this.ordiniGroups = [];
      this.page = 0;
    } else {
      this.page += 1;
    }
    const search = this.localService.searchText.getValue().toString();
    this.localService.getOrdiniInviatiFilteredGroupByMany(this.organizzazioniService.organizzazioneId,
      this.flagTerminate, search, this.page, this.count, this.sortKey, this.sortDirection,
      this.localService.localFilters.value, this.organizzazioniService.groupColumnsArray)
      .then((_result: SearchResult<GroupResult<Ordine>>) => {
        if (_result.totalCount === 0) {
          this.ordiniGroups = [];
          return false;
        }
        if (!add) {
          this.ordiniGroups = _result.result;
        } else {
          _result.result.forEach((ord: GroupResult<Ordine>) => this.ordiniGroups.push(ord));
        }
        this.loadMore = _result.totalCount > this.ordiniGroups.length;
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con raggruppamento gerarchico'))
      .finally(() => this.loading = false);
  };

  addOrdini = (ordini: Ordine[]) => ordini.forEach((_ordine: Ordine) => {
    if (this.selectedOrdiniGroup.ordini.indexOf(_ordine) < 0) {
      this.selectedOrdiniGroup.ordini.push(_ordine);
    }
  });

  confirmOrdineGroup = () => this.ordineGroupSended.emit(this.selectedOrdiniGroup);

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
