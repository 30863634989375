import { Component, OnInit, Input } from '@angular/core';
import { TracciatiService } from '../../../_services/tracciato.service';
import { TracciatoField } from '../../../_models/TracciatoField';
import { ModalController } from '@ionic/angular';
import { SaveMessage } from '../../../_helpers/save-message';
import { MessageConfirm } from '../../../_helpers/message-confirm.service';
import { TracciatiFieldCreateComponent } from '../tracciati-field-create/tracciati-field-create.component';
import {SearchResult} from '../../../_models/SearchResult';

@Component({
  selector: 'app-tracciati-field-list',
  templateUrl: './tracciati-field-list.component.html',
  styleUrls: ['./tracciati-field-list.component.scss'],
})
export class TracciatiFieldListComponent implements OnInit {

  @Input() tracciatoId;
  fields: TracciatoField[] = [];
  loading = false;

  constructor(
    private tracciatiService: TracciatiService,
    private modalCtrl: ModalController,
    private message: SaveMessage,
    private deleteConfirm: MessageConfirm) { }

  ngOnInit() {
    this.getFieldsOfTracciato();
  }

  getFieldsOfTracciato = () => {
    this.loading = true;
   this.tracciatiService.getFieldsOfTracciatiAsync(this.tracciatoId)
     .then((_fields: SearchResult<TracciatoField>) => {
       if (_fields.totalCount > 0) {
         this.fields = _fields.result;
       }
     })
     .catch(() => this.message.messageError('Ci sono problemi con il carimento dei campi'))
     .finally(() => this.loading = false);
  };

  async openModal(field?: TracciatoField) {
    const modal = await this.modalCtrl.create({
      component: TracciatiFieldCreateComponent,
      backdropDismiss: false,
      componentProps: { tracciatoId: this.tracciatoId, tracciatoField: field, isNew: field == null }
    });
    await modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'confirm') {
      //this.fields.push(data);
      this.getFieldsOfTracciato();
    }
  }

  deleteTracciatoField = (tracciatoId: number, fieldId: number) => this.deleteConfirm.presentAlert('Sei sicuro di voler eleminare?',() => {
    this.tracciatiService.deleteTracciatoFieldAsync(tracciatoId, fieldId)
      .then(() => {
        this.message.messageInfo('Campo eliminato con successo');
        this.getFieldsOfTracciato();
      })
      .catch(() => this.message.messageError('Problemi durante elimina di tracciato'));
  });

  sortFn = (a: TracciatoField, b: TracciatoField): number => {
    if (a.sortIndex < b.sortIndex) {
      return -1;
    }
    if (a.sortIndex === b.sortIndex){
      return 0;
    }
    if (a.sortIndex > b.sortIndex){
      return 1;
    }
  };

}
