import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class MessageConfirm {
    constructor(private alertController: AlertController) {}
    async presentAlert(message: string, handleDelete: any)
    {
        const alert = await this.alertController.create({
            header: message,
            buttons: [
              { text: 'Annulla', role: 'cancel' },
              { text: 'OK', role: 'confirm', handler: () => { handleDelete(); }}
            ]
        });
        await alert.present();
        await alert.onDidDismiss()
          .then((role: any) => {
            if (role.role === 'confirm') {
              handleDelete();
            }
          });
    }
}
