import { Component, Input, Output, OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { OrdineGroup} from '../../_models/_Index';
import {Organizzazione, SearchResult} from '../../_models/_Index';
import { OrganizzazioniService} from '../../_services/organizzazioni.service';
import { LocalService } from '../../_services/local.service';
import {Subscription} from 'rxjs';
import {SaveMessage} from '../../_helpers/save-message';

@Component({
  selector: 'app-ordini-locali-group',
  templateUrl: './ordini-locali-group.component.html',
  styleUrls: ['./ordini-locali-group.component.scss'],
})
export class OrdiniLocaliGroupComponent implements OnInit, OnDestroy {
  @Input() loading: boolean;
  @Input() flagTerminate: boolean;
  @Input() updateEnable: boolean;
  @Input() sortKey: string;
  @Input() sortDirection: boolean;
  @Input() isPersonal: boolean;
  @Output() loadingChange = new EventEmitter<boolean>();
  @Output() ordineGroupSended = new EventEmitter<OrdineGroup>();
  @Output() totalQtaChange = new EventEmitter<number>();
  ordiniGroups: OrdineGroup[] = [];
  subscription: Subscription;
  page = 0;
  count = 20;
  loadMore = false;
  constructor(
    private organizzazioniService: OrganizzazioniService,
    private localService: LocalService,
    private saveMessage: SaveMessage) { }

  ngOnInit() {
    this.subscription = this.localService.searchText.subscribe((_value: string) => {
      this.readOrdiniInviati(false);
    });
    this.subscription.add(this.organizzazioniService.currentOrganizzazioneSubject.subscribe((_org: Organizzazione) => {
      this.readOrdiniInviati(false);
    }));
    this.subscription.add(this.localService.handleRefresh.subscribe(() => this.readOrdiniInviati(false)));
  }

  async readOrdiniInviati(add: boolean) {
    this.loadMore = true;
    if (!add) {
      this.ordiniGroups = [];
      this.page = 0;
    } else {
      this.page += 1;
    }
    const search = this.localService.searchText.getValue().toString();
    this.totalQtaChange.emit(0);
    this.loadingChange.emit(true);
    await this.localService.getOrdiniInviatiFilteredGroup(this.organizzazioniService.organizzazioneId,
      this.flagTerminate, search, this.page, this.count, this.sortKey, this.sortDirection,
      this.localService.localFilters.value, this.isPersonal, this.organizzazioniService.groupColumnsArray)
      .then((_result: SearchResult<OrdineGroup>) => {
        this.localService.totalCount.next(_result.totalCount);
        this.sumQta();
        if (_result.totalCount === 0) {
          this.ordiniGroups = [];
          return false;
        }
        if (!add) {
          this.ordiniGroups = _result.result;
        } else {
          _result.result.forEach((ord: OrdineGroup) => this.ordiniGroups.push(ord));
        }
        if (_result.totalCount <= this.ordiniGroups.length) {
          this.loadMore = false;
        }
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con le API ArchiConnect'))
      .finally(() => this.loadingChange.emit(false));
  }

  sumQta = () => {
    let totalQta = 0;
    this.ordiniGroups.forEach((ordine: OrdineGroup) => totalQta += ordine.quantita);
    this.totalQtaChange.emit(totalQta);
  };

  sort(key: string) {
    if (this.sortKey === key) {
      this.sortDirection = !this.sortDirection;
    }
    this.sortKey = key;
    this.readOrdiniInviati(false);
  }

  selectOrdineGroup = (ordineGroup: OrdineGroup) => {
    this.ordineGroupSended.emit(ordineGroup);
  };

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
