import {Component, EventEmitter, Input, Output} from '@angular/core';
import { TIPO_FILTRO_ORDINE, TipoFiltroOrdine} from '../../../_helpers/TipoFiltroOrdine';

@Component({
  selector: 'app-select-type-filtro-ordine',
  templateUrl: './select-type-filtro-ordine.component.html',
  styleUrls: ['./select-type-filtro-ordine.component.scss'],
})
export class SelectTypeFiltroOrdineComponent {
  @Input() tipoFiltroOrdine: TipoFiltroOrdine;
  @Output() tipoFiltroOrdineChange = new EventEmitter<TipoFiltroOrdine>();
  tipoFiltroOrdinesItem = TIPO_FILTRO_ORDINE;
  selectTipoFiltroOrdine = (e: any) => this.tipoFiltroOrdineChange.emit(e.target.value);
}
