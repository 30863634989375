import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Lotto} from '../_models/Lotto';
import {environment} from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class LottiService {
  constructor(private http: HttpClient) {}

  async createLottoAsync(orgId: number, lotto: Lotto) {
    return await this.http.post<Lotto>(`${environment.localApiUrl}Lotti/CreateLotto/${orgId}`, lotto)
      .toPromise();
  }

  async leggiLottoAsync(orgId: number, lotto: Lotto) {
    return await this.http.post<Lotto>(`${environment.localApiUrl}Lotti/LeggiLotto/${orgId}`, lotto)
      .toPromise();
  }

  async leggiProgressivoAsync(orgId: number, lotto: Lotto) {
    return await this.http.post<Lotto>(`${environment.localApiUrl}Lotti/LeggiProgressivo/${orgId}`, lotto)
      .toPromise();
  }
}
