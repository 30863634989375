import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {OrdiniStat} from '../_models/_Index';
import {ArticoliStat} from '../_models/ArticoliStat';
import {environment} from '../../environments/environment';
import {ArticoloProduzioneStat} from '../_models/ArticoloProduzioneStat';

@Injectable({ providedIn: 'root' })
export class StatisticheService {
  constructor(private http: HttpClient) {}

  getStatistics(start: Date, end: Date, orgId: number, risorsaId: number) {
    return this.http.get<OrdiniStat>(`${environment.localApiUrl}Statistiche/GetOrdiniStatistiche?`+
      `start=${start.toUTCString()}&end=${end.toUTCString()}&orgId=${orgId}&risorsaId=${risorsaId}`)
      .toPromise();
  }

  getArticoloStatistics(start: Date, end: Date, orgId: number, codice: string, risorsaId: number, lavorazioneId: number) {
    return this.http
      .get<ArticoliStat>(`${environment.localApiUrl}Statistiche/GetArticoloStatistiche?` +
        `start=${start.toUTCString()}&end=${end.toUTCString()}&orgId=${orgId}&codice=${codice}&`+
        `risorsaId=${risorsaId}&lavorazioneId=${lavorazioneId}`)
      .toPromise();
  }

  getCommessaStatistics(start: Date, end: Date, orgId: number, codice: string, risorsaId: number, lavorazioneId: number) {
    return this.http
      .get<ArticoliStat>(`${environment.localApiUrl}Statistiche/GetCommessaStatistiche?`+
        `start=${start.toUTCString()}&end=${end.toUTCString()}&orgId=${orgId}&codice=${codice}&`+
        `risorsaId=${risorsaId}&lavorazioneId=${lavorazioneId}`)
      .toPromise();
  }

  getArticoloProduzione(orgId: number, codice: string) {
    return this.http
      .get<ArticoloProduzioneStat[]>(`${environment.localApiUrl}Statistiche/GetArticoloProduzione?orgId=${orgId}&codice=${codice}`)
      .toPromise();
  }
}
