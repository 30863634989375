import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Organizzazione } from '../../_models/Organizzazione';
import { OrganizzazioniService } from '../../_services/organizzazioni.service';
import {SaveMessage} from '../../_helpers/save-message';

interface SelectItem {
  value: number;
  text: string;
}
@Component({
  selector: 'app-organizzazioni-create',
  templateUrl: './organizzazioni-create.component.html',
  styleUrls: ['./organizzazioni-create.component.scss'],
})

export class OrganizzazioniCreateComponent implements OnInit {

  organizzazione: Organizzazione = {
    id: 0,
    codice: '',
    descrizione: '',
    isActive: true,
    codiceGruppo: '',
    tipoInstallazione: 0,
    pathFile: '',
    hostFtp: '',
    passwordFtp: '',
    utenteFtp: '',
    indirizzoApiEsolver: '',
    hostApiEsolver: '',
    usernameApiEsolver: '',
    passwordApiEsolver: ''
  };
  loading = false;

  public tipoInstallazione: SelectItem[] = [
    { value: 1, text: 'LOCALE' },
    { value: 2, text: 'CLOUD' },
  ];

  constructor(
    private saveMessage: SaveMessage,
    private organizzazioniService: OrganizzazioniService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() { }

  cancel = () => this.modalCtrl.dismiss(false, 'cancel');

  creaOrganizzazione(): void {
    this.loading = true;
    this.organizzazioniService.createOrganizzazioneAsync(this.organizzazione)
      .then((_organizzazione: Organizzazione) => { this.modalCtrl.dismiss(_organizzazione, 'confirm'); })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con la creazione di organizzazione'))
      .finally(() => this.loading = false);
  }
}
