import { Component, Input } from '@angular/core';
import {TracciatoFieldTypesEnum} from '../../../_helpers/TracciatoFieldTypes';

@Component({
  selector: 'app-tracciati-type-field-label',
  templateUrl: './tracciati-type-field-label.component.html',
  styleUrls: ['./tracciati-type-field-label.component.scss'],
})
export class TracciatiTypeFieldLabelComponent {
  @Input() tracciatiTypeField: TracciatoFieldTypesEnum;
}
