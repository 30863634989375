import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';
import {Organizzazione} from '../../_models/Organizzazione';
import {AccountOrganizzazione} from '../../_models/AccountOrganizzazione';
import {SaveMessage} from '../../_helpers/save-message';

@Component({
  selector: 'app-ordini-viewmode-switch',
  templateUrl: './ordini-viewmode-switch.component.html',
  styleUrls: ['./ordini-viewmode-switch.component.scss'],
})
export class OrdiniViewmodeSwitchComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  organizzazione: Organizzazione;
  accountOrganizzazione: AccountOrganizzazione;
  loading = false;
  constructor(
    private saveMessage: SaveMessage,
    private organizzazioneService: OrganizzazioniService) { }

  ngOnInit() {
    this.organizzazione = this.organizzazioneService.currentOrganizzazioneSubject.value;
    if (this.organizzazione && this.organizzazione.accountSettings) {
      this.accountOrganizzazione = this.organizzazione.accountSettings;
    }
    this.subscription = this.organizzazioneService.currentOrganizzazioneSubject
      .subscribe((_organizzazione: Organizzazione) => {
        if (_organizzazione && _organizzazione.accountSettings) {
          this.organizzazione = _organizzazione;
          this.accountOrganizzazione = _organizzazione.accountSettings;
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setViewMode = (viewGroup: boolean) => {
    this.loading = true;
    this.accountOrganizzazione.activeViewGroup = viewGroup;
    this.organizzazioneService.saveAccountOrganizzazioneAsync(this.accountOrganizzazione)
      .then((_accountOrganizzazione: AccountOrganizzazione) => {
        this.organizzazione.accountSettings = _accountOrganizzazione;
        this.organizzazioneService.setCurrentOrganizzazioneValue(this.organizzazione);
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con salvataggio'))
      .finally(() => this.loading = false);
  };
}
