export enum TracciatoFieldFormatTypes {
  noFormat = 0,
  onlyNumber = 1,
  onlyString = 2,
  onlyNumberAndString = 3,
  onlyUpperString = 4,
  onlyLowerString = 5
}

export const TRACCIATO_FIELD_FORMAT_TYPES = [
  { value: TracciatoFieldFormatTypes.noFormat, label: 'No-Format' },
  { value: TracciatoFieldFormatTypes.onlyNumber, label: 'Numeri [0-9]' },
  { value: TracciatoFieldFormatTypes.onlyString, label: 'Stringhe [A-Za-z]' },
  { value: TracciatoFieldFormatTypes.onlyNumberAndString, label: 'Numeri + Stringhe [0-9A-Za-z]' },
  { value: TracciatoFieldFormatTypes.onlyUpperString, label: 'Stringhe Maiuscole [A-Z]'},
  { value: TracciatoFieldFormatTypes.onlyLowerString, label: 'Stringhe Minuscole [a-z]'}
];
