import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {SearchResult, Evento, Ordine, FilterItem, WorkSession} from '../_models/_Index';
import { environment } from '../../environments/environment';
import { OrganizzazioniService} from'./organizzazioni.service';
import {StatoEvento} from '../_helpers/stato-evento';
import {StatoRisorsa} from '../_helpers/stato-risorsa';
import {BehaviorSubject} from 'rxjs';
import {HubConnection, HubConnectionBuilder} from '@microsoft/signalr';
import {SaveMessage} from '../_helpers/save-message';
import {AuthenticationService} from './authentication.service';

@Injectable({ providedIn: 'root' })
export class EventiService {

  public lastEventoCreated: BehaviorSubject<Evento> = new BehaviorSubject<Evento>(null);
  public lastEventoUpdated: BehaviorSubject<Evento> = new BehaviorSubject<Evento>(null);
  public lastEventoDeleted: BehaviorSubject<Evento> = new BehaviorSubject<Evento>(null);
  public hubState: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private hubConnection: HubConnection;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private organizzazioniService: OrganizzazioniService,
    private saveMessage: SaveMessage) {}

  public startConnection() {
    return new Promise((resolve, reject) => {
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(`${environment.localHubUrl}hubs/evento`)
        .withAutomaticReconnect()
        .build();

      this.hubConnection.onclose(() => this.hubState.next(0));

      this.hubConnection.start()
        .then(() => {
          this.hubState.next(1);
          resolve(true);
        })
        .catch((err: any) => reject(err));

      this.hubConnection.on('SendCreated',(evento: Evento) => {
        if (evento.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Nuovo evento creato!');
          this.lastEventoCreated.next(evento);
        }
      });

      this.hubConnection.on('SendUpdated',(evento: Evento) => {
        if (evento.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Evento aggiornato!');
          this.lastEventoUpdated.next(evento);
        }
      });

      this.hubConnection.on('SendDeleted',(evento: Evento) => {
        if (evento.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Evento eliminato!');
          this.lastEventoDeleted.next(evento);
        }
      });
    });
  }

  async getEventiFilteredAsyn(orgid: number, search: string, page: number, count: number, sortField: string, sortAsc: boolean, filters: FilterItem<any>[]) {
    return await this.http.post<SearchResult<Evento>>(`${environment.localApiUrl}Eventi/EventiFiltered/${orgid}`,
      { search, page, count, sortField, sortAsc, filters })
      .toPromise();
  }

  async getEventoAsyn(orgId: number, idEvento: number) {
    return await this.http.get<Evento>(`${environment.localApiUrl}Eventi/Evento/${orgId}/${idEvento}`)
      .toPromise();
  }

  async createEventoAsync(evento: Evento) {
    return await this.http.post<Evento>(`${environment.localApiUrl}Eventi/CreateEvento`, evento)
      .toPromise();
  }

  async creaUserEvento(stato: number, organizzazioneId: number, dataEvento?: Date) {
    const evento: Evento = new Evento();
    evento.isActive = true;
    evento.organizzazioneId = organizzazioneId;
    evento.tipoEvento = StatoEvento.INFO;
    evento.statoRisorsa = stato;
    evento.dataRegistrazione = dataEvento;
    evento.descrizione = `${StatoRisorsa[stato]} user`;
    evento.executedBy = this.authService.currentUserValue.userName;
  }

  async creaEvento(numEvento: number, ordine: Ordine, dataEvento?: Date) {
    //const utcSeconds = parseInt(((dataEvento.getTime() + dataEvento.getTimezoneOffset()*60*1000)/1000).toString(), 10);
    const utcSeconds = parseInt((dataEvento.getTime()/1000).toString(), 10);
    const evento: Evento = new Evento();
    evento.isActive = true;
    evento.organizzazioneId = ordine.organizzazioneId;
    evento.tipoEvento = StatoEvento.INFO;
    evento.idDocumento = ordine.idDocumento;
    evento.idRiga = ordine.idRiga;
    evento.idRigaLavorazione = ordine.idRigaLavorazione;
    evento.risorsaId = ordine.risorsaId;
    evento.statoRisorsa = numEvento;
    evento.dataUnixRegistrazione = utcSeconds;
    evento.dataRegistrazione = dataEvento;
    evento.descrizione = `${StatoRisorsa[numEvento]} ${ordine.idDocumento}.${ordine.idRiga}.${ordine.idRigaLavorazione}`;
    evento.note = JSON.stringify(ordine);
    evento.executedBy = this.authService.currentUserValue.userName;

    return this.createEventoAsync(evento);
  }

  async updateEventoAsync(evento: Evento) {
    return await this.http.post<Evento>(`${environment.localApiUrl}Eventi/UpdateEvento`, evento)
      .toPromise();
  }

  async deleteEventoAsync(orgId: number, id: number) {
    return await this.http.get<Evento>(`${environment.localApiUrl}Eventi/DeleteEvento/${orgId}/${id}`)
      .toPromise();
  }

  async deleteEventoPostDateAsync(orgId: number, risorsaId: number) {
    return await this.http.get<Evento>(`${environment.localApiUrl}Eventi/DeleteEventoPosticipate/${orgId}/${risorsaId}`)
      .toPromise();
  }

  async getWorkSessions(startDate: Date, endDate: Date, orgId: number, risorsaId: number) {
    return await this.http.post<WorkSession[]>(`${environment.localApiUrl}Eventi/GetWorkSessions`,
      {startDate, endDate, orgId, risorsaId})
      .toPromise();
  }
}
