export enum TracciatoLinkModes {
  byKeyFirst = 0,
  byKeyOne = 1,
  firstActive = 2
}

export const TRACCIATO_LINK_MODES = [
  { value: TracciatoLinkModes.byKeyOne, label: 'Chiavi, primo attivo di risorsa' },
  { value: TracciatoLinkModes.byKeyFirst, label: 'Chiavi Preciso' },
  { value: TracciatoLinkModes.firstActive, label: 'Primo attivo di risorsa' }
];
