import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TRACCIATO_FIELDS} from '../../../_helpers/TracciatoFields';
import {TracciatoFieldTypesEnum} from '../../../_helpers/TracciatoFieldTypes';

@Component({
  selector: 'app-select-tracciati-field',
  templateUrl: './select-tracciati-field.component.html',
  styleUrls: ['./select-tracciati-field.component.scss'],
})
export class SelectTracciatiFieldComponent {
  @Input() field: string;
  @Input() fieldType: TracciatoFieldTypesEnum;
  @Output() fieldChange = new EventEmitter<string>();
  tracciatoFieldsItem = TRACCIATO_FIELDS;
  selectField = (e: any) => this.fieldChange.emit(e.target.value);

  filterFields(fields: any[]) {
    return fields.filter(x => x.fieldType === this.fieldType);
  }
}
