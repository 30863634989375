import { Detail } from './Detail';

export class RisorsaOpcUa extends Detail {
    risorsaId: number;

    //UTENZA
    username: string;
    password: string;
    utenza: boolean;

    //SCRITTURA
    codeNodeId: string;
    numRegNodeId: string;
    codArtNodeId: string;
    descArtNodeId: string
    qtyNodeId: string;

    //LETTURA
    actualCodeNodeId: string;
    actualNumRegNodeId: string;
    actualCodeArtNodeId: string;
    actualDescArtNodeId: string;
    actualQtyNodeId: string;
    actualRejectNodeId: string;

    //ERRORE
    errorNodeId: string;
    //STATO
    stateNodeId: string;
    workingState: number;
    
}
