import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Operatore, SearchResult} from '../_models/_Index';
import { environment } from '../../environments/environment';
import {Subject} from 'rxjs';
import {OperatoreSessione} from '../_models/OperatoreSessione';

@Injectable({ providedIn: 'root' })
export class OperatoriService {

  public handleSave: Subject<number> = new Subject();
  constructor(private http: HttpClient) { }

  async getOperatoreAsync(id: number) {
    return await this.http.get<Operatore>(`${environment.localApiUrl}Operatori/Operatore/${id}`)
      .toPromise();
  }

  async setOrganizzazioneAsync(orgId: number) {
    return await this.http.get<number>(`${environment.localApiUrl}Operatori/SetOrganizzazione/${orgId}`)
      .toPromise();
  }

  async getOperatoreByEmailAsync(email: string) {
    return await this.http.get<Operatore>(`${environment.localApiUrl}Operatori/OperatoreEmail/${email}`)
      .toPromise();
  }

  async updateOperatoreAsync(operatore: Operatore) {
    return await this.http.post<Operatore>(`${environment.localApiUrl}Operatori/UpdateOperatore`, operatore)
      .toPromise();
  }

  async deleteOperatoreAsync(id: number) {
    return await this.http.get(`${environment.localApiUrl}Operatori/DeleteOperatore/${id}`)
      .toPromise();
  }

  async getOperatoreSessioniAsync(id: number, page: number, count: number) {
    return await this.http
      .get<SearchResult<OperatoreSessione>>(`${environment.localApiUrl}Operatori/OperatoreSessioni/${id}/${page}/${count}`)
      .toPromise();
  }
}
