import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-select-risorse-condition',
  templateUrl: './select-risorse-condition.component.html',
  styleUrls: ['./select-risorse-condition.component.scss'],
})
export class SelectRisorseConditionComponent {
  @Input() condition = 0;
  @Output() conditionChange = new EventEmitter<number>();
  conditions = [
    {value: 0, label: 'Normale'},
    {value: 1, label: 'Manutenzione'}
  ];
  selectCondition = (e: any) => this.conditionChange.emit(e.target.value);
}
