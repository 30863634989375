export enum StatoRisorsa {
  STOP = -1,
  PLANNED = 1,
  LAUNCH = 2,
  START = 3,
  PAUSE = 6,
  //RESTART = 13,
  END = 4,
  SUSPENDE = 5,
  TRANSFER = 21,
  USER_LOGIN = 51,
  USER_REGISTER = 52,
  CHANGE = 91,
  DELETE = 92,
  END_FLAG = 93,
  SEND_ESOLVER_WAIT = 97,
  SEND_ESOLVER_KO = 98,
  SEND_ESOLVER = 99
}
