import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TracciatoFieldFormatTypes, TRACCIATO_FIELD_FORMAT_TYPES} from '../../../_helpers/TracciatoFieldFormatTypes';

@Component({
  selector: 'app-select-tracciato-field-type-format',
  templateUrl: './select-tracciato-field-type-format.component.html',
  styleUrls: ['./select-tracciato-field-type-format.component.scss'],
})
export class SelectTracciatoFieldTypeFormatComponent  {
  @Input() fieldFormatType: TracciatoFieldFormatTypes;
  @Output() fieldFormatTypeChange = new EventEmitter<TracciatoFieldFormatTypes>();
  tracciatoFieldFormatTypesItem = TRACCIATO_FIELD_FORMAT_TYPES;
  selectFieldFormatType = (e: any) => this.fieldFormatTypeChange.emit(e.target.value);
}
