import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface NotificationItem {
  message: string;
  color: string;
  dateTime: Date;
}

@Injectable({ providedIn: 'root' })
export class NotificheService {
  public notifiche: BehaviorSubject<Array<NotificationItem>> = new BehaviorSubject([]);
  public notificheAlert: BehaviorSubject<Array<NotificationItem>> = new BehaviorSubject([]);

  async messageInfo(label: string) {
    const notificheAdd = this.notifiche.getValue();
    notificheAdd.unshift({message:label,color:'success',dateTime:new Date()});
    this.notifiche.next(notificheAdd);
    this.notificheAlert.next([notificheAdd[0]]);
    this.runPopupQueue();
  }

  async messageError(label: string) {
    const notificheAdd = this.notifiche.getValue();
    notificheAdd.unshift({message:label,color:'danger',dateTime:new Date()});
    this.notifiche.next(notificheAdd);
    //const notificheAlertAdd = this.notificheAlert.getValue();
    //notificheAlertAdd.unshift({message:label,color:'danger',dateTime:new Date()});
    this.notificheAlert.next([notificheAdd[0]]);
    this.runPopupQueue();
  }

  async messageWarning(label: string) {
    const notificheAdd = this.notifiche.getValue();
    notificheAdd.unshift({message:label,color:'warning',dateTime:new Date()});
    this.notifiche.next(notificheAdd);
    this.notificheAlert.next([notificheAdd[0]]);
    this.runPopupQueue();
  }

  runPopupQueue() {
    const notificheProcess = this.notificheAlert.getValue();
    if (notificheProcess === undefined) {
      return;
    }
    notificheProcess.some(async (notification) => {
      setTimeout(() => {
        notificheProcess.shift();
        this.notificheAlert.next(notificheProcess);
        this.runPopupQueue();
      },3000);
      return true;
    });

  }
}
