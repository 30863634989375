import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ModalController} from '@ionic/angular';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';
import {Organizzazione} from '../../_models/Organizzazione';
import {AccountOrganizzazione} from '../../_models/AccountOrganizzazione';
import {SaveMessage} from '../../_helpers/save-message';
import {constants} from '../../_helpers/constants';
import {AuthenticationService} from '../../_services/authentication.service';

@Component({
  selector: 'app-filtro-ordine',
  templateUrl: './filtro-ordine.component.html',
  styleUrls: ['./filtro-ordine.component.scss'],
})
export class FiltroOrdineComponent  implements OnInit, OnDestroy {
  subscription: Subscription;
  organizzazione: Organizzazione;
  accountOrganizzazione: AccountOrganizzazione;
  loading = false;
  protected readonly constants = constants;
  constructor(
    public authService: AuthenticationService,
    private saveMessage: SaveMessage,
    private modalCtrl: ModalController,
    private organizzazioneService: OrganizzazioniService) { }

  ngOnInit() {
    this.organizzazione = this.organizzazioneService.currentOrganizzazioneSubject.value;
    if (this.organizzazione && this.organizzazione.accountSettings) {
      this.accountOrganizzazione = this.organizzazione.accountSettings;
    }
    this.subscription = this.organizzazioneService.currentOrganizzazioneSubject
      .subscribe((_organizzazione: Organizzazione) => {
        if (_organizzazione && _organizzazione.accountSettings) {
          this.organizzazione = _organizzazione;
          this.accountOrganizzazione = _organizzazione.accountSettings;
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  cancel = () => this.modalCtrl.dismiss(false, 'cancel');

  succesSave = () => {
    this.loading = true;
    this.organizzazioneService.saveAccountOrganizzazioneAsync(this.accountOrganizzazione)
      .then((_accountOrganizzazione: AccountOrganizzazione) => {
        this.organizzazione.accountSettings = _accountOrganizzazione;
        this.organizzazioneService.setCurrentOrganizzazioneValue(this.organizzazione);
        this.modalCtrl.dismiss(true, 'confirm');
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con salvataggio'))
      .finally(() => this.loading = false);
  };
}
