import {Component, OnInit} from '@angular/core';
import {LocalService} from '../../_services/local.service';
import {Ordine, OrdineCreate} from '../../_models/Ordine';
import {TipoUmTempo} from '../../_helpers/TipoUmTempo';
import {SaveMessage} from '../../_helpers/save-message';
import {ModalController} from '@ionic/angular';
import {EventiService} from '../../_services/eventi.service';
import {StatoRisorsa} from '../../_helpers/stato-risorsa';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';

@Component({
  selector: 'app-ordine-local-create',
  templateUrl: './ordine-local-create.component.html',
  styleUrls: ['./ordine-local-create.component.scss'],
})
export class OrdineLocalCreateComponent implements OnInit {
  newOrdine: OrdineCreate = {
    idDocumento: 0,
    idRiga: 0,
    idRigaLavorazione: 0,
    organizzazioneId: 0,
    risorsaId: 0,
    lavorazioneId: 0,
    dataRegistrazione: new Date(),
    numRegistrazione: 1,
    codiceArticolo: '',
    descArticolo: '',
    quantita: 1,
    lotto: 1,
    quantitaLavorata: 0,
    quantitaScartata: 0,
    stato: StatoRisorsa.PLANNED,
    statoElimina: 0,
    dataTermine: new Date(),
    commessa: '',
    umTempo: TipoUmTempo.ore,
    flgTerminato: false,
    codiceRisorsa: '',
    codiceLavorazione: '',
    note: '',
    udM: '',
    rifLottoProdotto: '',
    numFase: 1,
    rifOdP: ''
  };
  constructor(
    private saveMessage: SaveMessage,
    private modalCtrl: ModalController,
    private localService: LocalService,
    private eventiService: EventiService,
    private organizzazioniService: OrganizzazioniService
  ) { }

  ngOnInit() {
    this.newOrdine.organizzazioneId = this.organizzazioniService.organizzazioneId;
  }

  createLocalOrdine = () => {
    this.localService.createOrdine(this.newOrdine)
      .then((ordineLocal: Ordine) => {
        this.saveMessage.messageInfo('Ordine creato con successo');
        this.eventiService.creaEvento(StatoRisorsa.PLANNED,ordineLocal,new Date())
          .then(() => this.modalCtrl.dismiss(ordineLocal, 'confirm'));
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con il nuovo ordine'));
  };

  cancel = () => this.modalCtrl.dismiss(false, 'cancel');

}
