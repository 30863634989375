import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { OrganizzazioniService} from '../../../_services/organizzazioni.service';
import { RisorseService } from '../../../_services/risorse.service';
import {Risorsa} from '../../../_models/Risorsa';
import {SearchResult} from '../../../_models/SearchResult';

@Component({
  selector: 'app-select-risorsa',
  templateUrl: './select-risorsa.component.html',
  styleUrls: ['./select-risorsa.component.scss'],
})
export class SelectRisorsaComponent implements OnInit {
  @Input() byCode = false;
  @Input() isString = false;
  @Input() risorsaValue;
  @Input() all = false;
  @Output() risorsaValueChange = new EventEmitter<string>();
  risorses = [];
  loading = false;

  constructor(
    private organizzazioniService: OrganizzazioniService,
    public risorseService: RisorseService) {
  }

  ngOnInit() {
    this.getRisorse();
  }

  selectRisorsa = (e: any) => this.risorsaValueChange.emit(this.isString ? e.target.value.toString() : e.target.value);

  getRisorse = () => this.organizzazioniService.risorsePersonal && !this.all ? this.getMyRisorse() : this.getRisorseLocalAsync();

  getMyRisorse() {
    //this.loading = true;
    this.setRisorse(this.risorseService.myRisorse.value);
    /*this.risorseService.getMyRisorseAsync(this.organizzazioniService.organizzazioneId)
      .then(this.setRisorse)
      .catch((_err) => console.log(_err))
      .finally(() => this.loading = false);*/
  }

  getRisorseLocalAsync() {
    this.loading = true;
    this.risorses = [];
    this.risorseService
      .getRisorseFilteredAsync(this.organizzazioniService.organizzazioneId, '', 0, 0, 'codice', true)
      .then((_risorse: SearchResult<Risorsa>) => this.setRisorse(_risorse.result))
      .catch((_err) => console.log(_err))
      .finally(() => this.loading = false);
  }

  setRisorse = (_result: Risorsa[]) => {
    if (_result.length > 0) {
      _result.forEach((_risorsa: Risorsa) =>
        this.risorses
          .push({ value: this.byCode ? _risorsa.codice : _risorsa.id,
            label: `${_risorsa.codice}: ${_risorsa.descrizione}` })
      );
    }
  };
}
