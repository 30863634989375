export enum TipoFiltroOrdine {
  noFiltro = 0,
  myRisorse = 1,
  myLavorazioni = 2,
  myRisorseLavorazioni = 3
}

export const TIPO_FILTRO_ORDINE = [
  { value: TipoFiltroOrdine.noFiltro, label: 'No Filtro' },
  { value: TipoFiltroOrdine.myRisorse, label: 'Risorse Personali' },
  { value: TipoFiltroOrdine.myLavorazioni, label: 'Lavorazioni Personali' },
  { value: TipoFiltroOrdine.myRisorseLavorazioni, label: 'Risorse e Lavorazioni'}
];
