import {Component, Input } from '@angular/core';
import {Evento} from '../../_models/Evento';

@Component({
  selector: 'app-tipo-evento-label',
  templateUrl: './tipo-evento-label.component.html',
  styleUrls: ['./tipo-evento-label.component.scss'],
})
export class TipoEventoLabelComponent {

  @Input() evento: Evento;

}
