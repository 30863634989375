// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-select::part(icon) {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/_components/select/select-filter-row-operation/select-filter-row-operation.component.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF","sourcesContent":["ion-select::part(icon) {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
