export class WorkSession {
  id: number;
  risorsaId: number;
  risorsaCodice: string;
  codiceArticolo: string;
  unixStart: number;
  unixEnd: number;
  start: Date;
  end: Date;
  quantitaLavorata: number;
  quantitaScartata: number;
  eventi?: [];
}
